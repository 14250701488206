import React, { useState, useEffect } from 'react'

import { insertAsyncScriptTag } from './dom'

export const GoogleTagManagerContext = React.createContext()
export const GoogleTagManagerContextProvider = ({ children, layer = 'dataLayer', id }) => {
  const [googleTagManager, setGoogleTagManager] = useState(null)
  useEffect(() => {
    if (!id) {
      return
    }
    const cmd = (window[layer] = window[layer] || [])
    cmd.push({ 'gtm.start': new Date().getTime(), event: 'gtm.js' })
    cmd.push(function () {
      // explicitly not using () => because we want this to be the dataLayer
      const googletag = window[layer]
      setGoogleTagManager(googletag)
    })
    const dl = layer !== 'dataLayer' ? `&l=${encodeURIComponent(layer)}` : ''
    const src = `https://www.googletagmanager.com/gtm.js?id=${encodeURIComponent(id)}${dl}`
    insertAsyncScriptTag(src)
  }, [id, layer])
  return (
    <GoogleTagManagerContext.Provider
      value={{
        googleTagManager,
      }}
    >
      {children}
    </GoogleTagManagerContext.Provider>
  )
}
