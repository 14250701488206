import React, { useContext, useState, useEffect } from 'react'
import { useLocation, useHistory } from 'react-router-dom'
import { makeStyles } from '@material-ui/core/styles'
import { Button, Input, InputLabel, Paper, Table, TableBody, TableCell, TableHead, TableRow } from '@material-ui/core'

import { AuthContext } from './AuthContext'
import { NewFetchError } from './Fetch'
import qs from './qs'
import { AdminSiteContext } from './AdminSiteContext'

const useStyles = makeStyles({
  root: {
    padding: '1em',
    marginBottom: '1em',
  },
  venueMeta: {
    display: 'inline-block',
    verticalAlign: 'middle',
  },
  name: {
    fontWeight: 'bold',
  },
})

async function refresh(idToken, query) {
  const resp = await fetch(`/api/ticketmastervenues${qs.stringify(query)}`, {
    headers: {
      authorization: `Bearer ${idToken}`,
    },
  })
  if (!resp.ok) {
    throw await NewFetchError(resp)
  }
  return await resp.json()
}

export default () => {
  const history = useHistory()
  const location = useLocation()
  const params = qs.parse(location.search)
  const classes = useStyles()
  const { getIdToken, roles } = useContext(AuthContext)
  const { siteId } = useContext(AdminSiteContext)
  const [venues, setVenues] = useState(null)
  const [refreshKey, setRefreshKey] = useState(0)
  const [query, setQuery] = useState({
    q: params.q,
  })
  const siteSearch = window.location.origin.replace(/\/\/admin\./, `//${siteId}.`) + '/search'
  useEffect(() => {
    let cancel = false
    ;(async () => {
      history.replace(`${location.pathname}${qs.stringify({ ...query, site: params.site })}${location.hash}`)
      const idToken = await getIdToken()
      const data = await refresh(idToken, query)
      if (cancel) {
        return
      }
      setVenues(data.venues)
    })()
    return () => {
      cancel = true
    }
  }, [getIdToken, refreshKey, query, location.pathname, location.hash, history, params.site])

  const updateVenue = async (data) => {
    const idToken = await getIdToken()
    const resp = await fetch(`/api/ticketmastervenues/update`, {
      method: 'post',
      body: JSON.stringify(data),
      headers: {
        authorization: `Bearer ${idToken}`,
      },
    })
    if (!resp.ok) {
      if (resp.status === 400) {
        try {
          const data = await resp.json()
          if (data.type === 'validation_error') {
            return data.fields || { name: 'unknown error' }
          }
        } catch {} // Treat as generic error
      }
      throw await NewFetchError(resp)
    }
    setRefreshKey((k) => k + 1)
  }

  return (
    <Paper className={classes.root}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>
              Venue{' '}
              <InputLabel>
                Filter:
                <Input value={query.q} onChange={(e) => setQuery({ ...query, q: e.target.value })} />
              </InputLabel>
              <Button onClick={(e) => setQuery({ q: '' })}>Clear</Button>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {venues
            ? venues.length === 0 && (
                <TableRow>
                  <TableCell>There are no venues.</TableCell>
                  <TableCell></TableCell>
                </TableRow>
              )
            : null}
          {venues
            ? venues.map((v) => (
                <TableRow key={v.id}>
                  <TableCell>
                    <div className={classes.venueMeta}>
                      <div className={classes.name}>{v.label}</div>
                      <div className={classes.name}>{v.name}</div>
                      <div>{v.address}</div>
                      <div>
                        {v.upcomingEventsCount ? (
                          <a
                            href={siteSearch + `?noSiteBounds=true&venue=${encodeURIComponent(v.id)}`}
                            rel="noopener noreferrer"
                            target="_blank"
                          >
                            ({v.upcomingEventsCount} upcoming event{v.upcomingEventsCount !== 1 ? 's' : ''})
                          </a>
                        ) : null}
                      </div>
                    </div>
                  </TableCell>
                  <TableCell align="right">
                    {roles.indexOf('superadmin') !== -1 && (
                      <label>
                        Quarantine{' '}
                        <input
                          type="checkbox"
                          defaultChecked={v.quarantine}
                          onChange={async (e) => {
                            await updateVenue({ ...v, quarantine: e.target.checked })
                          }}
                        />
                      </label>
                    )}
                  </TableCell>
                </TableRow>
              ))
            : null}
        </TableBody>
      </Table>
    </Paper>
  )
}
