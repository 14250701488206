import React from 'react'

import styles from './Logo.module.css'
import A from './A'
import Image from './Image'

const Logo = ({ checksum, name }) => (checksum ? <Image checksum={checksum} alt={name} /> : <h1>{name}</h1>)

export default ({ name, logoChecksum, linkUrl, className = '', style }) => {
  if (linkUrl) {
    return (
      <div style={style} className={`${styles.root} ${className}`}>
        <A href={linkUrl}>
          <Logo name={name} checksum={logoChecksum} />
        </A>
      </div>
    )
  }
  return (
    <div style={style} className={`${styles.root} ${className}`}>
      <Logo name={name} checksum={logoChecksum} />
    </div>
  )
}
