import React, { useContext, useMemo, useState, useEffect } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { Button, Paper, TextField } from '@material-ui/core'
import { RemoveCircle, AddCircle } from '@material-ui/icons'
import { Map, Marker } from 'react-leaflet'
import L from 'leaflet'
import ReactQuill from 'react-quill'
import 'react-quill/dist/quill.snow.css'

import styles from './AdminAddEditCuratedVenue.module.css'
import CustomTileLayer from './CustomTileLayer'
import { AuthContext } from './AuthContext'
import { NewFetchError } from './Fetch'
import ImageUpload from './components/ImageUpload'
import { createUploadImageHandler } from './api_utilities'
import { useHistory } from 'react-router'
import { AdminSiteContext } from './AdminSiteContext'

const useStyles = makeStyles({
  root: {
    padding: '1em',
    marginBottom: '1em',
  },
  venueMeta: {
    display: 'inline-block',
    verticalAlign: 'middle',
  },
  name: {
    fontWeight: 'bold',
  },
})

export default ({ uid }) => {
  const classes = useStyles()
  const { getIdToken } = useContext(AuthContext)
  const history = useHistory()

  const saveCuratedVenue = async (data) => {
    const vErrs = {}
    if (!Number.isFinite(data.latitude)) {
      vErrs.latitude = 'latitude must not be blank'
    }
    if (!Number.isFinite(data.longitude)) {
      vErrs.longitude = 'longitude must not be blank'
    }
    if (Object.keys(vErrs).length > 0) {
      return vErrs
    }
    const idToken = await getIdToken()
    const resp = await fetch(`/api/curatedvenues/update`, {
      method: 'post',
      body: JSON.stringify(data),
      headers: {
        authorization: `Bearer ${idToken}`,
      },
    })
    if (!resp.ok) {
      if (resp.status === 400) {
        try {
          const data = await resp.json()
          if (data.type === 'validation_error') {
            return data.fields || { name: 'unknown error' }
          }
        } catch {} // Treat as generic error
      }
      throw await NewFetchError(resp)
    }
  }

  return (
    <Paper className={classes.root}>
      <CreateEditVenue uid={uid} saveCuratedVenue={saveCuratedVenue} onSuccess={() => history.push('/curatedvenues')} />
    </Paper>
  )
}

const icon = L.icon({ iconUrl: '/marker-icon.png' })

const useFormStyles = makeStyles({
  map: {
    width: '100%',
    height: '15em',
  },
  autocompleteContainer: {
    display: 'flex',
  },
  autocomplete: {
    flexGrow: 1,
  },
  icon: {
    margin: 'auto 0 auto 1rem',
  },
})

const CreateEditVenue = ({ onSuccess, saveCuratedVenue, uid }) => {
  const classes = useFormStyles()
  const { getIdToken } = useContext(AuthContext)
  const [primaryImageChecksum, setPrimaryImageChecksum] = useState()
  const [secondaryImageChecksum, setSecondaryImageChecksum] = useState()
  const [name, setName] = useState('')
  const [address, setAddress] = useState('')
  const [latitude, setLatitude] = useState()
  const [longitude, setLongitude] = useState()
  const [about, setAbout] = useState('')
  const [url, setUrl] = useState('')
  const [hours, setHours] = useState('')
  const [email, setEmail] = useState('')
  const [phone, setPhone] = useState('')
  const [parking, setParking] = useState('')
  const [twitter, setTwitter] = useState('')
  const [facebook, setFacebook] = useState('')
  const [pinterest, setPinterest] = useState('')
  const [yelp, setYelp] = useState('')
  const [venues, setVenues] = useState([])
  const [ready, setReady] = useState(false)
  const [submitting, setSubmitting] = useState()
  const [error, setError] = useState({})

  const position = useMemo(
    () =>
      Number.isFinite(Number.parseFloat(latitude)) && Number.isFinite(Number.parseFloat(longitude))
        ? { lat: Number.parseFloat(latitude), lng: Number.parseFloat(longitude) }
        : undefined,
    [latitude, longitude]
  )

  useEffect(() => {
    if (!uid) {
      return
    }
    setReady(false)
    ;(async () => {
      const idToken = await getIdToken()
      const resp = await fetch(`/api/curatedvenue?uid=${encodeURIComponent(uid)}`, {
        headers: {
          authorization: `Bearer ${idToken}`,
        },
      })
      if (!resp.ok) {
        throw await NewFetchError(resp)
      }
      const data = await resp.json()
      setName(data.name)
      setAddress(data.address)
      setLatitude(data.latitude)
      setLongitude(data.longitude)
      setAbout(data.about)
      setUrl(data.url)
      setHours(data.hours)
      setEmail(data.email)
      setPhone(data.phone)
      setParking(data.parking)
      setTwitter(data.twitter)
      setFacebook(data.facebook)
      setPinterest(data.pinterest)
      setYelp(data.yelp)
      setVenues(data.venues || [])
      setPrimaryImageChecksum(data.primaryImageChecksum)
      setSecondaryImageChecksum(data.secondaryImageChecksum)
      setReady(true)
    })()
  }, [getIdToken, uid])

  const handleSubmit = async (successCallback = () => null) => {
    setSubmitting(true)
    const error = await saveCuratedVenue({
      uid: uid,
      name,
      address,
      latitude: Number(latitude),
      longitude: Number(longitude),
      about,
      url,
      hours,
      email,
      phone,
      parking,
      twitter,
      facebook,
      pinterest,
      yelp,
      venues: venues.filter((v) => !!v),
      primaryImageChecksum,
      secondaryImageChecksum,
    })
    if (error) {
      setError(error)
      setSubmitting(false)
      return
    }
    successCallback()
  }

  if (uid && !ready) {
    return null
  }
  return (
    <div>
      <TextField
        autoFocus={!uid}
        name="name"
        value={name}
        onChange={(e) => {
          setName(e.target.value)
        }}
        error={error.name}
        helperText={error.name}
        margin="dense"
        label="Name"
        autoComplete="off"
        fullWidth
      />
      <label>Primany Image (16:9)</label>
      <ImageUpload
        dropzoneContainerStyle={{ aspectRatio: '16/9', width: '520px' }}
        value={primaryImageChecksum}
        onChange={setPrimaryImageChecksum}
        onUpload={createUploadImageHandler(getIdToken)}
        error={error.primaryImageChecksum}
      />
      <label>Secondary Image (square for teaser)</label>
      <ImageUpload
        previewImageSize={'250,'}
        dropzoneContainerStyle={{ aspectRatio: '1/1', width: '260px' }}
        value={secondaryImageChecksum}
        onChange={setSecondaryImageChecksum}
        onUpload={createUploadImageHandler(getIdToken)}
        error={error.secondaryImageChecksum}
      />
      <TextField
        name="address"
        value={address}
        onChange={(e) => {
          setAddress(e.target.value)
        }}
        margin="dense"
        label="Address"
        autoComplete="off"
        error={error.address}
        helperText={error.address}
        fullWidth
      />
      <Button
        onClick={async () => {
          const idToken = await getIdToken()
          const resp = await fetch(`/api/curratedvenues/geocode?q=${encodeURIComponent(address)}`, {
            headers: {
              authorization: `Bearer ${idToken}`,
            },
          })
          if (!resp.ok) {
            throw await NewFetchError(resp)
          }
          const data = await resp.json()
          if (data.matches && data.matches.length > 0) {
            const i = data.matches[0]
            setLatitude(i.latitude)
            setLongitude(i.longitude)
            setAddress(i.name)
            setError({ ...error, address: null })
          } else {
            setError({ ...error, address: 'Unable to geocode address' })
          }
        }}
      >
        Normalize and Geocode
      </Button>
      <TextField
        name="latitude"
        value={latitude}
        onChange={(e) => {
          setLatitude(e.target.value)
        }}
        error={error.latitude}
        helperText={error.latitude}
        margin="dense"
        label="Latitude"
        autoComplete="off"
        fullWidth
      />
      <TextField
        name="longitude"
        value={longitude}
        onChange={(e) => {
          setLongitude(e.target.value)
        }}
        error={error.longitude}
        helperText={error.longitude}
        margin="dense"
        label="Longitude"
        autoComplete="off"
        fullWidth
      />
      <Map className={classes.map} center={position} zoom={13}>
        <CustomTileLayer />
        {position ? <Marker position={position} icon={icon} /> : null}
      </Map>
      <label>
        About
        <ReactQuill
          theme="snow"
          defaultValue={about}
          onChange={(content, _delta, _source, _editor) => setAbout(content)}
        />
      </label>
      <label>
        Hours
        <ReactQuill
          theme="snow"
          defaultValue={hours}
          onChange={(content, _delta, _source, _editor) => setHours(content)}
        />
      </label>
      <label>
        Parking
        <ReactQuill
          theme="snow"
          defaultValue={parking}
          onChange={(content, _delta, _source, _editor) => setParking(content)}
        />
      </label>
      <TextField
        name="url"
        value={url}
        onChange={(e) => {
          setUrl(e.target.value)
        }}
        error={error.url}
        helperText={error.url}
        margin="dense"
        label="URL"
        autoComplete="off"
        fullWidth
      />
      <TextField
        name="email"
        value={email}
        onChange={(e) => {
          setEmail(e.target.value)
        }}
        error={error.email}
        helperText={error.email}
        margin="dense"
        label="Email"
        autoComplete="off"
        fullWidth
      />
      <TextField
        name="phone"
        value={phone}
        onChange={(e) => {
          setPhone(e.target.value)
        }}
        error={error.phone}
        helperText={error.phone}
        margin="dense"
        label="Phone"
        autoComplete="off"
        fullWidth
      />
      <TextField
        name="twitter"
        value={twitter}
        onChange={(e) => {
          setTwitter(e.target.value)
        }}
        error={error.twitter}
        helperText={error.twitter}
        margin="dense"
        label="Twitter"
        autoComplete="off"
        fullWidth
      />
      <TextField
        name="facebook"
        value={facebook}
        onChange={(e) => {
          setFacebook(e.target.value)
        }}
        error={error.facebook}
        helperText={error.facebook}
        margin="dense"
        label="Facebook"
        autoComplete="off"
        fullWidth
      />
      <TextField
        name="pinterest"
        value={pinterest}
        onChange={(e) => {
          setPinterest(e.target.value)
        }}
        error={error.pinterest}
        helperText={error.pinterest}
        margin="dense"
        label="Pinterest"
        autoComplete="off"
        fullWidth
      />
      <TextField
        name="yelp"
        value={yelp}
        onChange={(e) => {
          setYelp(e.target.value)
        }}
        error={error.yelp}
        helperText={error.yelp}
        margin="dense"
        label="Yelp"
        autoComplete="off"
        fullWidth
      />
      <label>Venues</label>
      {venues.map((sv, idx) => (
        <div key={idx} className={classes.autocompleteContainer}>
          <div className={classes.autocomplete}>
            {sv.name} {sv.address}
          </div>
          <div className={classes.icon}>
            <RemoveCircle
              onClick={() => {
                setVenues(venues.filter((v) => sv?.id !== v?.id))
              }}
            />
          </div>
        </div>
      ))}
      <VenuePicker
        getIdToken={getIdToken}
        currentSelections={venues}
        addVenue={(v) => {
          setVenues([...venues, v])
        }}
      />
      <div>
        <Button onClick={onSuccess} color="primary">
          Cancel
        </Button>
        <Button onClick={() => handleSubmit(onSuccess)} color="primary" disabled={submitting}>
          {uid ? 'Update' : 'Add'}
        </Button>
      </div>
    </div>
  )
}

function debounce(func, timeout = 300) {
  let timer
  return (...args) => {
    clearTimeout(timer)
    timer = setTimeout(() => {
      func.apply(this, args)
    }, timeout)
  }
}

const VenuePicker = ({ getIdToken, addVenue, currentSelections = [] }) => {
  const { siteHost } = useContext(AdminSiteContext)
  const siteSearch = window.location.origin.replace(/\/\/admin\./, `//${siteHost}.`) + '/search'
  const [matches, setMatches] = useState([])
  const getVenues = debounce(async (inputValue) => {
    const idToken = await getIdToken()
    const resp = await fetch(`/api/curatedvenues/autocomplete?q=${encodeURIComponent(inputValue)}`, {
      headers: {
        authorization: `Bearer ${idToken}`,
      },
    })
    if (!resp.ok) {
      throw await NewFetchError(resp)
    }
    const data = await resp.json()
    setMatches(data.matches)
  })

  return (
    <div className={styles.venuePicker}>
      <TextField
        name="Venue Search"
        onChange={(e) => {
          getVenues(e.target.value)
        }}
        margin="dense"
        label="Venue Search"
        autoComplete="off"
        fullWidth
      />
      <ul>
        {matches
          .filter((v) => !currentSelections.find((c) => c?.id === v?.id))
          .map((i) => (
            <li key={i.id} className={styles.container}>
              <a
                href={`#add-${i.id}`}
                onClick={(e) => {
                  e.preventDefault()
                  addVenue(i)
                }}
              >
                <AddCircle />
              </a>
              {i.name && <div>{i.name}</div>}
              <div>{i.address}</div>
              <div>
                <a
                  href={siteSearch + `?noSiteBounds=true&venue=${encodeURIComponent(i.id)}`}
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  ({i.upcomingEventsCount} upcoming event{i.upcomingEventsCount !== 1 ? 's' : ''})
                </a>
              </div>
            </li>
          ))}
      </ul>
    </div>
  )
}
