import React, { useContext } from 'react'
import { Helmet } from 'react-helmet-async'
import moment from 'moment-timezone'

import styles from './MyEventsPage.module.css'
import { SiteContext } from '../SiteContext'
import { TrackPageView } from '../analytics'
import Breadcrumbs from '../Breadcrumbs'
import Image from '../Image'
import Button from '../components/Button'
import API from '../API'

const EventRow = ({ id, imageChecksum, title, start, noStart, published, venue: { name: venueName }, upcoming }) => {
  const {
    site: { timezone },
  } = useContext(SiteContext)
  return (
    <div key={id} className={styles.event}>
      <div>
        <Image className={styles.image} checksum={imageChecksum} size="240," />
      </div>
      <div className={styles.meta}>
        {!published && <div className={styles.unpublished}>This event is not published.</div>}
        <div className={styles.title}>{title}</div>
        <div>{venueName}</div>
        <div>
          {moment(start)
            .tz(timezone)
            .format(noStart ? 'ddd, MMM Do, h:mm a' : 'ddd, MMM, Do')}
        </div>
        <div className={styles.actions}>
          <Button to={`/event/${encodeURIComponent(id)}`}>View</Button>
          <Button to={`/edit/event/${encodeURIComponent(id)}`}>Edit</Button>
          {upcoming && <Button to={`/promote/featured-daily-event/${encodeURIComponent(id)}`}>Promote</Button>}
        </div>
      </div>
    </div>
  )
}

export default ({ breadcrumbs }) => {
  const { site } = useContext(SiteContext)
  return (
    <div className={styles.root}>
      <Helmet>
        <title>My Events - {site.name}</title>
      </Helmet>
      <TrackPageView title="MyEvents" />
      <Breadcrumbs breadcrumbs={breadcrumbs} />
      <h1 className={styles.heading}>My Events</h1>
      <API input={`/api/event/my`}>
        {({ data }) => {
          if (!data) {
            return null
          }
          const { upcomingEvents, pastEvents } = data
          return (
            <>
              <h2 className={styles.subHeading}>Upcoming events</h2>
              <div>
                {upcomingEvents.length === 0 && (
                  <div className={styles.noUpcoming}>
                    <div>You have no upcoming events.</div>
                    <Button to={`/create/event`}>Add Your Event</Button>
                  </div>
                )}
                {upcomingEvents.map((event) => (
                  <EventRow key={event.id} upcoming {...event} />
                ))}
              </div>
              {pastEvents.length > 0 && (
                <>
                  <h2 className={styles.subHeading}>Past events</h2>
                  <div>
                    {pastEvents.map((event) => (
                      <EventRow key={event.id} {...event} />
                    ))}
                  </div>
                </>
              )}
            </>
          )
        }}
      </API>
    </div>
  )
}
