import React, { useContext } from 'react'
import moment from 'moment-timezone'

import styles from './DateTimeRange.module.css'
import { SiteContext } from '../SiteContext'

export default ({ start, end, noStart, noEnd }) => {
  const {
    site: { timezone },
  } = useContext(SiteContext)
  if (typeof start === 'undefined') {
    if (typeof end === 'undefined') {
      return null
    }
    start = end
    if (typeof noStart === 'undefined') {
      noStart = true
    }
  }
  if (typeof end === 'undefined') {
    end = start
    if (typeof noEnd === 'undefined') {
      noEnd = true
    }
  }
  if (typeof timezone === 'undefined') {
    throw new Error('timezone must be defined')
  }
  const from = moment(start).tz(timezone)
  const to = moment(end).tz(timezone)
  if (!noStart && !noEnd) {
    if (from.isSame(to, 'day')) {
      return (
        <div className={styles.root}>
          <div>{from.format('ddd, MMM Do')}</div>
          <div>
            {from.format('h:mm a')} - {to.format('h:mm a')}
          </div>
        </div>
      )
    }
    if (to.isBefore(from.clone().add(1, 'd')) && to.isAfter(from)) {
      return (
        <div className={styles.root}>
          <div>{from.format('ddd, MMM Do')}</div>
          <div>
            {from.format('h:mm a')} - {to.format('h:mm a')}
          </div>
        </div>
      )
    }
    return (
      <div className={styles.root}>
        <div>
          {from.format('ddd, MMM Do')}, {from.format('h:mm a')}
        </div>
        <div>
          {to.format('ddd, MMM Do')}, {to.format('h:mm a')}
        </div>
      </div>
    )
  }
  if (noStart && noEnd) {
    if (from.isSame(to, 'day')) {
      return <div className={styles.root}>{from.format('ddd, MMM Do')}</div>
    }
    return (
      <div className={styles.root}>
        {from.format('ddd, MMM Do')} - {to.format('ddd, MMM Do')}
      </div>
    )
  }
  if (noEnd) {
    if (from.isSame(to, 'day')) {
      return (
        <div className={styles.root}>
          <div>{from.format('ddd, MMM Do')}</div>
          <div>{from.format('h:mm a')}</div>
        </div>
      )
    }
    return (
      <div className={styles.root}>
        <div>
          {from.format('ddd, MMM Do')}, {from.format('h:mm a')}
        </div>
        <div>{to.format('ddd, MMM Do')}</div>
      </div>
    )
  }
  // just noStart is true
  if (from.isSame(to, 'day')) {
    return (
      <div className={styles.root}>
        <div>{from.format('ddd, MMM Do')}</div>
        <div>until {to.format('h:mm a')}</div>
      </div>
    )
  }
  return (
    <div className={styles.root}>
      <div>{from.format('ddd, MMM Do')}</div>
      <div>
        {to.format('ddd, MMM Do')}, {to.format('h:mm a')}
      </div>
    </div>
  )
}
