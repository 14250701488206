import memoize from 'lodash/memoize'

const catToOptions = (idPrefix, path) => (cat) => {
  const result = [{ id: idPrefix + cat.id, label: cat.label, path }]
  if (cat.children) {
    result.push(...catsToOptions(cat.children, idPrefix, [...path, cat.label]))
  }
  return result
}

export const catsToOptions = memoize((cats, idPrefix = '', path = []) => cats.flatMap(catToOptions(idPrefix, path)))

export const filterOption = ({ data }, q) => {
  if (data.display === 'none') {
    return false
  }
  if (data.display === 'children') {
    return true // placeholder used to display child component
  }
  const terms = q.toLowerCase().trim().split(/\s+/)
  for (let i = 0; i < terms.length; i++) {
    const term = terms[i]
    if (data.label.toLowerCase().indexOf(term) !== -1) {
      continue // move to next term
    }
    let found = false
    for (let i = 0; i < data.path.length; i++) {
      if (data.path[i].toLowerCase().indexOf(term) !== -1) {
        found = true
        continue
      }
    }
    if (found) {
      continue // move to next term
    }
    return false // this term doesn't match
  }
  return true // all terms match
}
