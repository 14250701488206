import React from 'react'
import { Formik, Form, ErrorMessage, getIn } from 'formik'
import { object, string, number, array, boolean } from 'yup'
import { geoJSON } from 'leaflet'
import { GeoJSON, Map, Marker } from 'react-leaflet'
import { Typography, MenuItem, TextField, InputAdornment, Button, Checkbox } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import L from 'leaflet'

import styles from './components/CreateEventForm.module.css'
import CustomTileLayer from './CustomTileLayer'
import ImageUpload from './components/ImageUpload'
import AdminCategoryPicker from './AdminCategoryPicker'
import timezones from './timezones'

const icon = L.icon({ iconUrl: '/marker-icon.png' })

const BoundsMap = ({ classes, bounds }) => {
  try {
    bounds = JSON.parse(bounds)
  } catch (e) {
    return <Typography className={classes.error}>Invalid bounds</Typography>
  }
  if (
    !(
      bounds.type === 'Polygon' ||
      (bounds.type === 'Feature' && bounds.geometry?.type === 'Polygon') ||
      (bounds.type === 'FeatureCollection' &&
        bounds.features?.length === 1 &&
        bounds.features[0].type === 'Feature' &&
        bounds.features[0].geometry?.type === 'Polygon')
    )
  ) {
    return (
      <Typography className={classes.error}>
        Must be a GeoJSON FeatureCollection or Feature containing a single Polygon (or a single Polygon)
      </Typography>
    )
  }
  let b
  try {
    b = geoJSON(bounds).getBounds()
    if (!b.isValid()) {
      return <Typography className={classes.error}>Invalid bounds</Typography>
    }
  } catch (e) {
    return <Typography className={classes.error}>Invalid bounds</Typography>
  }

  const markers = []
  const noSize = b.getNorth() <= b.getSouth() || b.getEast() <= b.getWest()
  if (noSize) {
    markers.push([b.getNorth(), b.getEast()])
    markers.push([b.getSouth(), b.getWest()])
    b.extend([b.getNorth() + 1, b.getEast() + 1])
    b.extend([b.getSouth() - 1, b.getWest() - 1])
  }
  return (
    <Map className={classes.map} bounds={b}>
      <CustomTileLayer />
      {!noSize && <GeoJSON key={JSON.stringify(bounds)} data={bounds} style={{ color: 'rgba(244, 156, 253, 0.9)' }} />}
      {markers && markers.map((v) => <Marker key={v} position={v} icon={icon} />)}
    </Map>
  )
}

const useStyles = makeStyles((theme) => ({
  root: {
    maxWidth: '480px',
  },
  adUnits: {
    width: '100%',
    textAlign: 'left',
    '& thead th:first-child': {
      width: '50%',
    },
  },
  adUnitSize: {
    marginRight: '1em',
  },
  error: {
    color: 'red',
    fontWeight: 'bold',
  },
  map: {
    width: '100%',
    height: '20em',
  },
}))

export default ({ site, categories, uploadImage, onSubmit }) => {
  const classes = useStyles()
  return (
    <Formik
      enableReinitialize
      initialValues={{
        timezone: site.timezone,
        bounds: JSON.stringify(site.bounds, null, '    '),
        name: site.name,
        host: site.host,
        upsellEmail: site.upsellEmail,
        supportEmail: site.supportEmail,
        logoChecksum: site.logoChecksum,
        logoHeightHeader: site.logoHeightHeader,
        logoLinkUrl: site.logoLinkUrl,
        externalHeaderUrl: site.externalHeaderUrl,
        externalHeaderMobileHeight: site.externalHeaderMobileHeight || 0,
        externalHeaderTabletHeight: site.externalHeaderTabletHeight || 0,
        externalHeaderDesktopHeight: site.externalHeaderDesktopHeight || 0,
        externalFooterUrl: site.externalFooterUrl,
        externalFooterMobileHeight: site.externalFooterMobileHeight || 0,
        externalFooterTabletHeight: site.externalFooterTabletHeight || 0,
        externalFooterDesktopHeight: site.externalFooterDesktopHeight || 0,
        defaultEventImageChecksum: site.defaultEventImageChecksum,
        homeCategories: site.homeCategories,
        homeEvents: site.homeEvents,
        siteNavCategories: site.siteNavCategories,
        branding: site.branding,
        googleAnalyticsTrackingId: site.googleAnalyticsTrackingId,
        googleAds: {
          networkCode: site.googleAds.networkCode,
          leaderboard: site.googleAds.leaderboard.code,
          rightRail: site.googleAds.rightRail.code,
        },
        googleTagManagerContainerId: site.googleTagManagerContainerId,
        ticketmasterFeedEnabled: site.ticketmasterFeedEnabled,
        preventSearchEngineIndex: site.preventSearchEngineIndex,
      }}
      validationSchema={object().shape({
        timezone: string().required('Timezone is required').oneOf(timezones),
        name: string().required('Name is required').min(3, 'Name is too short'),
        host: string()
          .required('Host is required')
          .matches(/^[a-z0-9-]{3,32}$/, 'Host must be lower case alphanumeric and between 3 and 32 characters long'),
        upsellEmail: string().email(),
        supportEmail: string().email(),
        logoChecksum: string(),
        logoHeightHeader: number().required().min(0),
        logoLinkUrl: string(),
        externalHeaderUrl: string(),
        externalHeaderMobileHeight: number().required().min(0),
        externalHeaderTabletHeight: number().required().min(0),
        externalHeaderDesktopHeight: number().required().min(0),
        externalFooterUrl: string(),
        externalFooterMobileHeight: number().required().min(0),
        externalFooterTabletHeight: number().required().min(0),
        externalFooterDesktopHeight: number().required().min(0),
        defaultEventImageChecksum: string(),
        homeCategories: array(),
        homeEvents: array(),
        siteNavCategories: array(),
        googleAnalyticsTrackingId: string(),
        googleAds: object().shape({
          networkCode: string(),
          leaderboard: string(),
          rightRail: string(),
        }),
        googleTagManagerContainerId: string(),
        ticketmasterFeedEnabled: boolean(),
        preventSearchEngineIndex: boolean(),
        bounds: object(),
      })}
      onSubmit={async (values, actions) => {
        try {
          const {
            logoHeightHeader,
            externalHeaderMobileHeight,
            externalHeaderTabletHeight,
            externalHeaderDesktopHeight,
            externalFooterMobileHeight,
            externalFooterTabletHeight,
            externalFooterDesktopHeight,
            bounds: origBounds,
            ...passthru
          } = values
          let bounds = JSON.parse(origBounds)
          if (bounds.type === 'Polygon') {
            // all good
          } else if (bounds.type === 'Feature' && bounds.geometry?.type === 'Polygon') {
            bounds = bounds.geometry
          } else if (
            bounds.type === 'FeatureCollection' &&
            bounds.features?.length === 1 &&
            bounds.features[0].type === 'Feature' &&
            bounds.features[0].geometry?.type === 'Polygon'
          ) {
            bounds = bounds.features[0].geometry
          } else {
            actions.setStatus({ msg: 'Invalid or unsupported geometry' })
            actions.setSubmitting(false)
            return
          }
          const normalized = {
            id: site.id,
            logoHeightHeader: Number(logoHeightHeader),
            externalHeaderMobileHeight: Number(externalHeaderMobileHeight),
            externalHeaderTabletHeight: Number(externalHeaderTabletHeight),
            externalHeaderDesktopHeight: Number(externalHeaderDesktopHeight),
            externalFooterMobileHeight: Number(externalFooterMobileHeight),
            externalFooterTabletHeight: Number(externalFooterTabletHeight),
            externalFooterDesktopHeight: Number(externalFooterDesktopHeight),
            bounds,
            ...passthru,
          }
          actions.setStatus()
          onSubmit && (await onSubmit(normalized))
        } catch (error) {
          actions.setStatus({ msg: 'Your form has some problems: ' + error.message })
          actions.setSubmitting(false)
        }
      }}
    >
      {({ isSubmitting, status, touched, errors, values, setFieldValue, setFieldTouched, ...other }) => {
        const isError = (field) => !!getIn(touched, field) && !!getIn(errors, field)
        const formikInputProps = (field) => {
          return {
            name: field,
            value: getIn(values, field),
            onChange: (e) => setFieldValue(field, e.target.value),
            onBlur: () => setFieldTouched(field, true),
          }
        }
        const formikTextProps = (field, helperText) => {
          return {
            ...formikInputProps(field),
            error: isError(field),
            helperText: isError(field) ? getIn(errors, field) : helperText,
          }
        }
        return (
          <Form className={classes.root} autoComplete="off">
            <TextField {...formikTextProps('name')} label="Name" variant="outlined" fullWidth margin="dense" />
            <TextField
              {...formikTextProps('host')}
              label={window.location.host.replace(/^admin/, '{Host}')}
              variant="outlined"
              fullWidth
              margin="dense"
            />
            <TextField
              {...formikTextProps('timezone')}
              select
              label="Timezone"
              variant="outlined"
              fullWidth
              margin="dense"
            >
              {timezones.map((v) => (
                <MenuItem key={v} value={v}>
                  {v}
                </MenuItem>
              ))}
            </TextField>
            <BoundsMap classes={classes} bounds={getIn(values, 'bounds')} />
            <TextField
              {...formikTextProps('bounds')}
              label="Bounds geoJson"
              placeholder="{}"
              variant="outlined"
              fullWidth
              margin="dense"
              multiline
            />
            <TextField
              {...formikTextProps('supportEmail')}
              label="Support Email"
              variant="outlined"
              fullWidth
              margin="dense"
            />
            <TextField
              {...formikTextProps('upsellEmail')}
              label="Upsell Email"
              variant="outlined"
              fullWidth
              margin="dense"
            />
            <TextField
              {...formikTextProps('googleAnalyticsTrackingId')}
              label="Google Analytics"
              placeholder="Tracking ID"
              variant="outlined"
              fullWidth
              margin="dense"
            />
            <TextField
              {...formikTextProps('googleAds.networkCode')}
              label="Google Ads"
              placeholder="Network Code"
              variant="outlined"
              fullWidth
              margin="dense"
            />
            <TextField
              {...formikTextProps('googleAds.leaderboard')}
              label="Leaderboard Ads"
              placeholder="Ad Unit Code"
              variant="outlined"
              fullWidth
              margin="dense"
            />
            <TextField
              {...formikTextProps('googleAds.rightRail')}
              label="Right Rail Ads"
              placeholder="Ad Unit Code"
              variant="outlined"
              fullWidth
              margin="dense"
            />
            <table className={classes.adUnits}>
              <thead>
                <tr>
                  <th>Ad Unit</th>
                  <th>Sizes</th>
                </tr>
              </thead>
              <tbody>
                {['leaderboard', 'rightRail'].map((type) => {
                  const adUnit = site.googleAds[type]
                  if (!adUnit) {
                    return null
                  }
                  return (
                    <tr key={type}>
                      <td>{values.googleAds[type] || adUnit.defaultCode}</td>
                      <td>
                        {adUnit.sizes.map(({ width, height }) => (
                          <span key={`${width}x${height}`} className={classes.adUnitSize}>
                            {width}x{height}
                          </span>
                        ))}
                      </td>
                    </tr>
                  )
                })}
              </tbody>
            </table>
            <TextField
              {...formikTextProps('googleTagManagerContainerId')}
              label="Google Tag Manager Container ID"
              placeholder="GTM-XXXXX"
              variant="outlined"
              fullWidth
              margin="dense"
            />
            <TextField
              {...formikTextProps('externalHeaderUrl')}
              label="External Header URL"
              placeholder="iframe url"
              variant="outlined"
              fullWidth
              margin="dense"
            />
            <TextField
              {...formikTextProps('externalHeaderMobileHeight')}
              type="number"
              label="External Header Mobile Height (<480px wide)"
              placeholder="iframe height"
              variant="outlined"
              InputProps={{
                endAdornment: <InputAdornment position="end">pixels</InputAdornment>,
              }}
              fullWidth
              margin="dense"
            />
            <TextField
              {...formikTextProps('externalHeaderTabletHeight')}
              type="number"
              label="External Header Tablet Height (<768px wide)"
              placeholder="iframe height"
              variant="outlined"
              InputProps={{
                endAdornment: <InputAdornment position="end">pixels</InputAdornment>,
              }}
              fullWidth
              margin="dense"
            />
            <TextField
              {...formikTextProps('externalHeaderDesktopHeight')}
              type="number"
              label="External Header Desktop Height (>=768px wide)"
              placeholder="iframe height"
              variant="outlined"
              InputProps={{
                endAdornment: <InputAdornment position="end">pixels</InputAdornment>,
              }}
              fullWidth
              margin="dense"
            />
            <TextField
              {...formikTextProps('externalFooterUrl')}
              label="External Footer URL"
              placeholder="iframe url"
              variant="outlined"
              fullWidth
              margin="dense"
            />
            <TextField
              {...formikTextProps('externalFooterMobileHeight')}
              type="number"
              label="External Footer Mobile Height (<480px wide)"
              placeholder="iframe height"
              variant="outlined"
              InputProps={{
                endAdornment: <InputAdornment position="end">pixels</InputAdornment>,
              }}
              fullWidth
              margin="dense"
            />
            <TextField
              {...formikTextProps('externalFooterTabletHeight')}
              type="number"
              label="External Footer Tablet Height (<768px wide)"
              placeholder="iframe height"
              variant="outlined"
              InputProps={{
                endAdornment: <InputAdornment position="end">pixels</InputAdornment>,
              }}
              fullWidth
              margin="dense"
            />
            <TextField
              {...formikTextProps('externalFooterDesktopHeight')}
              type="number"
              label="External Footer Desktop Height (>=768px wide)"
              placeholder="iframe height"
              variant="outlined"
              InputProps={{
                endAdornment: <InputAdornment position="end">pixels</InputAdornment>,
              }}
              fullWidth
              margin="dense"
            />
            <Typography component="h3" variant="h5">
              Logo Image
            </Typography>
            <ImageUpload
              value={values.logoChecksum}
              onChange={(opt) => setFieldValue('logoChecksum', opt)}
              onUpload={uploadImage}
              buttonComponent={Button}
              buttonProps={{
                variant: 'contained',
              }}
            />
            <ErrorMessage component={Typography} name="imageChecksum" className={classes.error} />
            <TextField
              {...formikTextProps('logoHeightHeader')}
              type="number"
              label="Height of Logo in Header"
              placeholder="logo height"
              variant="outlined"
              InputProps={{
                endAdornment: <InputAdornment position="end">pixels</InputAdornment>,
              }}
              fullWidth
              margin="dense"
            />
            <TextField
              {...formikTextProps('logoLinkUrl')}
              label="Logo Link"
              placeholder="link url"
              variant="outlined"
              fullWidth
              margin="dense"
            />
            <Typography component="h3" variant="h5">
              Default Event Image
            </Typography>
            <ImageUpload
              value={values.defaultEventImageChecksum}
              onChange={(opt) => setFieldValue('defaultEventImageChecksum', opt)}
              onUpload={uploadImage}
              buttonComponent={Button}
              buttonProps={{
                variant: 'contained',
              }}
            />
            <ErrorMessage component={Typography} name="defaultEventImageChecksum" className={classes.error} />

            <AdminCategoryPicker
              label="Homepage Events Categories"
              placeholder="Categories"
              value={values.homeEvents}
              onChange={(opt) => setFieldValue('homeEvents', opt)}
              onBlur={() => setFieldTouched('homeEvents', true)}
              multiple
              categories={categories}
            />
            <ErrorMessage component="div" name="homeEvents" className={styles.error} />

            <AdminCategoryPicker
              label="Homepage Categories"
              placeholder="Categories"
              value={values.homeCategories}
              onChange={(opt) => setFieldValue('homeCategories', opt)}
              onBlur={() => setFieldTouched('homeCategories', true)}
              multiple
              categories={categories}
            />
            <ErrorMessage component="div" name="homeCategories" className={styles.error} />

            <AdminCategoryPicker
              label="Site Nav Categories"
              placeholder="Categories"
              value={[...values.siteNavCategories].sort()}
              onChange={(opt) => setFieldValue('siteNavCategories', opt)}
              onBlur={() => setFieldTouched('siteNavCategories', true)}
              multiple
              categories={categories}
            />
            <ErrorMessage component="div" name="siteNavCategories" className={styles.error} />

            <div>
              <label>
                <Checkbox
                  checked={values.ticketmasterFeedEnabled}
                  onChange={(e) => setFieldValue('ticketmasterFeedEnabled', e.target.checked)}
                />
                Enable Ticketmaster Feed
              </label>
              <label>
                <Checkbox
                  checked={!values.preventSearchEngineIndex}
                  onChange={(e) => setFieldValue('preventSearchEngineIndex', !e.target.checked)}
                />
                Enable search engine indexing
              </label>
            </div>
            {status && status.msg && <Typography className={classes.error}>{status.msg}</Typography>}
            <Button type="submit" variant="contained" disabled={isSubmitting}>
              Submit
            </Button>
          </Form>
        )
      }}
    </Formik>
  )
}
