import React, { useContext } from 'react'
import { Redirect, Route, Switch } from 'react-router-dom'
import { Helmet } from 'react-helmet-async'

import styles from './SiteApp.module.css'
import ExternalHeader from './ExternalHeader'
import InternalHeader from './InternalHeader'
import Footer from './Footer'
import HomePage from './pages/HomePage'
import SearchPage from './pages/SearchPage'
import EventPage from './pages/EventPage'
import VenuePage from './pages/VenuePage'
import CategoriesPage from './pages/CategoriesPage'
import CategoryPage from './pages/CategoryPage'
import CategoryEditPage from './pages/CategoryEditPage'
import NeighborhoodsPage from './pages/NeighborhoodsPage'
import NeighborhoodPage from './pages/NeighborhoodPage'
import TermsOfServicePage from './pages/TermsOfServicePage'
import PrivacyPolicyPage from './pages/PrivacyPolicyPage'
import CreateEditEventPage from './pages/CreateEditEventPage'
import PromoteFeaturedDailyEventPage from './pages/PromoteFeaturedDailyEventPage'
import { SiteContext } from './SiteContext'
import Breadcrumbs from './Breadcrumbs'
import SiteNav from './SiteNav'
import SearchBar from './SearchBar'
import AuthRoute from './AuthRoute'
import ReceiptPage from './pages/ReceiptPage'
import MyEventsPage from './pages/MyEventsPage'
import MyReceiptsPage from './pages/MyReceiptsPage'
import FeaturedVenuePage from './pages/FeaturedVenuePage'
import ExternalFooter from './ExternalFooter'

const typography = {
  BodySerif: { className: styles.BodySerif, href: 'https://fonts.googleapis.com/css?family=Kadwa&display=swap' },
  HeadingSerif: {
    className: styles.HeadingSerif,
    href: 'https://fonts.googleapis.com/css?family=Source+Serif+Pro&display=swap',
  },
  BodySansSerif: {
    className: styles.BodySansSerif,
    href: 'https://fonts.googleapis.com/css?family=Cabin:600&display=swap',
  },
  HeadingSansSerif: {
    className: styles.HeadingSansSerif,
    href: 'https://fonts.googleapis.com/css?family=Nunito+Sans&display=swap',
  },
  BodyUgly: {
    className: styles.BodyUgly,
    href: 'https://fonts.googleapis.com/css?family=Rye&display=swap',
  },
  HeadingUgly: {
    className: styles.HeadingUgly,
    href: 'https://fonts.googleapis.com/css?family=Damion&display=swap',
  },
}

export default () => {
  const { site } = useContext(SiteContext)
  const bodyStyle = typography.BodySansSerif
  const headingStyle = typography.HeadingSansSerif
  return (
    <div className={`${styles.root} ${bodyStyle.className} ${headingStyle.className}`}>
      {site.canonicalDomain && (
        <Route>
          {({ location }) => (
            <Helmet>
              <link rel="canonical" href={`https://${site.canonicalDomain}${location.pathname}${location.search}`} />
            </Helmet>
          )}
        </Route>
      )}
      {site.preventSearchEngineIndex && (
        <Helmet>
          <meta name="robots" content="noindex" />
        </Helmet>
      )}
      <Helmet>
        <link href={bodyStyle.href} rel="stylesheet" />
        <link href={headingStyle.href} rel="stylesheet" />
      </Helmet>
      {site.externalHeaderUrl &&
      (site.externalHeaderMobileHeight || site.externalHeaderTabletHeight || site.externalHeaderDesktopHeight) ? (
        <ExternalHeader
          url={site.externalHeaderUrl}
          mobileHeight={site.externalHeaderMobileHeight}
          tabletHeight={site.externalHeaderTabletHeight}
          desktopHeight={site.externalHeaderDesktopHeight}
        />
      ) : (
        <InternalHeader
          name={site.name}
          logoChecksum={site.logoChecksum}
          linkUrl={site.logoLinkUrl}
          logoHeightHeader={site.logoHeightHeader}
        />
      )}
      <div className={styles.wideSearchBar}>
        <div className={styles.containedElement}>
          <SearchBar />
        </div>
      </div>
      <div className={styles.wideSiteNav}>
        <div className={styles.containedElement}>
          <SiteNav />
        </div>
      </div>
      <main>
        <Switch>
          <Route exact path="/">
            <HomePage />
          </Route>
          <Redirect from="/home" to="/" />
          <Route exact path="/search">
            <SearchPage breadcrumbs={[{ text: 'Search' }]} />
          </Route>
          <Route exact path="/event/:id">
            {({ match }) => <EventPage id={match.params.id} breadcrumbs={[{ text: 'Event Details' }]} />}
          </Route>
          <Route exact path="/venue/:id">
            {({ match }) => <VenuePage id={match.params.id} breadcrumbs={[{ text: 'Venue Details' }]} />}
          </Route>
          <Route exact path="/featuredvenue/:uid">
            {({ match }) => <FeaturedVenuePage uid={match.params.uid} />}
          </Route>
          <Route exact path="/categories">
            {({ match }) => <CategoriesPage breadcrumbs={[{ text: 'All Categories' }]} />}
          </Route>
          <Route exact path="/category/:id">
            {({ match }) => (
              <CategoryPage
                id={match.params.id}
                breadcrumbs={[{ text: 'All Categories', link: '/categories' }, { text: 'Category Page' }]}
              />
            )}
          </Route>
          <Route exact path="/category/:id/edit">
            {({ match }) => (
              <CategoryEditPage
                id={match.params.id}
                breadcrumbs={[{ text: 'All Categories', link: '/categories' }, { text: 'Edit Category' }]}
              />
            )}
          </Route>
          <Route exact path="/neighborhoods">
            {({ match }) => <NeighborhoodsPage breadcrumbs={[{ text: 'All Neighborhoods' }]} />}
          </Route>
          <Route exact path="/neighborhood/:id">
            {({ match }) => (
              <NeighborhoodPage
                type="neighborhood"
                name="Neighborhood"
                id={match.params.id}
                breadcrumbs={[{ text: 'All Neighborhoods', link: '/neighborhoods' }, { text: 'Neighborhood Page' }]}
              />
            )}
          </Route>
          <Route exact path="/place/:id">
            {({ match }) => (
              <NeighborhoodPage
                type="place"
                name="Place"
                id={match.params.id}
                breadcrumbs={[{ text: 'All Neighborhoods', link: '/neighborhoods' }, { text: 'Neighborhood Page' }]}
              />
            )}
          </Route>
          <Route exact path="/zipcode/:id">
            {({ match }) => (
              <NeighborhoodPage
                type="zipcode"
                name="Zip Code"
                id={match.params.id}
                breadcrumbs={[{ text: 'All Neighborhoods', link: '/neighborhoods' }, { text: 'Neighborhood Page' }]}
              />
            )}
          </Route>
          <Route exact path="/create/event">
            <CreateEditEventPage breadcrumbs={[{ text: 'Create Event' }]} />
          </Route>
          <Route exact path="/edit/event/:id">
            {({ match }) => <CreateEditEventPage id={match.params.id} breadcrumbs={[{ text: 'Edit Event' }]} />}
          </Route>
          <Route exact path="/promote/featured-daily-event/:id">
            {({ match }) => (
              <PromoteFeaturedDailyEventPage id={match.params.id} breadcrumbs={[{ text: 'Promote Event' }]} />
            )}
          </Route>
          <AuthRoute exact path="/account/receipt/:id">
            {({ match }) => (
              <ReceiptPage
                id={match.params.id}
                breadcrumbs={[
                  { text: 'My Account' },
                  { text: 'Receipts', link: '/account/receipts' },
                  { text: 'Receipt' },
                ]}
              />
            )}
          </AuthRoute>
          <AuthRoute exact path="/account/receipts">
            <MyReceiptsPage breadcrumbs={[{ text: 'My Account' }, { text: 'Receipts' }]} />
          </AuthRoute>
          <AuthRoute exact path="/account/events">
            <MyEventsPage breadcrumbs={[{ text: 'My Account' }, { text: 'Events' }]} />
          </AuthRoute>
          <Route exact path="/terms-of-service">
            <TermsOfServicePage breadcrumbs={[{ text: 'Terms of Service' }]} />
          </Route>
          <Route exact path="/privacy-policy">
            <PrivacyPolicyPage breadcrumbs={[{ text: 'Privacy Policy' }]} />
          </Route>
          <Route>
            <div className={styles.notFound}>
              <Breadcrumbs breadcrumbs={[{ text: 'Page not found' }]} />
              <h1>404 Not Found</h1>
            </div>
          </Route>
        </Switch>
      </main>
      <div className={styles.wideFooter}>
        <div className={styles.containedElement}>
          <Footer />
        </div>
      </div>
      {site.externalFooterUrl &&
      (site.externalFooterMobileHeight || site.externalFooterTabletHeight || site.externalFooterDesktopHeight) ? (
        <ExternalFooter
          url={site.externalFooterUrl}
          mobileHeight={site.externalFooterMobileHeight}
          tabletHeight={site.externalFooterTabletHeight}
          desktopHeight={site.externalFooterDesktopHeight}
        />
      ) : null}
    </div>
  )
}
