import React from 'react'
import { Icon } from 'leaflet'
import 'leaflet/dist/leaflet.css'
import ReactGA from 'react-ga'
import { initializeApp } from 'firebase/app'

import Fetch from './Fetch'
import { SiteContextProvider } from './SiteContext'
import { AuthContextProvider } from './AuthContext'
import { AppContextProvider } from './AppContext'
import { AnalyticsContextProvider, TrackUser } from './analytics'
import { GoogleTagContextProvider } from './ads'
import { GoogleTagManagerContextProvider } from './gtm'
import SiteApp from './SiteApp'
import SiteNotFoundPage from './pages/SiteNotFoundPage'
import { ImageServiceContextProvider } from './ImageServiceContext'
import { AsyncStripeProvider } from './stripe'
import { AdminUrlContext, LoginUrlContext, TimezoneContext, WidgetsUrlContext } from './contexts'
import AdminApp from './AdminApp'
import { LoginApp } from './LoginApp'
import { RecaptchaContext, setupRecaptcha } from './recaptcha'

// https://github.com/Leaflet/Leaflet/issues/4968
/* eslint-disable no-underscore-dangle, global-require */
delete Icon.Default.prototype._getIconUrl
Icon.Default.mergeOptions({
  iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
  iconUrl: require('leaflet/dist/images/marker-icon.png'),
  shadowUrl: require('leaflet/dist/images/marker-shadow.png'),
})
/* eslint-enable no-underscore-dangle, global-require */

let gaInitialized = false
export default ({ token }) => (
  <Fetch input="/api/app" throwError={false}>
    {({ data, error }) => {
      if (error !== null) {
        if (error.resp && error.resp.status !== 404) {
          throw error
        }
        return <SiteNotFoundPage />
      }
      if (data === null) {
        return null
      }
      return <App {...data} token={token} />
    }}
  </Fetch>
)

export const App = ({
  type,
  config,
  mapboxAPIKey,
  recaptchaSiteKey,
  googleAnalyticsTrackingIDs = [],
  googleTagManagerContainerId,
  stripePublishableKey,
  widgetsUrl,
  imagesUrl,
  adminUrl,
  loginUrl,
  firebaseWebConfig,
  token,
}) => {
  const trackerOpts = googleAnalyticsTrackingIDs.map((id, i) => ({
    trackingId: id,
    gaOptions: {
      name: `t${i + 1}`,
    },
  }))
  const trackerNames = trackerOpts.filter((o) => o.gaOptions).map((o) => o.gaOptions.name)
  if (!gaInitialized && trackerOpts.length > 0) {
    ReactGA.initialize(trackerOpts, { alwaysSendToDefaultTracker: false })
    gaInitialized = true
  }
  if (recaptchaSiteKey) {
    setupRecaptcha()
  }
  const firebaseApp = initializeApp(firebaseWebConfig)
  switch (type) {
    case 'login': {
      return <LoginApp firebaseApp={firebaseApp} />
    }
    case 'admin': {
      return (
        <AppContextProvider value={{ mapboxAPIKey }}>
          <ImageServiceContextProvider imagesUrl={`${imagesUrl}/api/images`}>
            <WidgetsUrlContext.Provider value={{ widgetsUrl }}>
              <TimezoneContext.Provider value={{ timezone: 'America/New_York' }}>
                <AnalyticsContextProvider value={{ trackerNames }}>
                  <LoginUrlContext.Provider value={{ loginUrl }}>
                    <AuthContextProvider firebaseApp={firebaseApp} token={token}>
                      <AdminApp />
                    </AuthContextProvider>
                  </LoginUrlContext.Provider>
                </AnalyticsContextProvider>
              </TimezoneContext.Provider>
            </WidgetsUrlContext.Provider>
          </ImageServiceContextProvider>
        </AppContextProvider>
      )
    }
    case 'site': {
      return (
        <AppContextProvider value={{ mapboxAPIKey }}>
          <AsyncStripeProvider publishableKey={stripePublishableKey}>
            <SiteContextProvider value={{ site: config }}>
              <AdminUrlContext.Provider value={{ adminUrl }}>
                <ImageServiceContextProvider imagesUrl={`${imagesUrl}/api/images`}>
                  <AnalyticsContextProvider value={{ trackerNames }}>
                    <GoogleTagContextProvider
                      networkCode={config.googleAds.networkCode}
                      leaderboard={config.googleAds.leaderboard}
                      rightRail={config.googleAds.rightRail}
                    >
                      <GoogleTagManagerContextProvider id={googleTagManagerContainerId}>
                        <RecaptchaContext.Provider value={{ recaptchaSiteKey }}>
                          <LoginUrlContext.Provider value={{ loginUrl }}>
                            <AuthContextProvider firebaseApp={firebaseApp} token={token}>
                              <TrackUser>
                                <SiteApp />
                              </TrackUser>
                            </AuthContextProvider>
                          </LoginUrlContext.Provider>
                        </RecaptchaContext.Provider>
                      </GoogleTagManagerContextProvider>
                    </GoogleTagContextProvider>
                  </AnalyticsContextProvider>
                </ImageServiceContextProvider>
              </AdminUrlContext.Provider>
            </SiteContextProvider>
          </AsyncStripeProvider>
        </AppContextProvider>
      )
    }
    default:
      return null
  }
}
