import React, { useContext } from 'react'
import { Helmet } from 'react-helmet-async'
import { Typography } from '@material-ui/core'

import { TrackPageView } from '../analytics'
import AdminUGCDetails from '../AdminUGCDetails'
import { TimezoneContext } from '../contexts'

export default () => {
  const { timezone } = useContext(TimezoneContext)
  return (
    <div>
      <Helmet>
        <title>User Generated Content</title>
      </Helmet>
      <TrackPageView title="User Generated Content" />
      <Typography component="h1" variant="h2">
        User Generated Content
      </Typography>
      <AdminUGCDetails timezone={timezone} siteId="" />
    </div>
  )
}
