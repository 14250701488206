import moment from 'moment'

export const expandDateToLabelAndDay = (counts) =>
  counts.map(({ date, ...rest }) => {
    const v = moment(date, 'YYYY-MM-DD', true)
    if (!v.isValid()) {
      throw new Error(`invalid date: ${v}`)
    }
    return { label: v.format('M/D'), day: v.format('ddd'), date, ...rest }
  })
