import React from 'react'
import { makeStyles } from '@material-ui/core'
import clsx from 'clsx'

import MuiReactSelect from './MuiReactSelect'
import { catsToOptions, filterOption } from './searchopts'

const useStyles = makeStyles((theme) => ({
  path: {
    fontSize: 'x-small',
    fontVariant: 'small-caps',
    color: 'grey',
  },
  valuePath: {
    marginRight: '0.4em',
  },
  menuValue: {
    display: 'inline-block',
  },
  menuTopLevel: {
    fontWeight: 'bold',
  },
}))

export default ({
  value: selection,
  onChange,
  onBlur,
  multiple,
  categories = [],
  placeholder,
  label,
  error,
  helperText,
}) => {
  const classes = useStyles()
  const options = catsToOptions(categories)
  let value
  if (multiple) {
    value = selection ? options.filter((v) => selection.includes(v.id)) : []
  } else {
    value = options.filter((v) => selection === v.id)
    value = value.length === 0 ? null : value[0]
  }
  const handleChange = (data) => {
    let result
    if (multiple) {
      result = data ? data.map((v) => v.id) : []
    } else {
      result = data ? data.id : ''
    }
    onChange && onChange(result)
  }
  return (
    <MuiReactSelect
      value={value}
      isMulti={multiple}
      onChange={handleChange}
      onBlur={onBlur}
      options={options}
      getOptionValue={(opt) => opt.id}
      filterOption={filterOption}
      label={label}
      error={error}
      helperText={helperText}
      placeholder={placeholder}
      formatOptionLabel={(data, { context }) =>
        context === 'value' ? (
          <>
            {data.path && data.path.length > 0 && (
              <span className={clsx(classes.path, classes.valuePath)}>{data.path.join(' » ')} » </span>
            )}
            <span>{data.label}</span>
          </>
        ) : (
          <div className={classes.menuValue}>
            <div className={clsx(data.path.length === 0 && classes.menuTopLevel)}>{data.label}</div>
            {data.path && data.path.length > 0 && <div className={classes.path}>{data.path.join(' » ')}</div>}
          </div>
        )
      }
    />
  )
}
