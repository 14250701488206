import React, { useContext, useEffect, useState, Fragment } from 'react'
import { Map, Marker } from 'react-leaflet'
import { Helmet } from 'react-helmet-async'
import L from 'leaflet'
import Carousel from 'react-gallery-carousel'
import 'react-gallery-carousel/dist/index.css'

import styles from './FeaturedVenuePage.module.css'
import API from '../API'
import { SiteContext } from '../SiteContext'
import { TrackPageView } from '../analytics'
import useAds from '../useAds'
import Ad from '../Ad'
import ServiceError from '../ServiceError'
import ErrorBoundary from '../ErrorBoundary'
import DetailPageNotFound from '../DetailPageNotFound'
import Breadcrumbs from '../Breadcrumbs'
import CuratedVenueEvents from '../CuratedVenueEvents'
import { ImageServiceContext } from '../ImageServiceContext'
import CustomTileLayer from '../CustomTileLayer'

export const venueToLDJSON = ({ name, address, latitude, longitude }) => ({
  '@context': 'http://schema.org',
  '@type': 'Place',
  name: name,
  address: address,
  geo: {
    '@type': 'GeoCoordinates',
    latitude: latitude,
    longitude: longitude,
  },
})

const icon = L.icon({ iconUrl: '/marker-icon.png' })

const computeImageWidth = (elem) => {
  const w = Math.round(elem.innerWidth / 300) * 300
  if (w < elem.innerWidth) {
    return w + 300
  }
  return w
}

const FeaturedVenuePage = ({ uid, featuredVenue, curatedVenue }) => {
  const { site } = useContext(SiteContext)
  const { imageURL } = useContext(ImageServiceContext)
  const [imageWidth, setImageWidth] = useState(computeImageWidth(window))
  useEffect(() => {
    const cb = () => setImageWidth(computeImageWidth(window))
    window.addEventListener('resize', cb)
    return () => {
      document.removeEventListener('resize', cb)
    }
  }, [])
  useAds([
    { id: 'div-gpt-ad-right-rail-0', type: 'rightRail' },
    { id: 'div-gpt-ad-leaderboard-0', type: 'leaderboard' },
  ])
  const socialLinks = [
    { name: 'Facebook', url: curatedVenue.facebook },
    { name: 'Twitter', url: curatedVenue.twitter },
    { name: 'Yelp', url: curatedVenue.yelp },
    { name: 'Pinterest', url: curatedVenue.pinterest },
  ]
    .filter((i) => !!i.url)
    .map((i, idx, arr) => (
      <Fragment key={idx}>
        <a href={i.url}>{i.name}</a>
        {idx < arr.length - 1 ? ', ' : ''}
      </Fragment>
    ))
  const primaryImageUrl = imageURL({ size: `${imageWidth},`, checksum: curatedVenue.primaryImageChecksum })
  const images = [
    {
      src: primaryImageUrl,
    },
  ].concat(
    featuredVenue.additionalMedia.map((i) => ({
      src: imageURL({ size: `${imageWidth},`, checksum: i }),
    }))
  )
  return (
    <div className={styles.root}>
      <Helmet>
        <title>
          {curatedVenue.name} - {site.name}
        </title>
        <script type="application/ld+json">
          {JSON.stringify(venueToLDJSON({ ...curatedVenue, ...featuredVenue }))}
        </script>
      </Helmet>
      {featuredVenue.uid === uid && <TrackPageView title={curatedVenue.name} />}
      <Breadcrumbs breadcrumbs={[{ text: curatedVenue.name }]} />
      <div className={styles.layout}>
        <div className={styles.name}>{curatedVenue.name}</div>
        <div className={styles.address}>Address: {curatedVenue.address}</div>
        <div className={styles.hours}>
          {curatedVenue.hours ? 'Hours: ' : ''}
          <span className={styles.hoursContent} dangerouslySetInnerHTML={{ __html: curatedVenue.hours }} />
        </div>
        <div className={styles.phone}>
          {curatedVenue.phone ? 'Phone: ' : ''}
          {curatedVenue.phone}
        </div>
        <div className={styles.url}>
          {curatedVenue.url ? 'Website: ' : ''}
          <a href={curatedVenue.url}>{curatedVenue.url}</a>
        </div>
        <div className={styles.email}>
          {curatedVenue.email ? 'Email: ' : ''}
          {curatedVenue.email}
        </div>
        <div className={styles.marketingMessage} dangerouslySetInnerHTML={{ __html: featuredVenue.marketingMessage }} />
        <div
          className={styles.image}
          style={{
            backgroundImage: `url(${primaryImageUrl})`,
          }}
        >
          {images.length > 1 ? (
            <Carousel
              images={images}
              hasMediaButton={false}
              hasSizeButton={false}
              hasThumbnails={false}
              hasIndexBoard={false}
            />
          ) : null}
        </div>
        <div className={styles.socialLinks}>
          {socialLinks.length > 0 && <Fragment>Find us on: {socialLinks}</Fragment>}
        </div>
        <div className={styles.parking}>
          {curatedVenue.parking ? 'Parking: ' : ''}
          <span className={styles.parkingContent} dangerouslySetInnerHTML={{ __html: curatedVenue.parking }} />
        </div>
        <div className={styles.about} dangerouslySetInnerHTML={{ __html: curatedVenue.about }} />
        <Map className={styles.map} center={[curatedVenue.latitude, curatedVenue.longitude]} zoom={13}>
          <CustomTileLayer />
          <Marker position={[curatedVenue.latitude, curatedVenue.longitude]} icon={icon} />
        </Map>
        <div className={styles.events}>
          <CuratedVenueEvents curatedVenue={curatedVenue} />
        </div>
        <div className={styles.ads}>
          <Ad id="div-gpt-ad-right-rail-0" type="rightRail" />
        </div>
      </div>
      <Ad id="div-gpt-ad-leaderboard-0" type="leaderboard" />
    </div>
  )
}

export default ({ uid }) => (
  <ErrorBoundary component={() => <ServiceError message="Venue temporarily unavailable" />}>
    <API input={`/api/featuredvenue?uid=${uid}`} throwError={false}>
      {({ data, error }) => {
        if (error !== null) {
          if (error.resp && error.resp.status !== 404) {
            throw error
          }
          return <DetailPageNotFound message="Venue not found" />
        }
        if (data === null) {
          return null
        }
        return <FeaturedVenuePage {...data} uid={uid} />
      }}
    </API>
  </ErrorBoundary>
)
