import React from 'react'
import { Helmet } from 'react-helmet-async'
import { Typography } from '@material-ui/core'

import { TrackPageView } from '../analytics'
import AdminFeaturedVenues from '../AdminFeaturedVenues'

export default () => {
  return (
    <div>
      <Helmet>
        <title>Featured Venues - Vyb</title>
      </Helmet>
      <TrackPageView title="Featured Venues" />
      <Typography component="h1" variant="h2">
        Featured Venues
      </Typography>
      <AdminFeaturedVenues />
    </div>
  )
}
