import React, { useContext } from 'react'
import clsx from 'clsx'

import { makeStyles } from '@material-ui/core/styles'
import {
  AppBar,
  Toolbar,
  IconButton,
  Typography,
  Drawer,
  Divider,
  List,
  ListItem,
  ListItemText,
  Container,
  ListItemIcon,
} from '@material-ui/core'
import {
  Menu as MenuIcon,
  ChevronLeft as ChevronLeftIcon,
  Dashboard as DashboardIcon,
  Web as WebIcon,
  Settings as SettingsIcon,
  Widgets as WidgetsIcon,
  AddCircle as AddCircleIcon,
  People as PeopleIcon,
  Bookmark as BookmarkIcon,
  Bookmarks as BookmarksIcon,
  CloudDownload as CloudDownloadIcon,
  Store as StoreIcon,
  Payment as PaymentIcon,
  Star,
  Storefront,
  Announcement as AnnouncementIcon,
  AttachMoney as MoneyIcon,
  VpnKey as VpnKeyIcon,
} from '@material-ui/icons'

import { ReactComponent as Vyb } from './branding/vyb.svg'
import { AuthContext } from './AuthContext'
import AdminAccountDropdown from './AdminAccountDropdown'
import AdminSitePicker from './AdminSitePicker'
import { Link } from './AdminLink'
import AdminCreateSiteDialog from './AdminCreateSiteDialog'

const drawerWidth = 240

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  toolbar: {
    paddingRight: 24, // keep right padding when drawer closed
  },
  toolbarIcon: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: '0 8px',
    ...theme.mixins.toolbar,
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: 36,
  },
  menuButtonHidden: {
    display: 'none',
  },
  sites: {
    flexGrow: 1,
  },
  logo: {
    height: '1.5em',
    verticalAlign: 'middle',
    '& path': {
      fill: 'currentColor',
    },
  },
  drawerPaper: {
    position: 'relative',
    whiteSpace: 'nowrap',
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerPaperClose: {
    overflowX: 'hidden',
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    width: theme.spacing(7),
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing(9),
    },
  },
  appBarSpacer: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    height: '100vh',
    overflow: 'auto',
  },
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  paper: {
    padding: theme.spacing(2),
    display: 'flex',
    overflow: 'auto',
    flexDirection: 'column',
  },
  fixedHeight: {
    height: 240,
  },
}))

export default ({ children }) => {
  const classes = useStyles()
  const [open, setOpen] = React.useState(false)
  const { roles } = useContext(AuthContext)

  const handleDrawerOpen = () => {
    setOpen(true)
  }
  const handleDrawerClose = () => {
    setOpen(false)
  }
  return (
    <div className={classes.root}>
      <AppBar position="absolute" className={clsx(classes.appBar, open && classes.appBarShift)}>
        <Toolbar className={classes.toolbar}>
          <IconButton
            edge="start"
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            className={clsx(classes.menuButton, open && classes.menuButtonHidden)}
          >
            <MenuIcon />
          </IconButton>
          <Typography component="h1" variant="h6" color="inherit" noWrap className={classes.title}>
            <Link to="/">
              <Vyb className={classes.logo} alt="Vyb" />
            </Link>
          </Typography>
          <div className={classes.sites}>
            <AdminSitePicker />
          </div>
          <AdminAccountDropdown />
        </Toolbar>
      </AppBar>
      <Drawer
        variant="permanent"
        classes={{
          paper: clsx(classes.drawerPaper, !open && classes.drawerPaperClose),
        }}
        open={open}
      >
        <div className={classes.toolbarIcon}>
          <IconButton onClick={handleDrawerClose}>
            <ChevronLeftIcon />
          </IconButton>
        </div>
        <Divider />
        <List>
          <ListItem button component={Link} to="/site/dashboard">
            <ListItemIcon>
              <DashboardIcon />
            </ListItemIcon>
            <ListItemText primary="Dashboard" />
          </ListItem>
          {roles.includes('superadmin') && (
            <ListItem button component={Link} to="/site/edit">
              <ListItemIcon>
                <SettingsIcon />
              </ListItemIcon>
              <ListItemText primary="Edit Site" />
            </ListItem>
          )}
          <ListItem button component={Link} to="/site/announcements">
            <ListItemIcon>
              <AnnouncementIcon />
            </ListItemIcon>
            <ListItemText primary="Announcements" />
          </ListItem>
          <ListItem button component={Link} to="/site/ugc">
            <ListItemIcon>
              <BookmarkIcon />
            </ListItemIcon>
            <ListItemText primary="UGC" />
          </ListItem>
          <ListItem button component={Link} to="/site/upsellrates">
            <ListItemIcon>
              <MoneyIcon />
            </ListItemIcon>
            <ListItemText primary="Upsell Rates" />
          </ListItem>
          <ListItem button component={Link} to="/site/upsells">
            <ListItemIcon>
              <PaymentIcon />
            </ListItemIcon>
            <ListItemText primary="Upsells" />
          </ListItem>
          <ListItem button component={Link} to="/site/featuredvenues">
            <ListItemIcon>
              <Star />
            </ListItemIcon>
            <ListItemText primary="Featured Venues" />
          </ListItem>
          <ListItem button component={Link} to="/site/apikeys">
            <ListItemIcon>
              <VpnKeyIcon />
            </ListItemIcon>
            <ListItemText primary="API Keys" />
          </ListItem>
          <ListItem button component={Link} to="/site/widgets">
            <ListItemIcon>
              <WidgetsIcon />
            </ListItemIcon>
            <ListItemText primary="Widgets" />
          </ListItem>
          <ListItem button component={Link} to="/site/users">
            <ListItemIcon>
              <PeopleIcon />
            </ListItemIcon>
            <ListItemText primary="Users" />
          </ListItem>
          {roles.find((r) => r === 'superadmin' || /^siteadmin:/.test(r)) && (
            <>
              <Divider />
              {roles.includes('superadmin') && (
                <ListItem button component={Link} to="/ugc">
                  <ListItemIcon>
                    <BookmarksIcon />
                  </ListItemIcon>
                  <ListItemText primary="All UGC" />
                </ListItem>
              )}
              {roles.includes('superadmin') && (
                <ListItem button component={Link} to="/feeds">
                  <ListItemIcon>
                    <CloudDownloadIcon />
                  </ListItemIcon>
                  <ListItemText primary="Feeds" />
                </ListItem>
              )}
              <ListItem button component={Link} to="/curatedvenues">
                <ListItemIcon>
                  <Storefront />
                </ListItemIcon>
                <ListItemText primary="Curated Venues" />
              </ListItem>
              {roles.includes('superadmin') && (
                <>
                  <ListItem button component={Link} to="/venues">
                    <ListItemIcon>
                      <StoreIcon />
                    </ListItemIcon>
                    <ListItemText primary="Scrape Venues" />
                  </ListItem>
                  <ListItem button component={Link} to="/scrapes">
                    <ListItemIcon>
                      <WebIcon />
                    </ListItemIcon>
                    <ListItemText primary="Scrapes" />
                  </ListItem>
                  <ListItem button component={Link} to="/ticketmastervenues">
                    <ListItemIcon>TM</ListItemIcon>
                    <ListItemText primary="Ticketmaster Venues" />
                  </ListItem>
                  <AdminCreateSiteDialog>
                    {({ open }) => (
                      <ListItem button onClick={open}>
                        <ListItemIcon>
                          <AddCircleIcon />
                        </ListItemIcon>
                        <ListItemText primary="Add Site" />
                      </ListItem>
                    )}
                  </AdminCreateSiteDialog>
                </>
              )}
            </>
          )}
        </List>
      </Drawer>
      <main className={classes.content}>
        <div className={classes.appBarSpacer} />
        <Container maxWidth="xl" className={classes.container}>
          {children}
        </Container>
      </main>
    </div>
  )
}
