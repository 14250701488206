import React from 'react'

export default class ErrorBoundary extends React.Component {
  state = { hasError: false }

  componentDidCatch() {
    this.setState({ hasError: true })
  }

  render() {
    const { hasError } = this.state
    if (!hasError) {
      return this.props.children
    }
    return React.createElement(this.props.component)
  }
}
