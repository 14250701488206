import React, { useContext, useState, useEffect } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { Button, Checkbox, Paper, Table, TableBody, TableCell, TableHead, TableRow, TextField } from '@material-ui/core'
import { FileCopyOutlined, SearchOutlined } from '@material-ui/icons'

import { AuthContext } from './AuthContext'
import { NewFetchError } from './Fetch'
import { AdminSiteContext } from './AdminSiteContext'

const useStyles = makeStyles({
  root: {
    padding: '1em',
    marginBottom: '1em',
  },
})

const getApiKeys = async ({ getIdToken, siteId }) => {
  const idToken = await getIdToken()
  const resp = await fetch(`/api/keys/list?site=${encodeURIComponent(siteId)}`, {
    headers: {
      authorization: `Bearer ${idToken}`,
    },
  })
  if (!resp.ok) {
    throw await NewFetchError(resp)
  }
  const data = await resp.json()
  return data
}

const save = async ({ getIdToken, siteId, apiKey, comment, enabled }) => {
  const idToken = await getIdToken()
  const resp = await fetch(`/api/key/update?site=${encodeURIComponent(siteId)}`, {
    headers: {
      authorization: `Bearer ${idToken}`,
    },
    method: 'POST',
    body: JSON.stringify({
      siteId,
      apiKey,
      comment,
      enabled,
    }),
  })
  if (!resp.ok) {
    throw await NewFetchError(resp)
  }
}

export default ({ apiUrl }) => {
  const classes = useStyles()
  const { getIdToken } = useContext(AuthContext)
  const { siteId, siteName } = useContext(AdminSiteContext)
  const [apiKeys, setApiKeys] = useState(null)

  useEffect(() => {
    let cancel = false
    ;(async () => {
      const data = await getApiKeys({ getIdToken, siteId })
      if (cancel) {
        return
      }
      setApiKeys(data)
    })()
    return () => {
      cancel = true
    }
  }, [getIdToken, siteId])

  const refresh = async () => {
    const data = await getApiKeys({ getIdToken, siteId })
    setApiKeys(data)
  }

  const createApiKey = async () => {
    const idToken = await getIdToken()
    const resp = await fetch(`/api/key/create?site=${encodeURIComponent(siteId)}`, {
      headers: {
        authorization: `Bearer ${idToken}`,
      },
    })
    if (!resp.ok) {
      throw await NewFetchError(resp)
    }
  }

  const deleteApiKey = async (apiKey) => {
    const idToken = await getIdToken()
    const resp = await fetch(
      `/api/key/delete?site=${encodeURIComponent(siteId)}&apiKey=${encodeURIComponent(apiKey)}`,
      {
        headers: {
          authorization: `Bearer ${idToken}`,
        },
      }
    )
    if (!resp.ok) {
      throw await NewFetchError(resp)
    }
  }

  const ApiKeyRow = ({ apiKey, comment: origComment, enabled: origEnabled }) => {
    const [comment, setComment] = useState(origComment)
    const [enabled, setEnabled] = useState(origEnabled)
    useEffect(() => {
      ;(async () => {
        if (enabled !== origEnabled) {
          await save({ getIdToken, siteId, apiKey, comment, enabled })
          const data = await getApiKeys({ getIdToken, siteId })
          setApiKeys(data)
        }
      })()
    }, [enabled, origEnabled, apiKey, comment])
    const saveAndRefresh = async () => {
      await save({ getIdToken, siteId, apiKey, comment, enabled })
      await refresh()
    }
    return (
      <TableRow>
        <TableCell>{apiKey}</TableCell>
        <TableCell>
          <Button title={'Copy to clipboard'} onClick={() => navigator.clipboard.writeText(apiKey)}>
            <FileCopyOutlined />
          </Button>
        </TableCell>
        <TableCell>
          {enabled ? (
            <a href={`${apiUrl}?apiKey=${encodeURIComponent(apiKey)}`} rel="noopener noreferrer" target="_blank">
              <SearchOutlined />
            </a>
          ) : null}
        </TableCell>
        <TableCell>
          <TextField value={comment} onChange={(e) => setComment(e.target.value)} onBlur={(e) => saveAndRefresh()} />
        </TableCell>
        <TableCell>
          <Checkbox checked={enabled} onChange={(e) => setEnabled(e.target.checked)} />
        </TableCell>
        <TableCell align="right">
          <Button
            size="small"
            onClick={async () => {
              if (window.confirm(`Confirm delete ${apiKey}`)) {
                await deleteApiKey(apiKey)
                await refresh()
              }
            }}
          >
            Delete...
          </Button>
        </TableCell>
      </TableRow>
    )
  }

  return (
    <Paper className={classes.root}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Key</TableCell>
            <TableCell>Clipboard</TableCell>
            <TableCell>Test</TableCell>
            <TableCell>Comment</TableCell>
            <TableCell>Enabled</TableCell>
            <TableCell align="right">
              <Button
                variant="contained"
                onClick={async () => {
                  await createApiKey()
                  await refresh()
                }}
              >
                Create
              </Button>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {apiKeys
            ? apiKeys.length === 0 && (
                <TableRow>
                  <TableCell colSpan={5}>There are no api keys for {siteName}.</TableCell>
                </TableRow>
              )
            : null}
          {apiKeys ? apiKeys.map((v) => <ApiKeyRow key={v.apiKey} {...v} />) : null}
        </TableBody>
      </Table>
    </Paper>
  )
}
