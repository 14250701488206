import React from 'react'
import Select from 'react-select'
import AsyncSelect from 'react-select/async'
import { Typography, Paper, TextField, MenuItem, Chip } from '@material-ui/core'
import { makeStyles, emphasize, useTheme } from '@material-ui/core/styles'
import { Cancel as CancelIcon } from '@material-ui/icons'
import clsx from 'clsx'

const useStyles = makeStyles((theme) => ({
  input: {
    display: 'flex',
    padding: 0,
    height: 'auto',
  },
  valueContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    flex: 1,
    alignItems: 'center',
    overflow: 'hidden',
    padding: theme.spacing(1, 1),
  },
  chip: {
    margin: theme.spacing(0.5, 0.25),
  },
  chipFocused: {
    backgroundColor: emphasize(
      theme.palette.type === 'light' ? theme.palette.grey[300] : theme.palette.grey[700],
      0.08
    ),
  },
  noOptionsMessage: {
    padding: theme.spacing(1, 2),
  },
  singleValue: {
    fontSize: 16,
  },
  placeholder: {
    position: 'absolute',
    left: 10,
    bottom: 8,
    fontSize: 16,
  },
  paper: {
    position: 'absolute',
    zIndex: 10001, // needs to beat leaflet
    left: 0,
    right: 0,
  },
}))

const InputComponent = ({ inputRef, ...props }) => <div ref={inputRef} {...props} />

// https://material-ui.com/components/autocomplete/#react-select
const Control = ({ children, innerProps, innerRef, selectProps: { classes, TextFieldProps } }) => (
  <TextField
    fullWidth
    variant="outlined"
    margin="dense"
    InputProps={{
      inputComponent: InputComponent,
      inputProps: {
        className: classes.input,
        ref: innerRef,
        children,
        ...innerProps,
      },
    }}
    {...TextFieldProps}
  />
)

const Menu = ({ selectProps, innerProps, children }) => (
  <Paper square className={selectProps.classes.paper} {...innerProps}>
    {children}
  </Paper>
)

const MultiValue = ({ selectProps, removeProps, isFocused, children }) => (
  <Chip
    tabIndex={-1}
    label={children}
    className={clsx(selectProps.classes.chip, {
      [selectProps.classes.chipFocused]: isFocused,
    })}
    onDelete={removeProps.onClick}
    deleteIcon={<CancelIcon {...removeProps} />}
  />
)

const NoOptionsMessage = ({ selectProps, innerProps, children }) => (
  <Typography color="textSecondary" className={selectProps.classes.noOptionsMessage} {...innerProps}>
    {children}
  </Typography>
)

const Option = ({ innerRef, isFocused, isSelected, innerProps, children }) => (
  <MenuItem
    ref={innerRef}
    selected={isFocused}
    component="div"
    style={{
      fontWeight: isSelected ? 500 : 400,
    }}
    {...innerProps}
  >
    {children}
  </MenuItem>
)

const Placeholder = ({ selectProps, innerProps = {}, children }) => (
  <Typography color="textSecondary" className={selectProps.classes.placeholder} {...innerProps}>
    {children}
  </Typography>
)

const SingleValue = ({ selectProps, innerProps, children }) => (
  <Typography className={selectProps.classes.singleValue} {...innerProps}>
    {children}
  </Typography>
)

const ValueContainer = ({ selectProps, children }) => (
  <div className={selectProps.classes.valueContainer}>{children}</div>
)

export default ({ label, error, helperText, async, ...props }) => {
  const classes = useStyles()
  const theme = useTheme()
  const selectStyles = {
    input: (base) => ({
      ...base,
      color: theme.palette.text.primary,
      '& input': {
        font: 'inherit',
      },
    }),
  }
  const Component = async ? AsyncSelect : Select
  return (
    <Component
      {...props}
      classes={classes}
      styles={selectStyles}
      components={{
        Control,
        Menu,
        MultiValue,
        NoOptionsMessage,
        Option,
        Placeholder,
        SingleValue,
        ValueContainer,
      }}
      TextFieldProps={{
        label,
        error,
        helperText,
        InputLabelProps: {
          shrink: true,
        },
      }}
      label={label}
    />
  )
}
