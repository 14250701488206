import React from 'react'

import API from '../API'
import ServiceError from '../ServiceError'
import ErrorBoundary from '../ErrorBoundary'
import FeaturedDailyEvents from '../components/FeaturedDailyEvents'

export default () => (
  <ErrorBoundary component={() => <ServiceError message="Featured events temporarily unavailable" />}>
    <API input="/api/featured-daily-events">
      {({ data }) => data && <FeaturedDailyEvents events={data.events || []} />}
    </API>
  </ErrorBoundary>
)
