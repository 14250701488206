import React, { useContext } from 'react'
import { geoJSON } from 'leaflet'
import { Map, Marker, GeoJSON } from 'react-leaflet'
import L from 'leaflet'
import { Helmet } from 'react-helmet-async'

import styles from './NeighborhoodPage.module.css'
import API from '../API'
import EventCard from '../components/EventCard'
import CustomTileLayer from '../CustomTileLayer'
import { SiteContext } from '../SiteContext'
import { TrackPageView } from '../analytics'
import useAds from '../useAds'
import Ad from '../Ad'
import ErrorBoundary from '../ErrorBoundary'
import ServiceError from '../ServiceError'
import DetailPageNotFound from '../DetailPageNotFound'
import FeaturedDailyEvents from '../appcomponents/FeaturedDailyEvents'
import Breadcrumbs from '../Breadcrumbs'
import FeaturedVenues from '../appcomponents/FeaturedVenues'

const icon = L.icon({ iconUrl: '/marker-icon.png' })

const NeighborhoodEvents = ({ events }) =>
  events.length > 0 ? (
    <div className={styles.events}>
      <div>
        {events.map((event) => (
          <EventCard key={event.id} {...event} />
        ))}
      </div>
    </div>
  ) : (
    <div className={styles.noEvents}>No upcoming events match this neighborhood.</div>
  )

const NeighborhoodMap = ({ bounds, events }) => {
  var b = geoJSON(bounds).getBounds()
  return (
    <Map className={styles.map} bounds={b}>
      <CustomTileLayer />
      <GeoJSON data={bounds} style={{ color: 'rgba(244, 156, 253, 0.9)' }} />
      {events.map((e) => (
        <Marker key={e.id} position={[e.venue.latitude, e.venue.longitude]} title={e.title} icon={icon} />
      ))}
    </Map>
  )
}

const NeighborhoodPage = ({ type, id, name, neighborhood, breadcrumbs }) => {
  const { site } = useContext(SiteContext)
  useAds([
    { id: 'div-gpt-ad-right-rail-0', type: 'rightRail' },
    { id: 'div-gpt-ad-leaderboard-0', type: 'leaderboard' },
  ])
  return (
    <div className={styles.root}>
      <Helmet>
        <title>
          {neighborhood.name} - {site.name}
        </title>
      </Helmet>
      {neighborhood.id === id && <TrackPageView title={neighborhood.name} />}
      <Breadcrumbs breadcrumbs={breadcrumbs} />
      <h1 className={styles.title}>
        {name}: {neighborhood.name}
      </h1>
      <ErrorBoundary component={() => <ServiceError message="Events in this neighborhood temporarily unavailable" />}>
        <API input={`/api/neighborhoodevents?type=${encodeURIComponent(type)}&id=${encodeURIComponent(id)}`}>
          {({ data }) => (
            <div className={styles.layout}>
              <div>{data && <NeighborhoodEvents events={data.events} />}</div>
              <div>
                {data && <NeighborhoodMap bounds={neighborhood.bounds} events={data.events} />}
                <Ad id="div-gpt-ad-right-rail-0" type="rightRail" />
              </div>
            </div>
          )}
        </API>
      </ErrorBoundary>
      <FeaturedDailyEvents />
      <FeaturedVenues />
      <Ad id="div-gpt-ad-leaderboard-0" type="leaderboard" />
    </div>
  )
}

export default ({ type, id, name, breadcrumbs }) => (
  <ErrorBoundary component={() => <ServiceError message="Neighborhood temporarily unavailable" />}>
    <API input={`/api/neighborhood?type=${encodeURIComponent(type)}&id=${encodeURIComponent(id)}`} throwError={false}>
      {({ data, error }) => {
        if (error !== null) {
          if (error.resp && error.resp.status !== 404) {
            throw error
          }
          return <DetailPageNotFound message="Neighborhood not found" breadcrumbs={breadcrumbs} />
        }
        if (data === null) {
          return null
        }
        return <NeighborhoodPage type={type} id={id} name={name} neighborhood={data} breadcrumbs={breadcrumbs} />
      }}
    </API>
  </ErrorBoundary>
)
