import React from 'react'

import styles from './NearbyEvents.module.css'
import API from './API'
import EventCard from './components/EventCard'
import qs from './qs'
import ServiceError from './ServiceError'
import ErrorBoundary from './ErrorBoundary'

export default ({ latitude, longitude, excludes, excludeSeries }) => {
  const params = {
    lat: latitude,
    lon: longitude,
    excludes: excludes,
    excludeSeries: excludeSeries,
  }
  return (
    <ErrorBoundary component={() => <ServiceError message="Nearby events temporarily unavailable" />}>
      <API input={`/api/nearbyevents${qs.stringify(params)}`}>
        {({ data }) =>
          data === null ? null : data.events.length > 0 ? (
            <div className={styles.root}>
              <h1 className={styles.heading}>Nearby Events</h1>
              <div className={styles.layout}>
                <div>
                  {data.events.map((event) => (
                    <EventCard key={event.id} {...event} />
                  ))}
                </div>
              </div>
            </div>
          ) : (
            <div className={styles.none}>No upcoming events close by.</div>
          )
        }
      </API>
    </ErrorBoundary>
  )
}
