import React from 'react'
import {
  Card,
  CardContent,
  Link,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  ListSubheader,
  Typography,
} from '@material-ui/core'
import { InsertLink as InsertLinkIcon } from '@material-ui/icons'

export default ({ name, canonicalDomain, siteUrl, otherDomains }) => {
  return (
    <Card>
      <CardContent>
        <Typography gutterBottom variant="h5" component="h2">
          {name}
        </Typography>
        <List component="nav" aria-label="canonical domain">
          <ListSubheader component="div">Canonical Domain</ListSubheader>
          {canonicalDomain && (
            <Link href={`https://${canonicalDomain}`} target="_blank" rel="noreferrer">
              <ListItem button>
                <ListItemIcon>
                  <InsertLinkIcon />
                </ListItemIcon>
                <ListItemText primary={canonicalDomain} />
              </ListItem>
            </Link>
          )}
          {!canonicalDomain && (
            <ListItem button>
              <ListItemText primary="not set" />
            </ListItem>
          )}
          <ListSubheader component="div">Vyb Domain</ListSubheader>
          <Link href={siteUrl} target="_blank">
            <ListItem button>
              <ListItemIcon>
                <InsertLinkIcon />
              </ListItemIcon>
              <ListItemText primary={new URL(siteUrl).host} />
            </ListItem>
          </Link>
          {otherDomains.length > 0 && (
            <>
              <ListSubheader component="div">Alternate Domains</ListSubheader>
              {otherDomains.map((d) => (
                <Link key={d} href={`https://${d}`} target="_blank" rel="noreferrer">
                  <ListItem button>
                    <ListItemIcon>
                      <InsertLinkIcon />
                    </ListItemIcon>
                    <ListItemText primary={d} />
                  </ListItem>
                </Link>
              ))}
            </>
          )}
        </List>
      </CardContent>
    </Card>
  )
}
