import React, { useContext, useState } from 'react'

import styles from './Ad.module.css'
import { GoogleTagContext } from './ads'

export default ({ id, type }) => {
  const [rnd] = useState(Math.random())
  const { networkCode, leaderboard, rightRail } = useContext(GoogleTagContext)
  if (networkCode) {
    return <div id={id} className={styles.ad} />
  }
  const adUnits = { leaderboard, rightRail }
  const adUnit = adUnits[type]
  if (!adUnit) {
    return (
      <div id={id} className={styles.bad}>
        invalid type: {type}
      </div>
    )
  }
  const idx = Math.floor(rnd * adUnit.sizes.length)
  const size = adUnit.sizes[idx]
  return (
    <div id={id} style={{ width: size.width, height: size.height }} className={styles.placeholder}>
      <div className={styles.adCode}>{adUnit.code}</div>
    </div>
  )
}
