import React from 'react'

import { ReactComponent as Vyb } from './branding/vyb.svg'
import { GoogleSignIn } from './LoginWithGoogle'
import { EmailPasswordSignIn } from './LoginWithEmailPassword'

import styles from './Login.module.css'

export const Login = ({ firebaseApp }) => {
  return (
    <div className={styles.root}>
      <div className={styles.container}>
        <h2>
          <Vyb className={styles.vybLogo} />
        </h2>
        <GoogleSignIn firebaseApp={firebaseApp} />
        <div className={styles.orEmail}>Or login with your email and password</div>
        <EmailPasswordSignIn firebaseApp={firebaseApp} />
      </div>
    </div>
  )
}
