import { createLocation } from 'history'

import qs from './qs'

export const stickySearch = (whitelist, to, currentLocation) => {
  var next = to
  if (typeof next === 'function') {
    next = next(currentLocation)
  }
  if (typeof next === 'string') {
    next = createLocation(next, null, null, currentLocation)
  }
  if (currentLocation.search) {
    const currentParams = qs.parse(currentLocation.search)
    const nextParams = qs.parse(next.search)
    let dirty = false
    Object.keys(currentParams)
      .filter((name) => whitelist.includes(name))
      .forEach((name) => {
        if (!nextParams.hasOwnProperty(name)) {
          nextParams[name] = currentParams[name]
          dirty = true
        }
      })
    if (dirty) {
      next.search = qs.stringify(nextParams)
    }
  }
  return next
}
