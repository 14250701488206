import React, { useContext } from 'react'
import { Helmet } from 'react-helmet-async'

import styles from './PromoteFeaturedDailyEventPage.module.css'
import { SiteContext } from '../SiteContext'
import { TrackPageView } from '../analytics'
import PromoteFeaturedDailyEvent from '../appcomponents/PromoteFeaturedDailyEvent'
import Breadcrumbs from '../Breadcrumbs'

export default ({ id, breadcrumbs }) => {
  const { site } = useContext(SiteContext)
  return (
    <div className={styles.root}>
      <Helmet>
        <title>Promote Event - {site.name}</title>
      </Helmet>
      <TrackPageView title="Promote Featured Daily Event" />
      <Breadcrumbs breadcrumbs={breadcrumbs} />
      <h1 className={styles.heading}>Promote Event</h1>
      <PromoteFeaturedDailyEvent id={id} />
    </div>
  )
}
