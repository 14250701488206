import React, { useContext, useState, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { Button, Checkbox, FormControlLabel, FormGroup, MenuItem, Paper, TextField } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'

import { AuthContext } from './AuthContext'
import AdminCategoryPicker from './AdminCategoryPicker'
import AdminVenuePicker from './AdminVenuePicker'
import { NewFetchError } from './Fetch'
import { useStickySearch } from './AdminLink'

const useStyles = makeStyles({
  root: {
    maxWidth: '480px',
    padding: '16px',
  },
  checkbox: {
    userSelect: 'none',
  },
})

const plugins = ['carbonhouse', 'siphon', 'tribe']

const getVenues = (getIdToken) => async (inputValue, callback) => {
  const idToken = await getIdToken()
  const resp = await fetch(`/api/scrapevenues/autocomplete?q=${encodeURIComponent(inputValue)}`, {
    headers: {
      authorization: `Bearer ${idToken}`,
    },
  })
  if (!resp.ok) {
    throw await NewFetchError(resp)
  }
  const data = await resp.json()
  callback(data.matches)
}

export default ({ id, categories }) => {
  const { getIdToken } = useContext(AuthContext)
  const history = useHistory()
  const stickySearch = useStickySearch()
  const classes = useStyles()
  const [url, setUrl] = useState('')
  const [plugin, setPlugin] = useState()
  const [category, setCategory] = useState()
  const [venue, setVenue] = useState()
  const [scheduled, setScheduled] = useState()
  const [manifest, setManifest] = useState()
  const [ready, setReady] = useState(false)
  const [submitting, setSubmitting] = useState(false)
  const [error, setError] = useState({})

  useEffect(() => {
    if (!id) {
      return
    }
    setReady(false)
    ;(async () => {
      const idToken = await getIdToken()
      const resp = await fetch(`/api/scrape?id=${encodeURIComponent(id)}`, {
        headers: {
          authorization: `Bearer ${idToken}`,
        },
      })
      if (!resp.ok) {
        throw await NewFetchError(resp)
      }
      const data = await resp.json()
      setUrl(data.url)
      setPlugin(data.plugin)
      setCategory(data.categoryId)
      setVenue(data.venue)
      setScheduled(data.scheduled)
      setManifest(data.manifest ? JSON.stringify(data.manifest, null, 4) : '')
      setReady(true)
    })()
  }, [getIdToken, id])

  const handleSave = () => {
    let manifestJson = null
    if (manifest) {
      try {
        manifestJson = JSON.parse(manifest)
      } catch (e) {
        setError({ ...error, manifest: 'Invalid JSON ' + e })
        return
      }
    }
    setSubmitting(true)
    ;(async () => {
      const idToken = await getIdToken()
      const resp = await fetch(`/api/scrape/update`, {
        method: 'post',
        body: JSON.stringify({
          id,
          url,
          plugin,
          categoryId: category,
          venueId: venue ? venue.id : undefined,
          scheduled,
          manifest: manifestJson,
        }),
        headers: {
          authorization: `Bearer ${idToken}`,
        },
      })
      if (!resp.ok) {
        if (resp.status === 400) {
          const data = await resp.json()
          if (data.type === 'validation_error') {
            setError(data.fields)
            setSubmitting(false)
            return
          }
        }
        throw await NewFetchError(resp)
      }
      history.push(stickySearch('/scrapes'))
    })()
  }

  const scheduleNow = async () => {
    setSubmitting(true)
    const idToken = await getIdToken()
    try {
      const resp = await fetch(`/api/scrape/schedule?id=${encodeURIComponent(id)}`, {
        method: 'post',
        headers: {
          authorization: `Bearer ${idToken}`,
        },
      })
      if (!resp.ok) {
        setError({ scheduled: 'Unable to schedule' })
      } else {
        setScheduled(true)
        setError({})
      }
    } finally {
      setSubmitting(false)
    }
  }

  if (id && !ready) {
    return null
  }

  return (
    <Paper className={classes.root}>
      <TextField
        select
        name="plugin"
        value={plugin}
        onChange={(e) => {
          setPlugin(e.target.value)
        }}
        error={error.plugin}
        helperText={error.plugin}
        margin="dense"
        label="Plugin"
        autoComplete="off"
        variant="outlined"
        fullWidth
      >
        {plugins.map((v) => (
          <MenuItem key={v} value={v}>
            {v}
          </MenuItem>
        ))}
      </TextField>
      {plugin === 'siphon' ? (
        <TextField
          label="Manifest"
          multiline
          rows={20}
          value={manifest}
          onChange={(e) => setManifest(e.target.value)}
          error={error.manifest}
          helperText={error.manifest}
          margin="dense"
          autoComplete="off"
          variant="outlined"
          fullWidth
        />
      ) : (
        <TextField
          autoFocus={!id}
          name="url"
          value={url}
          onChange={(e) => {
            setUrl(e.target.value)
          }}
          error={error.url}
          helperText={error.url}
          margin="dense"
          label="URL"
          autoComplete="off"
          variant="outlined"
          fullWidth
        />
      )}
      <AdminVenuePicker
        label="Venue"
        placeholder="Venue"
        value={venue}
        error={error.venue}
        helperText={error.venue}
        onChange={setVenue}
        getVenues={getVenues(getIdToken)}
      />
      <AdminCategoryPicker
        label="Category"
        placeholder="Category"
        value={category}
        error={error.category}
        helperText={error.category}
        onChange={setCategory}
        categories={categories}
      />
      <FormGroup row>
        <FormControlLabel
          control={<Checkbox checked={scheduled} onChange={(e) => setScheduled(e.target.checked)} />}
          label="Scheduled"
          className={classes.checkbox}
        />
        {error.scheduled ? <div>{error.scheduled}</div> : null}
      </FormGroup>
      <Button variant="contained" onClick={scheduleNow} disabled={submitting}>
        Schedule for immediate execution
      </Button>{' '}
      <Button variant="contained" onClick={handleSave} disabled={submitting}>
        Save
      </Button>
    </Paper>
  )
}
