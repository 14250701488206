import React from 'react'

import styles from './ShoppingCart.module.css'
import Price from '../Price'

export default ({ rows = [], propsKey = 'id', children }) => (
  <div className={styles.root}>
    <table>
      <thead>
        <tr>
          <td>Item</td>
          <td>Price</td>
        </tr>
      </thead>
      <tbody>
        {rows.length === 0 && (
          <tr>
            <td className={styles.empty} colSpan="2">
              Your cart is empty.
            </td>
          </tr>
        )}
        {rows.length > 0 &&
          rows.map((r) => (
            <tr key={r.props[propsKey]}>
              <td>{children(r.props)}</td>
              <td>
                <Price className={styles.price} cents={r.price} />
              </td>
            </tr>
          ))}
        {rows.length > 0 && (
          <tr>
            <td colSpan="2">
              Total:{' '}
              <Price className={styles.price} cents={rows.map((r) => r.price).reduce((acc, cur) => acc + cur, 0)} />
            </td>
          </tr>
        )}
      </tbody>
    </table>
  </div>
)
