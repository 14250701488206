import React, { useContext } from 'react'

import { AuthContext } from '../AuthContext'
import { Container, Paper, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  paper: {
    padding: theme.spacing(4),
  },
}))

export default () => {
  const { logout } = useContext(AuthContext)
  const classes = useStyles()
  return (
    <Container maxWidth="sm" className={classes.container}>
      <Paper className={classes.paper}>
        <Typography component="h1" variant="h2" gutterBottom>
          404 Not Found
        </Typography>
        <Typography gutterBottom>
          The page you tried to access either does not exist or requires permissions you do not have.
        </Typography>
        <Typography gutterBottom>
          You can try{' '}
          <a
            href="#logout"
            onClick={(e) => {
              e.preventDefault()
              logout()
            }}
          >
            logging in
          </a>{' '}
          as a different user.
        </Typography>
        <Typography gutterBottom>
          Or going to the <a href="/">main page</a>.
        </Typography>
      </Paper>
    </Container>
  )
}
