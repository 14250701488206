import React, { useContext } from 'react'
import { Helmet } from 'react-helmet-async'

import styles from './HomePage.module.css'
import Button from '../components/Button'
import API from '../API'
import EventCard from '../components/EventCard'
import HomeCategoryCard from '../HomeCategoryCard'
import { SiteContext } from '../SiteContext'
import { TrackPageView } from '../analytics'
import useAds from '../useAds'
import Ad from '../Ad'
import ServiceError from '../ServiceError'
import ErrorBoundary from '../ErrorBoundary'
import FeaturedDailyEvents from '../appcomponents/FeaturedDailyEvents'
import FeaturedVenues from '../appcomponents/FeaturedVenues'

const Announcements = () => (
  <API input="/api/announcements">
    {({ data }) =>
      data?.announcements ? (
        <div className={styles.homeAnnouncemnts} dangerouslySetInnerHTML={{ __html: data.announcements }}></div>
      ) : null
    }
  </API>
)

const UpcomingHighlightedEvents = () => (
  <ErrorBoundary component={() => <ServiceError message="Upcoming events temporarily unavailable" />}>
    <API input="/api/upcominghighlightedevents">
      {({ data }) =>
        data === null ? null : data.events.length > 0 ? (
          <div className={styles.upcomingHighlighted}>
            <div>
              {data.events.map((event) => (
                <EventCard key={event.id} {...event} />
              ))}
            </div>
          </div>
        ) : (
          <div className={styles.noUpcomingHighlighted}>No upcoming events in this region.</div>
        )
      }
    </API>
  </ErrorBoundary>
)

const HomeCategories = () => (
  <API input="/api/homecategories">
    {({ data }) =>
      data === null ? null : (
        <div className={styles.homeCategories}>
          <div>
            {data.homeCategories.map((category) => (
              <HomeCategoryCard key={category.id} {...category} />
            ))}
          </div>
        </div>
      )
    }
  </API>
)

export default () => {
  const { site } = useContext(SiteContext)
  useAds([
    { id: 'div-gpt-ad-right-rail-0', type: 'rightRail' },
    { id: 'div-gpt-ad-leaderboard-0', type: 'leaderboard' },
  ])
  return (
    <div className={styles.root}>
      <Helmet>
        <title>Events, concerts and things to do - {site.name}</title>
      </Helmet>
      <TrackPageView title="Events, concerts and things to do" />
      <Announcements />
      <FeaturedDailyEvents />
      <FeaturedVenues />
      <div className={styles.layout}>
        <div>
          <UpcomingHighlightedEvents />
        </div>
        <div>
          <Button className={styles.addEvent} to="/create/event">
            Add Your Event
          </Button>
          <Ad id="div-gpt-ad-right-rail-0" type="rightRail" />
        </div>
      </div>
      <Ad id="div-gpt-ad-leaderboard-0" type="leaderboard" />
      <HomeCategories />
    </div>
  )
}
