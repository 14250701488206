import React, { useContext, useState, useEffect } from 'react'
import { Button, Avatar, Menu, MenuItem } from '@material-ui/core'

import { AuthContext } from './AuthContext'
import { NewFetchError } from './Fetch'

export default () => {
  const { getIdToken, isLoggedIn, logout } = useContext(AuthContext)
  const [anchorEl, setAnchorEl] = React.useState(null)
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
  }
  const handleClose = () => {
    setAnchorEl(null)
  }

  const [me, setMe] = useState()
  useEffect(() => {
    ;(async () => {
      const tok = await getIdToken()
      if (!tok) {
        setMe(null)
        return
      }
      const resp = await fetch('/api/me', {
        headers: {
          authorization: `Bearer ${tok}`,
        },
      })
      if (!resp.ok) {
        throw await NewFetchError(resp)
      }
      const me = await resp.json()
      setMe(me)
    })()
  }, [getIdToken])
  if (!isLoggedIn || !me) {
    return null
  }

  return (
    <div>
      <Button aria-controls="simple-menu" aria-haspopup="true" onClick={handleClick}>
        <Avatar alt={me.name} src={me.picture} />
      </Button>
      <Menu anchorEl={anchorEl} keepMounted open={Boolean(anchorEl)} onClose={handleClose}>
        <MenuItem
          onClick={() => {
            handleClose()
            logout()
          }}
        >
          Logout
        </MenuItem>
      </Menu>
    </div>
  )
}
