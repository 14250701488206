import React from 'react'

import styles from './CuratedVenueEvents.module.css'
import API from './API'
import EventCard from './components/EventCard'
import qs from './qs'
import ServiceError from './ServiceError'
import ErrorBoundary from './ErrorBoundary'

export default ({ curatedVenue }) => {
  const params = { curatedVenueUid: curatedVenue.uid }
  return (
    <ErrorBoundary component={() => <ServiceError message="Events temporarily unavailable" />}>
      <API input={`/api/curatedvenueevents${qs.stringify(params)}`}>
        {({ data }) =>
          data === null ? null : data.events.length > 0 ? (
            <div className={styles.root}>
              <h1 className={styles.heading}>Upcoming Events</h1>
              <div className={styles.layout}>
                <div>
                  {data.events.map((event) => (
                    <EventCard key={event.id} {...event} />
                  ))}
                </div>
              </div>
            </div>
          ) : (
            <div className={styles.none}>No upcoming events.</div>
          )
        }
      </API>
    </ErrorBoundary>
  )
}
