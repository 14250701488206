import { useContext, useEffect } from 'react'
import { GoogleTagContext } from './ads'

export default (opts) => {
  const { googletag, networkCode, leaderboard, rightRail } = useContext(GoogleTagContext)
  useEffect(() => {
    if (!googletag) {
      return
    }
    const slots = opts
      .map(({ id, type }) => {
        const adUnits = { leaderboard, rightRail }
        const adUnit = adUnits[type]
        if (!adUnit) {
          return null
        }
        const sizes = adUnit.sizes.map(({ width, height }) => [width, height])
        return googletag.defineSlot(`/${networkCode}/${adUnit.code}`, sizes, id).addService(googletag.pubads())
      })
      .filter((v) => v !== null)
    googletag.enableServices()
    slots.forEach((slot) => {
      googletag.display(slot.getSlotElementId())
    })
    return () => {
      googletag.destroySlots(slots)
    }
  }, [googletag, leaderboard, networkCode, opts, rightRail])
}
