import React, { useContext } from 'react'
import { Helmet } from 'react-helmet-async'

import styles from './ReceiptPage.module.css'
import { SiteContext } from '../SiteContext'
import { TrackPageView } from '../analytics'
import Breadcrumbs from '../Breadcrumbs'
import API from '../API'
import Receipt, { receiptNumber } from '../Receipt'
import Button from '../components/Button'

export default ({ id, breadcrumbs }) => {
  const { site } = useContext(SiteContext)
  return (
    <div className={styles.root}>
      <Helmet>
        <title>
          Receipt {receiptNumber(id)} - {site.name}
        </title>
      </Helmet>
      <TrackPageView title="Receipt" />
      <Breadcrumbs breadcrumbs={breadcrumbs} />
      <h1 className={styles.heading}>Thank you for your purchase</h1>

      <API input={`/api/invoice/summary?invoiceId=${encodeURIComponent(id)}`}>
        {({ data }) => {
          if (!data) {
            return null
          }
          const eventId =
            data.featuredDailyEvent && data.featuredDailyEvent.length > 0 ? data.featuredDailyEvent[0]?.event?.id : null
          return (
            <>
              <Receipt {...data} />
              {eventId && (
                <div className={styles.actions}>
                  <Button to={`/event/${encodeURIComponent(eventId)}`}>View This Event</Button>
                  <Button to={`/promote/featured-daily-event/${encodeURIComponent(eventId)}`}>
                    Add More Featured Dates
                  </Button>
                </div>
              )}
            </>
          )
        }}
      </API>
    </div>
  )
}
