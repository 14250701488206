import React, { useCallback, useEffect, useRef, useState } from 'react'
import { getAuth, signOut, onIdTokenChanged, signInWithCustomToken } from 'firebase/auth'

export const AuthContext = React.createContext()

export const AuthContextProvider = ({ firebaseApp, token, children }) => {
  const [user, setUser] = useState(null)
  const [err, setErr] = useState(null)
  const [claims, setClaims] = useState(null)
  const [authStateChanged, setAuthStateChanged] = useState(false)
  const unsub = useRef(() => null)
  const getIdToken = useCallback(async () => {
    const u = getAuth(firebaseApp).currentUser
    let tok
    if (u) {
      const r = await u.getIdTokenResult()
      tok = r.token
    }
    return tok
    // user?.uid here in order to change the function instance whenever the user changes or logs in/out
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [firebaseApp, user?.uid])
  useEffect(() => {
    ;(async () => {
      const auth = getAuth(firebaseApp)
      try {
        setErr(null)
        if (token) {
          await signInWithCustomToken(auth, token)
        }
      } catch (e) {
        setErr(e)
      }
      unsub.current()
      unsub.current = onIdTokenChanged(auth, async (u) => {
        if (u) {
          const r = await u.getIdTokenResult()
          setClaims(r.claims)
        } else {
          setClaims(null)
        }
        setUser(u)
        setAuthStateChanged(true)
      })
    })()
    return () => unsub.current()
  }, [token, firebaseApp])

  if (!authStateChanged) {
    return null
  }

  return (
    <AuthContext.Provider
      value={{
        error: err,
        getIdToken,
        isLoggedIn: !!user?.uid,
        userId: claims?.userUid,
        userName: claims?.name,
        roles: claims?.roles || [],
        email: claims?.email,
        picture: claims?.picture,
        signInProviders: Object.keys(claims?.firebase?.identities || {}),
        login: () => {
          throw new Error('not implemented - not needed - going away')
        },
        logout: async () => {
          try {
            setErr(null)
            await signOut(getAuth(firebaseApp))
          } catch (e) {
            setErr(e)
          }
        },
      }}
    >
      {children}
    </AuthContext.Provider>
  )
}
