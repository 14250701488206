import React, { useContext } from 'react'
import { Helmet } from 'react-helmet-async'
import { MdNavigateBefore, MdNavigateNext } from 'react-icons/md'

import styles from './CategoryPage.module.css'
import { SiteContext } from '../SiteContext'
import { ImageServiceContext } from '../ImageServiceContext'
import { TrackPageView } from '../analytics'
import useAds from '../useAds'
import Ad from '../Ad'
import API from '../API'
import { Link } from '../Link'
import EventCard from '../components/EventCard'
import ErrorBoundary from '../ErrorBoundary'
import ServiceError from '../ServiceError'
import DetailPageNotFound from '../DetailPageNotFound'
import FeaturedDailyEvents from '../appcomponents/FeaturedDailyEvents'
import Button from '../components/Button'
import { AuthContext } from '../AuthContext'
import Breadcrumbs from '../Breadcrumbs'
import FeaturedVenues from '../appcomponents/FeaturedVenues'
import { useLocation } from 'react-router'
import qs from '../qs'

const Page = ({ page, maxPage, params, children }) => {
  const disabled = page < 1 || page > maxPage
  const { id } = params
  const to = { ...params }
  delete to.id
  if (!disabled) {
    to.page = page
  }
  return (
    <Link
      to={`/category/${encodeURIComponent(id)}${qs.stringify(to)}`}
      className={`${styles.page} ${disabled ? styles.disabled : ''}`}
    >
      {children}
    </Link>
  )
}

const Pagination = ({ currentPage, maxPage, params }) => {
  return (
    <div className={styles.pagination}>
      <Page page={currentPage - 1} maxPage={maxPage} params={params}>
        <MdNavigateBefore /> Previous
      </Page>
      <Page page={currentPage + 1} maxPage={maxPage} params={params}>
        Next <MdNavigateNext />
      </Page>
    </div>
  )
}

const CategoryHeader = ({ cat }) => {
  const { roles } = useContext(AuthContext)
  const { imageURL } = useContext(ImageServiceContext)
  return (
    <div
      className={styles.header}
      style={{ backgroundImage: `url(${imageURL({ checksum: cat.backgroundImageChecksum })})` }}
    >
      <div className={styles.shade} />
      <div className={styles.content}>
        <div>
          <h1>{cat.name}</h1>
        </div>
        <div className={styles.description}>
          {cat.description.split('\n').map((p, i) => (
            <p key={i}>{p}</p>
          ))}
          {roles.includes('superadmin') && (
            <Button to={`/category/${encodeURIComponent(cat.id)}/edit`}>Edit category</Button>
          )}
        </div>
        <div className={styles.children}>
          {cat.children &&
            cat.children.map((child) => (
              <div key={child.id} className={styles.child}>
                <Link to={`/category/${encodeURIComponent(child.id)}`}>{child.name}</Link>
              </div>
            ))}
        </div>
      </div>
    </div>
  )
}

const MoreCategories = ({ cat }) => {
  const { imageURL } = useContext(ImageServiceContext)
  if (!cat.siblings || cat.siblings.length === 0) {
    return null
  }
  return (
    <div className={styles.moreCategories}>
      <h2>More Categories</h2>
      {cat.siblings.map((child) => {
        return (
          <Link key={child.id} className={styles.categoryCard} to={`/category/${encodeURIComponent(child.id)}`}>
            <div
              className={styles.image}
              style={{ backgroundImage: `url(${imageURL({ size: '768,', checksum: child.thumbnailImageChecksum })})` }}
            />
            <div className={styles.name}>{child.name}</div>
          </Link>
        )
      })}
    </div>
  )
}

const getPage = (page, max) => {
  let p = parseInt(page)
  if (isNaN(p)) {
    p = 0
  }
  if (p < 1) {
    p = 1
  } else if (p > max) {
    p = max
  }
  return p
}

const CategoryEvents = ({ id }) => {
  const { search } = useLocation()
  const params = { ...qs.parse(search), id }
  const page = getPage(params.page, 100)
  let eventsQuery = {
    id,
    page: page,
  }

  return (
    <ErrorBoundary component={() => <ServiceError message="Events in this category temporarily unavailable" />}>
      <API input={`/api/categoryevents${qs.stringify(eventsQuery)}`}>
        {({ data }) => {
          if (data === null) {
            return null
          }
          if (data.events.length === 0) {
            return <div className={styles.noEvents}>No upcoming events match this category in your area.</div>
          }
          return (
            <div>
              <div className={styles.events}>
                <div>
                  {data.events.map((event) => (
                    <EventCard key={event.id} {...event} />
                  ))}
                </div>
              </div>
              <Pagination currentPage={data.currentPage} maxPage={data.maxPage} params={params} />
            </div>
          )
        }}
      </API>
    </ErrorBoundary>
  )
}

const CategoryPage = ({ id, category, breadcrumbs }) => {
  const { site } = useContext(SiteContext)
  useAds([
    { id: 'div-gpt-ad-right-rail-0', type: 'rightRail' },
    { id: 'div-gpt-ad-leaderboard-0', type: 'leaderboard' },
  ])
  return (
    <div className={styles.root}>
      <Helmet>
        <title>
          {category.name} - {site.name}
        </title>
      </Helmet>
      {category.id === id && <TrackPageView title={category.name} />}
      <Breadcrumbs breadcrumbs={breadcrumbs} />
      <section>
        <CategoryHeader cat={category} />
      </section>
      <div className={styles.layout}>
        <div>
          <CategoryEvents id={id} />
        </div>
        <div>
          <Ad id="div-gpt-ad-right-rail-0" type="rightRail" />
          <MoreCategories cat={category} />
        </div>
      </div>
      <FeaturedDailyEvents />
      <FeaturedVenues />
      <Ad id="div-gpt-ad-leaderboard-0" type="leaderboard" />
    </div>
  )
}

export default ({ id, breadcrumbs }) => (
  <ErrorBoundary component={() => <ServiceError message="Category temporarily unavailable" />}>
    <API input={`/api/category?id=${encodeURIComponent(id)}`} throwError={false}>
      {({ data, error }) => {
        if (error !== null) {
          if (error.resp && error.resp.status !== 404) {
            throw error
          }
          return <DetailPageNotFound message="Category not found" breadcrumbs={breadcrumbs} />
        }
        if (data === null) {
          return null
        }
        return <CategoryPage id={id} category={data} breadcrumbs={breadcrumbs} />
      }}
    </API>
  </ErrorBoundary>
)
