import React, { useContext, useState, useEffect } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { Button, Paper } from '@material-ui/core'
import ReactQuill from 'react-quill'
import 'react-quill/dist/quill.snow.css'

import { AuthContext } from './AuthContext'
import { NewFetchError } from './Fetch'
import { AdminSiteContext } from './AdminSiteContext'

const useStyles = makeStyles({
  root: {
    padding: '1em',
    marginBottom: '1em',
  },
})

export default () => {
  const classes = useStyles()

  return (
    <Paper className={classes.root}>
      <EditAnnouncements />
    </Paper>
  )
}

const EditAnnouncements = () => {
  const { getIdToken } = useContext(AuthContext)
  const { siteId } = useContext(AdminSiteContext)
  const [announcements, setAnnouncements] = useState('')
  const [ready, setReady] = useState(false)
  const [submitting, setSubmitting] = useState()
  const [error, setError] = useState({})

  useEffect(() => {
    setReady(false)
    ;(async () => {
      const idToken = await getIdToken()
      const resp = await fetch(`/api/announcements?site=${encodeURIComponent(siteId)}`, {
        headers: {
          authorization: `Bearer ${idToken}`,
        },
      })
      if (!resp.ok) {
        throw await NewFetchError(resp)
      }
      const data = await resp.json()
      setAnnouncements(data.announcements)
      setReady(true)
    })()
  }, [getIdToken, siteId])

  const save = async (data) => {
    const vErrs = {}
    if (Object.keys(vErrs).length > 0) {
      return vErrs
    }
    const idToken = await getIdToken()
    const resp = await fetch(`/api/announcements/update?site=${encodeURIComponent(siteId)}`, {
      method: 'post',
      body: JSON.stringify(data),
      headers: {
        authorization: `Bearer ${idToken}`,
      },
    })
    if (!resp.ok) {
      if (resp.status === 400) {
        try {
          const data = await resp.json()
          if (data.type === 'validation_error') {
            return data.fields || { name: 'unknown error' }
          }
        } catch {} // Treat as generic error
      }
      throw await NewFetchError(resp)
    }
  }

  const handleSubmit = async (successCallback = () => null) => {
    setSubmitting(true)
    const error = await save({
      announcements,
    })
    if (error) {
      setError(error)
      setSubmitting(false)
      return
    }
    setError(null)
    setSubmitting(false)
    successCallback()
  }

  if (!ready) {
    return null
  }
  return (
    <div>
      <ReactQuill
        theme="snow"
        defaultValue={announcements}
        onChange={(content, _delta, _source, _editor) => {
          const e = document.createElement('div')
          e.innerHTML = content
          setAnnouncements(/\S/.test(e.innerText) ? content : '')
        }}
      />
      {error?.announcements ? error.announcements : null}
      <div>
        <Button onClick={() => handleSubmit()} color="primary" disabled={submitting}>
          Save
        </Button>
        {submitting ? ' ...' : ''}
      </div>
    </div>
  )
}
