export default [
  'UTC',
  'Pacific/Honolulu',
  'America/Anchorage',
  'America/Los_Angeles',
  'America/Boise',
  'America/Denver',
  'America/Phoenix',
  'America/Chicago',
  'America/Detroit',
  'America/New_York',
]
