import React, { useContext } from 'react'
import { Helmet } from 'react-helmet-async'
import { Typography, Paper } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'

import { TrackPageView } from '../analytics'
import { WidgetsUrlContext } from '../contexts'
import { AdminSiteContext } from '../AdminSiteContext'
import WidgetContainer from '../widgets/WidgetContainer'

const bookmarklet = (url) =>
  'javascript:' + // eslint-disable-line no-script-url
  encodeURIComponent(
    `(function(){var t="${url}";var s=document.createElement("link");s.rel="stylesheet";s.href=t+"/widgets.css";document.getElementsByTagName("head")[0].appendChild(s);var e=document.createElement("script");e.src=t+"/widgets.js";document.getElementsByTagName("head")[0].appendChild(e)})();`
  )

const useStyles = makeStyles((theme) => ({
  root: {
    '& code': {
      fontFamily: 'monospace',
      backgroundColor: 'white',
      padding: '0.2em 0.4em',
    },
  },
  example: {
    display: 'block',
    whiteSpace: 'pre',
    fontFamily: 'monospace',
    backgroundColor: 'white',
    lineHeight: '1.4em',
    padding: '1em',
  },
  widgetPaper: {
    marginTop: '1em',
    maxWidth: '350px',
  },
}))
export default () => {
  const classes = useStyles()
  const { widgetsUrl } = useContext(WidgetsUrlContext)
  const { siteId, siteName } = useContext(AdminSiteContext)

  return (
    <div className={classes.root}>
      <Helmet>
        <title>Widgets - Vyb - {siteName}</title>
      </Helmet>
      <TrackPageView title="Widgets" />
      <Typography component="h1" variant="h2">
        Widgets ~ {siteName}
      </Typography>
      <ol>
        <li>
          <Typography>
            To access the widgets on your site you must include the following in the <code>&lt;head></code> of your
            page:
          </Typography>
          <div className={classes.example}>
            {`<link rel="stylesheet" href="${widgetsUrl}/widgets.css">`}
            {'\n'}
            {`<script src="${widgetsUrl}/widgets.js"></script>`}
          </div>
        </li>
        <li>
          <Typography>
            To place a widget on the page, pick an existing element for example <code>&lt;div id="example"/></code>,
            generate a valid selector for it, and include the following:
          </Typography>
          <div className={classes.example}>
            {`VybEvents.widget({ selector: '#example', site: '${siteId}', type: 'events' })`}
          </div>
        </li>
        <li>
          <Typography>
            To temporarily enable the widgets on any page, you can use this{' '}
            <a href={bookmarklet('https://widgets.vyb.events')}>bookmarklet</a>.
          </Typography>
        </li>
      </ol>
      <Typography component="h1" variant="h2">
        Events Widget
      </Typography>
      <Paper className={classes.widgetPaper}>
        <WidgetContainer entrypoint={`${widgetsUrl}/api/widgets`} site={siteId} type="events" />
      </Paper>
    </div>
  )
}
