import React, { useContext } from 'react'
import { Helmet } from 'react-helmet-async'

import styles from './SearchPage.module.css'
import SearchResults from '../SearchResults'
import { SiteContext } from '../SiteContext'
import { TrackPageView } from '../analytics'
import useAds from '../useAds'
import Ad from '../Ad'
import FeaturedDailyEvents from '../appcomponents/FeaturedDailyEvents'
import Breadcrumbs from '../Breadcrumbs'
import FeaturedVenues from '../appcomponents/FeaturedVenues'

export default ({ id, breadcrumbs }) => {
  const { site } = useContext(SiteContext)
  useAds([{ id: 'div-gpt-ad-leaderboard-0', type: 'leaderboard' }])
  return (
    <div className={styles.root}>
      <Helmet>
        <title>Search - {site.name}</title>
      </Helmet>
      <TrackPageView title="Search" />
      <Breadcrumbs breadcrumbs={breadcrumbs} />
      <SearchResults />
      <FeaturedDailyEvents />
      <FeaturedVenues />
      <Ad id="div-gpt-ad-leaderboard-0" type="leaderboard" />
    </div>
  )
}
