import React, { useContext, useEffect, useState } from 'react'
import { useLocation, useHistory } from 'react-router-dom'
import DayPickerInput from 'react-day-picker/DayPickerInput'
import moment from 'moment-timezone'
import { Table, TableHead, TableBody, TableRow, TableCell, Typography, Paper } from '@material-ui/core'
import { CheckBox as CheckBoxIcon, CheckBoxOutlineBlank as CheckBoxOutlineBlankIcon } from '@material-ui/icons'

import { AdminSiteContext } from './AdminSiteContext'
import { AuthContext } from './AuthContext'
import { NewFetchError } from './Fetch'
import qs from './qs'

const search = async (siteId, getIdToken, start, end, eventId) => {
  const idToken = await getIdToken()
  const resp = await fetch(
    `/api/ugcdetails?site=${encodeURIComponent(siteId)}${
      start.isValid() ? `&start=${encodeURIComponent(start.startOf('day').toISOString())}` : ''
    }${end.isValid() ? `&end=${encodeURIComponent(end.endOf('day').toISOString())}` : ''}${
      eventId ? `&eventId=${encodeURIComponent(eventId)}` : ''
    }`,
    {
      headers: {
        authorization: `Bearer ${idToken}`,
      },
    }
  )
  if (!resp.ok) {
    throw await NewFetchError(resp)
  }
  const data = await resp.json()
  return data
}

let onSubmit = () => null

export default ({ siteId, timezone }) => {
  const history = useHistory()
  const location = useLocation()
  const params = qs.parse(location.search)
  const { getIdToken } = useContext(AuthContext)
  const { siteUrl } = useContext(AdminSiteContext)
  const [start, setStart] = useState(
    params.start
      ? moment.tz(params.start, timezone).startOf('day')
      : moment().tz(timezone).add(-14, 'days').startOf('day')
  )
  const [end, setEnd] = useState(moment.tz(params.end, timezone).endOf('day'))
  const [eventId, setEventId] = useState(params.eventId)
  const [results, setResults] = useState({
    events: [],
    start: start.clone(),
    end: end.clone(),
    searching: false,
  })
  onSubmit = async (clearEventId) => {
    let s = start.clone()
    let e = end.clone()
    if (e.isBefore(s)) {
      let t = s
      s = e
      e = t
      setStart(s)
      setEnd(e)
    }
    if (eventId && !clearEventId) {
      s = moment.invalid()
      e = moment.invalid()
      setStart(s)
      setEnd(e)
      history.replace(
        `${location.pathname}${qs.stringify({
          ...params,
          eventId,
          start: undefined,
          end: undefined,
        })}${location.hash}`
      )
    } else {
      setEventId(null)
      if (!start.isValid()) {
        s = moment().tz(timezone).add(-14, 'days').startOf('day')
        setStart(s)
      }
      history.replace(
        `${location.pathname}${qs.stringify({
          ...params,
          eventId: undefined,
          start: s.startOf('day').format('YYYY-MM-DD'),
          end: e.isValid() ? e.endOf('day').format('YYYY-MM-DD') : undefined,
        })}${location.hash}`
      )
    }
    setResults({ ...results, searching: true })
    const data = await search(siteId, getIdToken, s, e, !clearEventId && eventId)
    setResults({ ...results, events: data, start: s, end: e, eventId: !clearEventId && eventId })
  }
  useEffect(() => {
    onSubmit()
  }, [siteId])
  return (
    <Paper>
      {!results.searching ? (
        <Typography gutterBottom variant="h5" component="h2">
          {results.events.length === 0 ? 'No' : results.events.length} Event{results.events.length !== 1 ? 's' : ''}{' '}
          Created {results.start.isValid() && results.start.format('LL')}
          {results.end.isValid() ? ` - ${results.end.format('LL')}` : null}
        </Typography>
      ) : (
        <Typography gutterBottom variant="h5" component="h2">
          Searching for events created {start.isValid() && start.format('LL')} - {end.isValid() && end.format('LL')}...
        </Typography>
      )}
      <label>
        Start:{' '}
        <DayPickerInput
          value={start.isValid() ? start.format('YYYY-MM-DD') : ''}
          onDayChange={(day) => {
            day = day ? moment.tz(day.toISOString(), timezone) : moment.invalid()
            setStart(day)
          }}
        />
      </label>{' '}
      <label>
        End:{' '}
        <DayPickerInput
          value={end.isValid() ? end.format('YYYY-MM-DD') : ''}
          onDayChange={(day) => {
            day = day ? moment.tz(day.toISOString(), timezone) : moment.invalid()
            setEnd(day)
          }}
        />
      </label>{' '}
      <button onClick={() => onSubmit(true)}>Search</button>
      {results.searching && (
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Please Wait ...</TableCell>
            </TableRow>
          </TableHead>
        </Table>
      )}
      {!results.searching ? (
        !results.events.length ? (
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>
                  No UGC events created {results.start.isValid() ? results.start.format('LL') : ''}-
                  {results.end.isValid() ? results.end.format('LL') : ''}
                </TableCell>
              </TableRow>
            </TableHead>
          </Table>
        ) : (
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Created</TableCell>
                <TableCell>Title</TableCell>
                <TableCell>Venue</TableCell>
                <TableCell>Created By</TableCell>
                <TableCell>Start Date</TableCell>
                {!siteId && (
                  <TableCell>
                    <span title="Quarantined">Q</span>
                  </TableCell>
                )}
                <TableCell>
                  <span title="Published">P</span>
                </TableCell>
                <TableCell>
                  <span title="Hidden">H</span>
                </TableCell>
                {!siteId && <TableCell>Site</TableCell>}
              </TableRow>
            </TableHead>
            <TableBody>
              {results.events.map((row, idx) => {
                const { event, user } = row
                return (
                  <TableRow key={event.id}>
                    <TableCell style={{ whiteSpace: 'nowrap' }}>
                      {moment(event.create_time).tz(timezone).format('MM/DD/YYYY')}
                    </TableCell>
                    <TableCell>
                      <a href={`${siteUrl}/event/${encodeURIComponent(event.id)}`} target="_blank" rel="noreferrer">
                        {event.title}
                      </a>
                    </TableCell>
                    <TableCell>{event.venue.name || event.venue.address}</TableCell>
                    <TableCell>
                      {user.displayName === user.email || !user.displayName
                        ? user.email
                        : `${user.displayName} <${user.email}>`}
                    </TableCell>
                    <TableCell style={{ whiteSpace: 'nowrap' }}>
                      {moment(event.start).tz(timezone).format('MM/DD/YYYY')}
                    </TableCell>
                    {!siteId && (
                      <TableCell>{event.quarantine ? <CheckBoxIcon /> : <CheckBoxOutlineBlankIcon />}</TableCell>
                    )}
                    <TableCell>{event.published ? <CheckBoxIcon /> : <CheckBoxOutlineBlankIcon />}</TableCell>
                    <TableCell>
                      {!siteId ? event.hidden : event.hidden ? <CheckBoxIcon /> : <CheckBoxOutlineBlankIcon />}
                    </TableCell>
                    {!siteId && <TableCell>{event.site}</TableCell>}
                  </TableRow>
                )
              })}
            </TableBody>
          </Table>
        )
      ) : null}
    </Paper>
  )
}
