import React from 'react'
import { Helmet } from 'react-helmet-async'
import { Typography } from '@material-ui/core'

import { TrackPageView } from '../analytics'
import AdminFeeds from '../AdminFeeds'

export default () => {
  return (
    <div>
      <Helmet>
        <title>Feeds - Vyb</title>
      </Helmet>
      <TrackPageView title="Feeds" />
      <Typography component="h1" variant="h2">
        Feeds
      </Typography>
      <AdminFeeds />
    </div>
  )
}
