import React from 'react'

import styles from './Breadcrumbs.module.css'
import { Link } from './Link'

export default ({ breadcrumbs = [] }) => (
  <div className={styles.root}>
    <span className={styles.crumb}>
      <Link to="/">Events Home</Link>
    </span>
    {breadcrumbs.map((b, i) => (
      <span key={b.link || i} className={styles.crumb}>
        {b.link ? <Link to={b.link}>{b.text}</Link> : b.text}
      </span>
    ))}
  </div>
)
