import React, { useEffect, useRef, useState } from 'react'

import styles from './ExternalHeader.module.css'

export default ({ url, mobileHeight, tabletHeight, desktopHeight }) => {
  const root = useRef(null)
  const [ready, setReady] = useState(false)
  useEffect(() => {
    if (root.current) {
      root.current.style.setProperty('--mobileHeight', mobileHeight + 'px')
      root.current.style.setProperty('--tabletHeight', tabletHeight + 'px')
      root.current.style.setProperty('--desktopHeight', desktopHeight + 'px')
      setReady(true)
    }
  }, [mobileHeight, tabletHeight, desktopHeight])
  return (
    <div ref={root} className={styles.root}>
      {ready ? <iframe title="Page Header" src={url} scrolling="no" className={styles.responsiveHeight} /> : null}
    </div>
  )
}
