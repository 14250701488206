import React, { useContext, useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { Helmet } from 'react-helmet-async'

import styles from './CreateEditEventPage.module.css'
import Button from '../components/Button'
import { SiteContext } from '../SiteContext'
import { TrackPageView } from '../analytics'
import CreateEventForm from '../components/CreateEventForm'
import { NewFetchError } from '../Fetch'
import { AuthContext } from '../AuthContext'
import API from '../API'
import { createUploadImageHandler } from '../api_utilities'
import Breadcrumbs from '../Breadcrumbs'
import { LoginUrlContext } from '../contexts'

const submitEvent =
  (getIdToken, history) =>
  async (event, promote, recaptchaResponseToken = null) => {
    const idToken = await getIdToken()
    const headers = {
      'Content-Type': 'application/json',
      authorization: `Bearer ${idToken}`,
    }
    if (recaptchaResponseToken) {
      headers['X-Recaptcha-Response'] = recaptchaResponseToken
    }
    const resp = await fetch('/api/event/update', {
      method: 'post',
      body: JSON.stringify(event),
      headers,
    })
    if (!resp.ok) {
      throw await NewFetchError(resp)
    }
    const data = await resp.json()
    const redirectUrl = promote // && moment(event.start).isAfter(moment())
      ? `/promote/featured-daily-event/${encodeURIComponent(data.eventId)}`
      : `/event/${encodeURIComponent(data.eventId)}`
    history.replace(redirectUrl)
  }

const getVenues = (getIdToken, eventId) => async (inputValue, callback) => {
  const idToken = await getIdToken()
  const resp = await fetch(
    `/api/geocode?event=${encodeURIComponent(eventId || '')}&q=${encodeURIComponent(inputValue)}`,
    {
      headers: {
        authorization: `Bearer ${idToken}`,
      },
    }
  )
  if (!resp.ok) {
    throw await NewFetchError(resp)
  }
  const data = await resp.json()
  callback(data.matches)
}

export default ({ id, breadcrumbs }) => {
  const { site } = useContext(SiteContext)
  const { getIdToken } = useContext(AuthContext)
  const { loginUrl } = useContext(LoginUrlContext)
  const history = useHistory()
  const [event, setEvent] = useState(null)
  const [idToken, setIdToken] = useState(null)
  useEffect(() => {
    if (!id) {
      setEvent({})
      ;(async () => {
        const token = await getIdToken()
        setIdToken(token)
      })()
      return
    }
    ;(async () => {
      const token = await getIdToken()
      setIdToken(token)
      if (!token) {
        return
      }
      const resp = await fetch(`/api/event?id=${encodeURIComponent(id)}`, {
        headers: {
          authorization: `Bearer ${token}`,
        },
      })
      if (!resp.ok) {
        throw await NewFetchError(resp)
      }
      const payload = await resp.json()
      setEvent(payload.event)
    })()
  }, [id, getIdToken])
  if (idToken && event === null) {
    return null
  }
  return (
    <div className={styles.root}>
      <Helmet>
        <title>
          {id ? 'Edit' : 'Create'} Event - {site.name}
        </title>
      </Helmet>
      <TrackPageView title={`${id ? 'Edit' : 'Create'} Event`} />
      <Breadcrumbs breadcrumbs={breadcrumbs} />
      {idToken && (
        <>
          <API input="/api/sitesearchopts">
            {({ data: sitesearchopts }) =>
              sitesearchopts && (
                <CreateEventForm
                  event={event}
                  timezone={site.timezone}
                  getVenues={getVenues(getIdToken, event.id)}
                  categories={sitesearchopts.categories.children}
                  uploadImage={createUploadImageHandler(getIdToken)}
                  onSubmit={submitEvent(getIdToken, history)}
                />
              )
            }
          </API>
        </>
      )}
      {!idToken && (
        <div>
          <h1 className={styles.heading}>Log in to {id ? 'Edit' : 'Create'} an Event</h1>
          <div className={styles.interstitialText}>
            To create or edit an event, you must log in. Click the button below to get started.
          </div>
          <div className={styles.interstitialActions}>
            <Button href={`${loginUrl}/login?redirect=${encodeURIComponent(window.location.href)}`}>Login</Button>
          </div>
        </div>
      )}
    </div>
  )
}
