import React from 'react'
import DayPicker, { DateUtils } from 'react-day-picker'
import moment from 'moment'
import 'react-day-picker/lib/style.css'

import styles from './InventoryDatepicker.module.css'

export default ({ selectedDays = [], onChange, start, min, max, overrides = {}, defaultModifier = 'available' }) => {
  const selectedDates = selectedDays.map((v) => moment(v, 'YYYY-MM-DD', true).toDate())
  const month = start ? moment(start, 'YYYY-MM-DD', true).toDate() : undefined
  const fromMonth = min ? moment(min, 'YYYY-MM-DD', true).toDate() : undefined
  const toMonth = max ? moment(max, 'YYYY-MM-DD', true).toDate() : undefined
  const hasOverride =
    (...modifiers) =>
    (day) => {
      const k = moment(day).format('YYYY-MM-DD')
      const v = overrides[k] ? overrides[k] : defaultModifier
      return modifiers.includes(v)
    }
  const modifiers = {
    disabled: [
      {
        before: fromMonth,
        after: toMonth,
      },
      hasOverride('locked', 'soldout', 'promoted'),
    ],
    available: hasOverride('available'),
    locked: hasOverride('locked'),
    soldout: hasOverride('soldout'),
    promoted: hasOverride('promoted'),
  }
  const handleDayClick = (day, { selected, disabled }) => {
    if (disabled) {
      return
    }
    let result = selected
      ? selectedDates.filter((selectedDay) => !DateUtils.isSameDay(selectedDay, day))
      : [...selectedDates, day]
    result = result.map((v) => moment(v).format('YYYY-MM-DD'))
    onChange && onChange(result)
  }
  return (
    <div className={styles.root}>
      <DayPicker
        modifiers={modifiers}
        month={month}
        fromMonth={fromMonth}
        toMonth={toMonth}
        selectedDays={selectedDates}
        onDayClick={handleDayClick}
      />
    </div>
  )
}
