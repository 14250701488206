import React from 'react'
import moment from 'moment'
import Select from 'react-select'

import styles from './Timepicker.module.css'

const opts = (() => {
  let opts = []
  const start = moment('1970-01-01 05:00:00')
  const end = moment('1970-01-02 05:00:00')
  let time = start.clone()
  while (time < end) {
    opts.push({ value: time.format('HH:mm'), label: time.format('h:mm a'), time: time.clone() })
    time.add(1, 'm')
  }
  return opts
})()

const filter = (prevDate) => (opt, sel) => {
  if (prevDate && prevDate.hours() === opt.data.time.hours() && prevDate.minutes() === opt.data.time.minutes()) {
    return true
  }
  sel = sel.trim()
  const hourMatch = sel.match(/^(\d{1,2})/)
  if (hourMatch) {
    let h = Number(hourMatch[1])
    if (h > 23) {
      return false
    }
    const candidates = [h]
    if (h > 0 && h < 12 && hourMatch[1][0] !== '0') {
      candidates.push(h + 12)
    }
    if (h === 12) {
      candidates.push(0)
    }
    if (candidates.indexOf(opt.data.time.hour()) === -1) {
      return false
    }
  }
  const minMatch = sel.match(/^\d{1,2}\s*:\s*(\d{1,2})/)
  if (!minMatch && !hourMatch) {
    return opt.data.time.minutes() % 30 === 0
  }
  if (!minMatch) {
    return opt.data.time.minutes() % 10 === 0
  }
  const m = Number(minMatch[1])
  if (minMatch[1].length === 1) {
    if (m > 5) {
      return false
    }
    return Math.floor(opt.data.time.minutes() / 10) === m
  }
  if (m > 59) {
    return false
  }
  return opt.data.time.minutes() === m
}

export default ({ value, onChange, onBlur, placeholder, initialSearch }) => {
  const matches = opts.filter((opt) => opt.value === value)
  const opt = matches.length === 0 ? '' : matches[0]
  return (
    <Select
      className={styles.root}
      value={opt}
      defaultInputValue={initialSearch}
      defaultMenuIsOpen={typeof initialSearch !== 'undefined' ? true : undefined}
      onChange={(opt) => onChange && onChange(opt ? opt.value : '')}
      onBlur={onBlur}
      options={opts}
      filterOption={filter(opt ? opt.time : undefined)}
      placeholder={placeholder}
      isClearable={true}
    />
  )
}
