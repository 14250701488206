import React, { useContext } from 'react'
import { Card, CardContent, Typography } from '@material-ui/core'
import { ResponsiveContainer, AreaChart, Area, XAxis, YAxis } from 'recharts'

import API from './API'
import { AdminSiteContext } from './AdminSiteContext'
import CustomizedAxisTick from './CustomizedAxisTick'
import { expandDateToLabelAndDay } from './adminutils'
import ServiceError from './ServiceError'
import ErrorBoundary from './ErrorBoundary'

export default () => {
  const { siteId } = useContext(AdminSiteContext)
  return (
    <Card>
      <CardContent>
        <Typography gutterBottom variant="h5" component="h2">
          Traffic (UTC)
        </Typography>
        <ErrorBoundary component={() => <ServiceError message="Traffic temporarily unavailable" />}>
          <API input={`/api/dashboard/traffic?site=${encodeURIComponent(siteId)}`}>
            {({ data }) =>
              data &&
              (data.counts && data.counts.length > 0 ? (
                <ResponsiveContainer width="100%" height={300}>
                  <AreaChart data={expandDateToLabelAndDay(data.counts)}>
                    <XAxis dataKey="label" tick={<CustomizedAxisTick />} />
                    <YAxis allowDecimals={false} />
                    <Area type="linear" dataKey="count" stroke="#795548" fill="#795548" />
                  </AreaChart>
                </ResponsiveContainer>
              ) : (
                <Typography>no data available</Typography>
              ))
            }
          </API>
        </ErrorBoundary>
      </CardContent>
    </Card>
  )
}
