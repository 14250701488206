import React, { useContext } from 'react'
import { useHistory } from 'react-router-dom'
import moment from 'moment'

import styles from './PromoteFeaturedDailyEvent.module.css'
import { AuthContext } from '../AuthContext'
import { NewFetchError } from '../Fetch'
import API from '../API'
import ServiceError from '../ServiceError'
import ErrorBoundary from '../ErrorBoundary'
import PromoteFeaturedDailyEvent from '../components/PromoteFeaturedDailyEvent'
import DateTimeRange from '../components/DateTimeRange'
import Button from '../components/Button'
import Image from '../Image'
import { LoginUrlContext } from '../contexts'

const EventPreview = ({ event }) => {
  const { title, start, end, noStart, noEnd, venue, imageChecksum } = event
  return (
    <div className={styles.eventPreview}>
      <Image className={styles.image} size="510," checksum={imageChecksum} alt={title} />
      <div className={styles.meta}>
        <h3>{title}</h3>
        <DateTimeRange className={styles.when} start={start} end={end} noStart={noStart} noEnd={noEnd} />
        <div>{venue.name}</div>
      </div>
    </div>
  )
}

export default ({ id }) => {
  const { getIdToken, isLoggedIn } = useContext(AuthContext)
  const { loginUrl } = useContext(LoginUrlContext)
  const history = useHistory()
  const handlers = {
    fetchInventory: async (eventId) => {
      const tok = await getIdToken()
      const resp = await fetch(`/api/inventory/featured-daily-events?eventId=${encodeURIComponent(eventId)}`, {
        headers: {
          authorization: `Bearer ${tok}`,
        },
      })
      if (!resp.ok) {
        if (resp.status === 400) {
          const msg = await resp.text()
          if (msg === 'PastEvent') {
            return { pastEvent: true }
          } else {
            throw await NewFetchError(resp)
          }
        } else {
          throw await NewFetchError(resp)
        }
      }
      const { min, max, overrides, rate } = await resp.json()
      return { min, max, overrides, rate }
    },
    adminFeatureEvent: async function (eventId, days) {
      const tok = await getIdToken()
      const resp = await fetch('/api/admin/feature-event', {
        method: 'post',
        body: JSON.stringify({ eventId, days }),
        headers: {
          'Content-Type': 'application/json',
          authorization: `Bearer ${tok}`,
        },
      })
      if (!resp.ok) {
        throw await NewFetchError(resp)
      }
    },
    createInvoice: async (eventId, days) => {
      const tok = await getIdToken()
      const resp = await fetch('/api/invoice/featured-daily-events', {
        method: 'post',
        body: JSON.stringify({ eventId, days }),
        headers: {
          'Content-Type': 'application/json',
          authorization: `Bearer ${tok}`,
        },
      })
      if (!resp.ok) {
        throw await NewFetchError(resp)
      }
      const { invoiceId, totalCents } = await resp.json()
      return { invoiceId, totalCents }
    },
    payInvoice: async (invoiceId, stripeToken) => {
      const tok = await getIdToken()
      const resp = await fetch(
        `/api/invoice/pay?invoiceId=${encodeURIComponent(invoiceId)}&stripeToken=${encodeURIComponent(stripeToken)}`,
        {
          method: 'post',
          headers: {
            authorization: `Bearer ${tok}`,
          },
        }
      )
      if (!resp.ok) {
        throw await NewFetchError(resp)
      }
      const { error } = await resp.json()
      return { error }
    },
    settleInvoice: async (invoiceId) => {
      const tok = await getIdToken()
      const resp = await fetch(`/api/invoice/settle?invoiceId=${encodeURIComponent(invoiceId)}`, {
        method: 'post',
        headers: {
          authorization: `Bearer ${tok}`,
        },
      })
      if (!resp.ok) {
        throw await NewFetchError(resp)
      }
      history.push(`/account/receipt/${encodeURIComponent(invoiceId)}`)
    },
    cancelInvoice: async (invoiceId) => {
      const tok = await getIdToken()
      const resp = await fetch(`/api/invoice/cancel?invoiceId=${encodeURIComponent(invoiceId)}`, {
        method: 'post',
        headers: {
          authorization: `Bearer ${tok}`,
        },
      })
      if (!resp.ok) {
        throw await NewFetchError(resp)
      }
    },
  }
  return (
    <ErrorBoundary component={() => <ServiceError message="Featured event promotions temporarily unavailable" />}>
      <API input={`/api/event?id=${id}`}>
        {({ data }) =>
          data && (
            <div className={styles.root}>
              {data.event && !moment(data.event.start).isAfter(moment()) ? (
                <h2>You cannot promote an event that is in the past</h2>
              ) : (
                <div className={styles.subheading}>
                  Get premiere placement and presentation. Upgrade your event today to boost its performance and
                  visibility.
                </div>
              )}
              {data.event && <EventPreview event={data.event} />}
              {isLoggedIn && <PromoteFeaturedDailyEvent event={data.event} {...handlers} />}
              {!isLoggedIn && (
                <div>
                  <h1 className={styles.heading}>Log in to Promote This Event</h1>
                  <div className={styles.interstitialActions}>
                    <Button href={`${loginUrl}/login?redirect=${encodeURIComponent(window.location.href)}`}>
                      Login
                    </Button>
                  </div>
                </div>
              )}
            </div>
          )
        }
      </API>
    </ErrorBoundary>
  )
}
