import React, { useContext } from 'react'

import styles from './Footer.module.css'
import { SiteContext } from './SiteContext'
import { Link } from './Link'
import A from './A'
import { ReactComponent as Vyb } from './branding/vyb.svg'
import Logo from './Logo'

const classNames = {
  1: styles.cols1,
  2: styles.cols2,
  3: styles.cols3,
  4: styles.cols4,
  5: styles.cols5,
}

export default ({ name, address }) => {
  const { site } = useContext(SiteContext)
  const maxSiteCols = 4
  const numSiteCols = Math.min(maxSiteCols, site.footerSections ? site.footerSections.length : 0)
  return (
    <footer className={styles.root}>
      <div className={`${styles.siteLinks} ${classNames[numSiteCols + 1]}`}>
        <Logo name={site.name} logoChecksum={site.logoChecksum} linkUrl={site.logoLinkUrl} className={styles.logo} />
        {site.footerSections.slice(0, numSiteCols).map((section) => (
          <div key={section.title}>
            <h4>{section.title}</h4>
            <ul>
              {section.links.map((link) => (
                <li key={link.title}>
                  <A href={link.url}>{link.title}</A>
                </li>
              ))}
            </ul>
          </div>
        ))}
        <div>
          <h4>Events</h4>
          <ul>
            <li>
              <Link to="/">Home</Link>
            </li>
            <li>
              <Link to="/search">Search</Link>
            </li>
            <li>
              <Link to="/neighborhoods">Neighborhoods</Link>
            </li>
            <li>
              <Link to="/categories">All Categories</Link>
            </li>
          </ul>
        </div>
      </div>
      <div className={styles.platformLinks}>
        <span>
          <Vyb alt="Vyb" className={styles.vybLogo} />
        </span>
        <span>
          <Link to="/terms-of-service">Terms of Service</Link>
        </span>
        <span>
          <Link to="/privacy-policy">Privacy Policy</Link>
        </span>
        {site.supportEmail ? (
          <span>
            <a href={`mailto:${site.supportEmail}`}>Support</a>
          </span>
        ) : null}
      </div>
    </footer>
  )
}
