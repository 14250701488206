import React from 'react'

import styles from './DetailPageNotFound.module.css'
import Breadcrumbs from './Breadcrumbs'

export default ({ message, breadcrumbs }) => (
  <div className={styles.root}>
    <Breadcrumbs breadcrumbs={breadcrumbs} />
    <h1 className={styles.message}>{message}</h1>
  </div>
)
