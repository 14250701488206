import React, { Component, useContext, useEffect } from 'react'
import ReactGA from 'react-ga'
import { useLocation } from 'react-router-dom'

import { AuthContext } from './AuthContext'

const AnalyticsContext = React.createContext()
export const AnalyticsContextProvider = AnalyticsContext.Provider
export const AnalyticsContextConsumer = AnalyticsContext.Consumer

// Influenced by https://github.com/react-ga/react-ga/wiki/React-Router-v4-withTracker
class TrackedPageRaw extends Component {
  trackPage = (page) => {
    ReactGA.set(
      {
        page,
        ...this.props.options,
      },
      this.props.trackerNames
    )
    ReactGA.pageview(page, this.props.trackerNames, this.props.title)
  }

  componentDidMount() {
    const page = this.props.location.pathname + this.props.location.search
    this.trackPage(page)
  }

  componentDidUpdate(prevProps) {
    const currentPage = prevProps.location.pathname + prevProps.location.search
    const nextPage = this.props.location.pathname + this.props.location.search
    if (currentPage !== nextPage) {
      this.trackPage(nextPage)
    }
  }

  render() {
    return null
  }
}

export const TrackUser = ({ children }) => {
  const { userId } = useContext(AuthContext)
  const { trackerNames } = useContext(AnalyticsContext)
  useEffect(() => {
    ReactGA.set({ userId }, trackerNames)
  }, [userId, trackerNames])
  return children
}

export const TrackPageView = (props) => {
  const location = useLocation()
  return (
    <AnalyticsContextConsumer>
      {({ trackerNames }) => <TrackedPageRaw location={location} trackerNames={trackerNames} {...props} />}
    </AnalyticsContextConsumer>
  )
}
