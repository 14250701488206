import React, { useContext, useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import moment from 'moment-timezone'
import { makeStyles } from '@material-ui/core/styles'
import { Paper, Table, TableBody, TableCell, TableHead, TableRow } from '@material-ui/core'

import { NewFetchError } from './Fetch'
import { AuthContext } from './AuthContext'
import { useStickySearch } from './AdminLink'

const useStyles = makeStyles({
  tableWrapper: {
    maxWidth: '100%',
    overflowX: 'auto',
  },
})

const LastTime = ({ time }) => (time ? moment(time).fromNow() : null)

export default () => {
  const classes = useStyles()
  const history = useHistory()
  const stickySearch = useStickySearch()
  const { getIdToken } = useContext(AuthContext)
  const [feeds, setFeeds] = useState([])

  useEffect(() => {
    let cancel = false
    ;(async () => {
      const idToken = await getIdToken()
      const resp = await fetch(`/api/feeds/summary`, {
        headers: {
          authorization: `Bearer ${idToken}`,
        },
      })
      if (!resp.ok) {
        throw await NewFetchError(resp)
      }
      const data = await resp.json()
      if (cancel) {
        return
      }
      setFeeds(data.summary)
    })()
    return () => {
      cancel = true
    }
  }, [getIdToken])

  const handleClick = (type) => (e) => {
    history.push(stickySearch(`/feeds/${encodeURIComponent(type)}`))
  }

  return (
    <Paper>
      <div className={classes.tableWrapper}>
        <Table aria-label="feeds table">
          <TableHead>
            <TableRow>
              <TableCell>Type</TableCell>
              <TableCell>#</TableCell>
              <TableCell>Run</TableCell>
              <TableCell>Success</TableCell>
              <TableCell>Failure</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {feeds.map((t) => (
              <TableRow key={t.type} hover onClick={handleClick(t.type)}>
                <TableCell>{t.type}</TableCell>
                <TableCell>{t.feedCount}</TableCell>
                <TableCell>
                  <LastTime time={t.lastRun} />
                </TableCell>
                <TableCell>
                  <LastTime time={t.lastSuccess} />
                </TableCell>
                <TableCell>
                  <LastTime time={t.lastFailure} />
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </div>
    </Paper>
  )
}
