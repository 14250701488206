import React from 'react'
import { Helmet } from 'react-helmet-async'
import { Typography } from '@material-ui/core'

import { TrackPageView } from '../analytics'
import AdminFeedRuns from '../AdminFeedRuns'

export default ({ type }) => {
  return (
    <div>
      <Helmet>
        <title>Feed Runs {type} - Vyb</title>
      </Helmet>
      <TrackPageView title={`Feed Runs ${type}`} />
      <Typography component="h1" variant="h2">
        Feed Runs {type}
      </Typography>
      <AdminFeedRuns type={type} />
    </div>
  )
}
