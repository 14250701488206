import React, { useContext, useState, useEffect } from 'react'
import { useHistory } from 'react-router'
import { makeStyles } from '@material-ui/core/styles'
import { Button, Paper, Table, TableBody, TableCell, TableHead, TableRow } from '@material-ui/core'
import 'react-quill/dist/quill.snow.css'

import { AuthContext } from './AuthContext'
import { NewFetchError } from './Fetch'

const useStyles = makeStyles({
  root: {
    padding: '1em',
    marginBottom: '1em',
  },
  venueMeta: {
    display: 'inline-block',
    verticalAlign: 'middle',
  },
  name: {
    fontWeight: 'bold',
  },
})

export default () => {
  const classes = useStyles()
  const { getIdToken, roles } = useContext(AuthContext)
  const [curatedVenues, setCuratedVenues] = useState(null)
  const history = useHistory()

  useEffect(() => {
    let cancel = false
    ;(async () => {
      const idToken = await getIdToken()
      const resp = await fetch(`/api/curatedvenues`, {
        headers: {
          authorization: `Bearer ${idToken}`,
        },
      })
      if (!resp.ok) {
        throw await NewFetchError(resp)
      }
      const data = await resp.json()
      if (cancel) {
        return
      }
      setCuratedVenues(data)
    })()
    return () => {
      cancel = true
    }
  }, [getIdToken])

  return (
    <Paper className={classes.root}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Curated Venue</TableCell>
            <TableCell align="right">
              {roles.find((r) => r === 'superadmin' || /^siteadmin:/.test(r)) && (
                <Button variant="contained" onClick={() => history.push('/curatedvenue/create')}>
                  Add
                </Button>
              )}
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {curatedVenues
            ? curatedVenues.length === 0 && (
                <TableRow>
                  <TableCell>There are no curated venues.</TableCell>
                  <TableCell></TableCell>
                </TableRow>
              )
            : null}
          {curatedVenues
            ? curatedVenues.map((v) => (
                <TableRow key={v.uid}>
                  <TableCell>
                    <div className={classes.venueMeta}>
                      <div className={classes.name}>{v.name}</div>
                      <div>{v.address}</div>
                    </div>
                  </TableCell>
                  <TableCell align="right">
                    {roles.find((r) => r === 'superadmin' || /^siteadmin:/.test(r)) && (
                      <Button size="small" onClick={() => history.push(`/curatedvenue/edit/${v.uid}`)}>
                        Edit
                      </Button>
                    )}
                  </TableCell>
                </TableRow>
              ))
            : null}
        </TableBody>
      </Table>
    </Paper>
  )
}
