import { useEffect, useCallback } from 'react'

// https://stackoverflow.com/questions/32553158/detect-click-outside-react-component/42234988#42234988
export default (ref, callback) => {
  const handleClickOutside = useCallback(
    (event) => {
      if (ref.current && !ref.current.contains(event.target)) {
        event.preventDefault()
        event.stopPropagation()
        callback && callback()
      }
    },
    [ref, callback]
  )
  useEffect(() => {
    if (!callback) {
      return
    }
    document.addEventListener('click', handleClickOutside, true)
    return () => {
      document.removeEventListener('click', handleClickOutside)
    }
  }, [callback, handleClickOutside])
}

// To use
//   const wrapperRef = useRef(null)
//   useOutsideAlerter(wrapperRef)
//   return <div ref={wrapperRef}>{props.children}</div>
