import React, { useContext } from 'react'

import styles from './SiteNav.module.css'
import { NavLink } from './Link'
import AccountDropdown from './AccountDropdown'
import { SiteContext } from './SiteContext'

export default () => {
  const {
    site: { siteNavCategories },
  } = useContext(SiteContext)
  return (
    <div className={styles.root}>
      <div className={styles.links}>
        {siteNavCategories && siteNavCategories.length > 0 ? (
          siteNavCategories.map((c, idx) => (
            <NavLink key={idx} to={`/category/${c.id}`}>
              {c.name}
            </NavLink>
          ))
        ) : (
          <>
            <NavLink to="/category/comedy">Comedy</NavLink>
            <NavLink to="/category/concerts">Concerts</NavLink>
            <NavLink to="/category/food_and_drink">Food &amp; Drink</NavLink>
            <NavLink to="/category/sports">Sports</NavLink>
            <NavLink to="/category/theater">Theater</NavLink>
          </>
        )}
      </div>
      <div className={styles.actionContainer}>
        <NavLink to={`/create/event`}>Add Your Event</NavLink>
        <div className={styles.actions}>
          <AccountDropdown />
        </div>
      </div>
    </div>
  )
}
