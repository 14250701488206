import React, { useContext } from 'react'
import { useHistory } from 'react-router-dom'
import { Helmet } from 'react-helmet-async'
import { Typography } from '@material-ui/core'

import API from '../API'
import { createUploadImageHandler } from '../api_utilities'
import { NewFetchError } from '../Fetch'
import { TrackPageView } from '../analytics'
import { AuthContext } from '../AuthContext'
import { AdminSiteContext } from '../AdminSiteContext'
import AdminEditSite from '../AdminEditSite'
import { useStickySearch } from '../AdminLink'

const submitSite = (getIdToken, history, stickySearch, refresh) => async (site) => {
  const idToken = await getIdToken()
  const resp = await fetch('/api/site/update', {
    method: 'post',
    body: JSON.stringify(site),
    headers: {
      'Content-Type': 'application/json',
      authorization: `Bearer ${idToken}`,
    },
  })
  if (!resp.ok) {
    throw await NewFetchError(resp)
  }
  await refresh()
  history.replace(stickySearch('/site/dashboard'))
}

export default () => {
  const { getIdToken } = useContext(AuthContext)
  const history = useHistory()
  const stickySearch = useStickySearch()
  const { siteId, refresh } = useContext(AdminSiteContext)
  return (
    <div>
      <Helmet>
        <title>Edit Site - Vyb</title>
      </Helmet>
      <TrackPageView title="Edit Site" />
      <Typography component="h1" variant="h2">
        Edit Site
      </Typography>
      <API input={`/api/site?id=${encodeURIComponent(siteId)}`}>
        {({ data: site }) =>
          site && (
            <API input={`/api/sitesearchopts?id=${encodeURIComponent(siteId)}`}>
              {({ data: sitesearchopts }) =>
                sitesearchopts && (
                  <AdminEditSite
                    site={site}
                    categories={sitesearchopts.categories.children}
                    onSubmit={submitSite(getIdToken, history, stickySearch, refresh)}
                    uploadImage={createUploadImageHandler(getIdToken)}
                  />
                )
              }
            </API>
          )
        }
      </API>
    </div>
  )
}
