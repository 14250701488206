import React, { useContext } from 'react'
import { Helmet } from 'react-helmet-async'
import { Typography } from '@material-ui/core'

import { TrackPageView } from '../analytics'
import AdminSiteAdmins from '../AdminSiteAdmins'
import { AdminSiteContext } from '../AdminSiteContext'

export default () => {
  const { siteName } = useContext(AdminSiteContext)
  return (
    <div>
      <Helmet>
        <title>Users - {siteName}</title>
      </Helmet>
      <TrackPageView title="Users" />
      <Typography component="h1" variant="h2">
        Users ~ {siteName}
      </Typography>
      <AdminSiteAdmins />
    </div>
  )
}
