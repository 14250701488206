import React, { useContext } from 'react'
import { Helmet } from 'react-helmet-async'
import { Route, Switch, Redirect } from 'react-router'

import { CssBaseline } from '@material-ui/core'
import { StylesProvider, createMuiTheme, MuiThemeProvider } from '@material-ui/core/styles'
import { brown, amber, red } from '@material-ui/core/colors'

import { AdminSiteProvider } from './AdminSiteContext'
import { AuthContext } from './AuthContext'
import AdminAppLayout from './AdminAppLayout'
import AdminDashboardPage from './pages/AdminDashboardPage'
import AdminSiteEditPage from './pages/AdminSiteEditPage'
import AdminWidgetsPage from './pages/AdminWidgetsPage'
import AdminUsersPage from './pages/AdminUsersPage'
import AdminNoAccessPage from './pages/AdminNoAccessPage'
import AdminFeedsPage from './pages/AdminFeedsPage'
import AdminFeaturedVenuesPage from './pages/AdminFeaturedVenuesPage'
import AdminVenuesPage from './pages/AdminVenuesPage'
import AdminScrapesPage from './pages/AdminScrapesPage'
import AdminScrapeUpdatePage from './pages/AdminScrapeUpdatePage'
import AdminTicketmasterVenuesPage from './pages/AdminTicketmasterVenuesPage'
import AdminFeedRunsPage from './pages/AdminFeedRunsPage'
import AdminCuratedVenuesPage from './pages/AdminCuratedVenuesPage'
import AdminAddEditCuratedVenuePage from './pages/AdminAddEditCuratedVenuePage'
import AdminAnnouncementsPage from './pages/AdminAnnouncementsPage'
import AdminUpsellRatesPage from './pages/AdminUpsellRatesPage'
import AdminApiPage from './pages/AdminApiPage'
import AdminSiteUGCPage from './pages/AdminSiteUGCPage'
import AdminUpsellsPage from './pages/AdminUpsellsPage'
import AdminPlatformUGCPage from './pages/AdminPlatformUGCPage'

const theme = createMuiTheme({
  palette: {
    primary: brown,
    secondary: amber,
    error: {
      main: red[600],
    },
  },
})

export default () => {
  const { roles } = useContext(AuthContext)
  return (
    <>
      <Helmet>
        <link rel="stylesheet" href="https://fonts.googleapis.com/css?family=Roboto:300,400,500,700&amp;display=swap" />
        <link rel="stylesheet" href="https://fonts.googleapis.com/icon?family=Material+Icons" />
      </Helmet>
      <StylesProvider injectFirst>
        <CssBaseline />
        <MuiThemeProvider theme={theme}>
          <Switch>
            <AdminSiteProvider>
              <Switch>
                <Route exact path="/">
                  <AdminAppLayout>
                    <Redirect to="/site/dashboard" />
                  </AdminAppLayout>
                </Route>
                <Route exact path="/site/dashboard">
                  <AdminAppLayout>
                    <AdminDashboardPage />
                  </AdminAppLayout>
                </Route>
                <Route exact path="/site/featuredvenues">
                  <AdminAppLayout>
                    <AdminFeaturedVenuesPage />
                  </AdminAppLayout>
                </Route>
                {roles.includes('superadmin') && (
                  <Route exact path="/site/edit">
                    <AdminAppLayout>
                      <AdminSiteEditPage />
                    </AdminAppLayout>
                  </Route>
                )}
                <Route exact path="/site/apikeys">
                  <AdminAppLayout>
                    <AdminApiPage />
                  </AdminAppLayout>
                </Route>
                <Route exact path="/site/widgets">
                  <AdminAppLayout>
                    <AdminWidgetsPage />
                  </AdminAppLayout>
                </Route>
                <Route exact path="/site/users">
                  <AdminAppLayout>
                    <AdminUsersPage />
                  </AdminAppLayout>
                </Route>
                <Route exact path="/site/announcements">
                  <AdminAppLayout>
                    <AdminAnnouncementsPage />
                  </AdminAppLayout>
                </Route>
                <Route exact path="/site/ugc">
                  <AdminAppLayout>
                    <AdminSiteUGCPage />
                  </AdminAppLayout>
                </Route>
                <Route exact path="/site/upsellrates">
                  <AdminAppLayout>
                    <AdminUpsellRatesPage />
                  </AdminAppLayout>
                </Route>
                <Route exact path="/site/upsells">
                  <AdminAppLayout>
                    <AdminUpsellsPage />
                  </AdminAppLayout>
                </Route>
                {roles.includes('superadmin') && (
                  <Route exact path="/ugc">
                    <AdminAppLayout>
                      <AdminPlatformUGCPage />
                    </AdminAppLayout>
                  </Route>
                )}
                {roles.includes('superadmin') && (
                  <Route exact path="/feeds">
                    <AdminAppLayout>
                      <AdminFeedsPage />
                    </AdminAppLayout>
                  </Route>
                )}
                {roles.find((r) => r === 'superadmin' || /^siteadmin:/.test(r)) && (
                  <Route exact path="/curatedvenues">
                    <AdminAppLayout>
                      <AdminCuratedVenuesPage />
                    </AdminAppLayout>
                  </Route>
                )}
                {roles.find((r) => r === 'superadmin' || /^siteadmin:/.test(r)) && (
                  <Route exact path="/curatedvenue/create">
                    <AdminAppLayout>
                      <AdminAddEditCuratedVenuePage />
                    </AdminAppLayout>
                  </Route>
                )}
                {roles.find((r) => r === 'superadmin' || /^siteadmin:/.test(r)) && (
                  <Route exact path="/curatedvenue/edit/:uid">
                    {({ match }) => (
                      <AdminAppLayout>
                        <AdminAddEditCuratedVenuePage uid={match.params.uid} />
                      </AdminAppLayout>
                    )}
                  </Route>
                )}
                {roles.includes('superadmin') && (
                  <Route exact path="/venues">
                    <AdminAppLayout>
                      <AdminVenuesPage />
                    </AdminAppLayout>
                  </Route>
                )}
                {roles.includes('superadmin') && (
                  <Route exact path="/ticketmastervenues">
                    <AdminAppLayout>
                      <AdminTicketmasterVenuesPage />
                    </AdminAppLayout>
                  </Route>
                )}
                {roles.includes('superadmin') && (
                  <Route exact path="/scrapes">
                    <AdminAppLayout>
                      <AdminScrapesPage />
                    </AdminAppLayout>
                  </Route>
                )}
                )}
                {roles.includes('superadmin') && (
                  <Route exact path="/scrape/edit/:id">
                    {({ match }) => (
                      <AdminAppLayout>
                        <AdminScrapeUpdatePage id={match.params.id} />
                      </AdminAppLayout>
                    )}
                  </Route>
                )}
                {roles.includes('superadmin') && (
                  <Route exact path="/scrape/create">
                    <AdminAppLayout>
                      <AdminScrapeUpdatePage />
                    </AdminAppLayout>
                  </Route>
                )}
                {roles.includes('superadmin') && (
                  <Route exact path="/feeds/:type">
                    {({ match }) => (
                      <AdminAppLayout>
                        <AdminFeedRunsPage type={match.params.type} />
                      </AdminAppLayout>
                    )}
                  </Route>
                )}
                <Route>
                  <AdminNoAccessPage />
                </Route>
              </Switch>
            </AdminSiteProvider>
          </Switch>
        </MuiThemeProvider>
      </StylesProvider>
    </>
  )
}
