import React, { useContext, useEffect, useState, useImperativeHandle, forwardRef, useRef } from 'react'
import sha256 from 'js-sha256'

import styles from './ImageUpload.module.css'
import { ImageServiceContext } from '../ImageServiceContext'

const readAsArrayBuffer = (blob) =>
  new Promise((resolve, reject) => {
    const r = new FileReader()
    r.onload = () => {
      resolve(r.result)
    }
    r.onerror = reject
    r.readAsArrayBuffer(blob)
  })

const readAsDataURL = (blob) =>
  new Promise((resolve, reject) => {
    const r = new FileReader()
    r.onload = () => {
      resolve(r.result)
    }
    r.onerror = reject
    r.readAsDataURL(blob)
  })

const calculateSHA256 = async (f) => {
  const chunkSize = 64 * 1024
  var hash = sha256.create()
  for (let offset = 0; offset < f.size; offset += chunkSize) {
    const chunk = f.slice(offset, Math.min(offset + chunkSize, f.size))
    const ab = await readAsArrayBuffer(chunk)
    hash.update(ab)
  }
  return hash.hex()
}

export default forwardRef(
  (
    {
      value,
      onUpload,
      onChange,
      previewImageSize = '510,',
      dropzoneContainerStyle = {},
      buttonComponent: ButtonComponent,
      error: errorMsg,
      buttonProps = {},
    },
    ref
  ) => {
    const { imageURL } = useContext(ImageServiceContext)
    const [dataSource, setDataSource] = useState()
    const [uploading, setUploading] = useState(false)
    const [error, setError] = useState(null)

    useEffect(() => setError(errorMsg ? new Error(errorMsg) : null), [errorMsg])

    const inputRef = useRef(null)
    useImperativeHandle(ref, () => ({
      setFile: onFile,
    }))

    const clickHandler = (e) => {
      e.preventDefault()
      inputRef.current && inputRef.current.click()
    }

    const clearHandler = (e) => {
      e.preventDefault()
      onChange && onChange('')
    }

    const changeHandler = (e) => {
      if (e.target.files.length === 0) {
        return
      }
      const f = e.target.files[0]
      e.target.value = null
      return onFile(f)
    }

    const onFile = async (f) => {
      if (f.size > 20 * 1024 * 1024) {
        setError(new Error('Maximum size allowed is 20mb'))
        return
      }
      setError()
      setUploading(true)
      setDataSource(await readAsDataURL(f)) // TODO eats up to 20MB of RAM
      const checksum = await calculateSHA256(f)
      try {
        onUpload && (await onUpload(f, checksum))
      } catch (e) {
        setError(e)
        return
      } finally {
        setUploading(false)
        setDataSource()
      }
      onChange && onChange(checksum)
    }

    const previewURL = dataSource
      ? dataSource
      : value
      ? imageURL({ size: previewImageSize, checksum: value })
      : undefined

    return (
      <div className={styles.root}>
        <label className={uploading ? styles.uploading : ''}>
          <div className={styles.dropzoneContainer} style={dropzoneContainerStyle}>
            <div className={styles.dropzone} style={previewURL ? { backgroundImage: `url(${previewURL})` } : {}}>
              <input
                ref={inputRef}
                type="file"
                onChange={changeHandler}
                disabled={uploading}
                accept="image/png,image/jpeg,image/gif"
              />
              <div className={styles.imageContainer}>{previewURL && <img src={previewURL} alt="preview" />}</div>
            </div>
          </div>
          {!ButtonComponent && (
            <div className={styles.buttonContainer}>
              <div className={styles.selectButton}>{uploading ? 'Uploading' : 'Select Image'}</div>
              <div className={`${styles.clearButton} ${value ? '' : styles.noClear}`} onClick={clearHandler}>
                Clear Image
              </div>
            </div>
          )}
        </label>
        {ButtonComponent && (
          <div className={styles.buttonContainer}>
            <ButtonComponent {...buttonProps} onClick={clickHandler}>
              {uploading ? 'Uploading' : 'Select Image'}
            </ButtonComponent>
            <ButtonComponent {...buttonProps} disabled={!value} onClick={clearHandler}>
              Clear Image
            </ButtonComponent>
          </div>
        )}
        {error && <div className={styles.error}>{error.message}</div>}
      </div>
    )
  }
)
