import React, { useState, useEffect } from 'react'

export const StripeContext = React.createContext()

export const AsyncStripeProvider = ({ publishableKey, children }) => {
  const [stripe, setStripe] = useState(null)
  useEffect(() => {
    if (!publishableKey) {
      setStripe(null)
    } else if (window.Stripe) {
      setStripe(window.Stripe(publishableKey))
    } else {
      document.querySelector('#stripe-js').addEventListener('load', () => {
        setStripe(window.Stripe(publishableKey))
      })
    }
  }, [publishableKey])
  return <StripeContext.Provider value={stripe}>{children}</StripeContext.Provider>
}
