import React, { useContext, useEffect } from 'react'
import { Route } from 'react-router-dom'

import { AuthContext } from './AuthContext'
import { LoginUrlContext } from './contexts'

export default (props) => {
  const { loginUrl } = useContext(LoginUrlContext)
  const { isLoggedIn } = useContext(AuthContext)
  useEffect(() => {
    ;(async () => {
      if (!isLoggedIn) {
        window.location = `${loginUrl}/login?redirect=${encodeURIComponent(window.location.href)}`
      }
    })()
  }, [isLoggedIn, loginUrl])
  return isLoggedIn ? <Route {...props} /> : null
}
