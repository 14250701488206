import React, { useCallback } from 'react'
import { Link as RouterLink, NavLink as RouterNavLink, useLocation } from 'react-router-dom'

import { stickySearch } from './url'

const stickyParams = ['site']

export const useStickySearch = () => {
  const location = useLocation()
  return (to) => stickySearch(stickyParams, to, location)
}

// The forwardRef pattern comes from:
//   https://material-ui.com/guides/composition/#link
//   The use of React.forwardRef will no longer be required for react-router-dom v6.
//   See https://github.com/ReactTraining/react-router/issues/6056

export const Link = React.forwardRef(({ to, ...props }, ref) => {
  const stickyTo = useCallback(
    (currentLocation) => {
      return stickySearch(stickyParams, to, currentLocation)
    },
    [to]
  )
  return <RouterLink ref={ref} to={stickyTo} {...props} />
})
export const NavLink = React.forwardRef(({ to, ...props }, ref) => {
  const stickyTo = useCallback(
    (currentLocation) => {
      return stickySearch(stickyParams, to, currentLocation)
    },
    [to]
  )
  return <RouterNavLink ref={ref} to={stickyTo} {...props} />
})
