import React, { useContext, useEffect, useRef, useState } from 'react'
import { useLocation, useHistory } from 'react-router-dom'

import { Button, Input, InputLabel, Paper, Table, TableBody, TableCell, TableHead, TableRow } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'

import { AuthContext } from './AuthContext'
import { AdminSiteContext } from './AdminSiteContext'
import { NewFetchError } from './Fetch'
import { Link } from './AdminLink'
import qs from './qs'

const summarizeUrl = (url) => new URL(url).hostname.split('.').reverse().slice(0, 2).reverse().join('.')

const useStyles = makeStyles({
  meta: {
    display: 'inline-block',
    verticalAlign: 'middle',
  },
  button: {
    textDecoration: 'none',
  },
})

const getScrapes = async (idToken, query) => {
  const resp = await fetch(`/api/scrapes${qs.stringify(query)}`, {
    headers: {
      authorization: `Bearer ${idToken}`,
    },
  })
  if (!resp.ok) {
    throw await NewFetchError(resp)
  }
  const data = await resp.json()
  return data.scrapes
}

export default () => {
  const history = useHistory()
  const location = useLocation()
  const params = qs.parse(location.search)
  const classes = useStyles()
  const { getIdToken, roles } = useContext(AuthContext)
  const { siteHost } = useContext(AdminSiteContext)
  const [scrapes, setScrapes] = useState(null)
  const [query, setQuery] = useState({
    name: params.name,
    venue: params.venue,
    feedId: params.feedId,
  })

  const refresh = async () => {
    const idToken = await getIdToken()
    const s = await getScrapes(idToken, query)
    setScrapes(s)
  }
  useEffect(() => {
    history.replace(`${location.pathname}${qs.stringify({ ...query, site: params.site })}${location.hash}`)
    let cancel = false
    ;(async () => {
      const idToken = await getIdToken()
      const s = await getScrapes(idToken, query)
      if (!cancel) {
        setScrapes(s)
      }
    })()
    return () => {
      cancel = true
    }
  }, [getIdToken, history, query, location.pathname, location.hash, params.site])
  const siteSearch = window.location.origin.replace(/\/\/admin\./, `//${siteHost}.`) + '/search'
  return (
    <Paper>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>
              <DownloadScrapes />
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>
              <UploadScrapes onSuccess={refresh} />
            </TableCell>
          </TableRow>
        </TableHead>
      </Table>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>
              Scrape{' '}
              <InputLabel>
                Filter:
                <Input value={query.name} onChange={(e) => setQuery({ ...query, name: e.target.value })} />
              </InputLabel>
              <Button onClick={(e) => setQuery({ name: '' })}>Clear</Button>
            </TableCell>
            <TableCell align="right">
              {roles.indexOf('superadmin') !== -1 && (
                <Link className={classes.button} to={`/scrape/create`}>
                  <Button variant="contained">Add</Button>
                </Link>
              )}
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {scrapes
            ? scrapes.length === 0 && (
                <TableRow>
                  <TableCell>There are no scrapes.</TableCell>
                  <TableCell></TableCell>
                </TableRow>
              )
            : null}
          {scrapes
            ? scrapes.map((s) => (
                <TableRow key={s.id}>
                  <TableCell>
                    <div className={classes.meta}>
                      <div className={classes.name}>
                        {s.venueLabel} {s.venueName}
                        {s.plugin !== 'siphon' && s.url && (
                          <>
                            {' '}
                            (
                            <a href={s.url} rel="noopener noreferrer" target="_blank">
                              {summarizeUrl(s.url)}
                            </a>
                            )
                          </>
                        )}
                      </div>
                      <a
                        href={siteSearch + `?noSiteBounds=true&feed=${encodeURIComponent(s.id)}`}
                        rel="noopener noreferrer"
                        target="_blank"
                      >
                        see events
                      </a>
                    </div>
                  </TableCell>
                  <TableCell align="right">
                    {roles.indexOf('superadmin') !== -1 && (
                      <>
                        <Button
                          onClick={async () => {
                            if (window.confirm(`Delete ${s.venueName} Scrape and all of its events?`)) {
                              const idToken = await getIdToken()
                              const resp = await fetch(`/api/scrape/delete?id=${encodeURIComponent(s.id)}`, {
                                method: 'POST',
                                headers: {
                                  authorization: `Bearer ${idToken}`,
                                },
                              })
                              if (!resp.ok) {
                                window.alert(`Unable to delete ${s.venueName} scrape.`)
                              } else {
                                refresh()
                              }
                            }
                          }}
                        >
                          Delete scrape
                        </Button>
                        <Link className={classes.button} to={`/scrape/edit/${encodeURIComponent(s.id)}`}>
                          <Button size="small">Edit</Button>
                        </Link>
                      </>
                    )}
                  </TableCell>
                </TableRow>
              ))
            : null}
        </TableBody>
      </Table>
    </Paper>
  )
}

const DownloadScrapes = () => {
  const { getIdToken } = useContext(AuthContext)
  const [busy, setBusy] = useState(false)
  return (
    <div>
      <h3>Export scrapes</h3>
      <Button
        disabled={busy}
        onClick={async () => {
          try {
            setBusy(true)
            const idToken = await getIdToken()
            const resp = await fetch(`/api/feed/export`, {
              headers: {
                authorization: `Bearer ${idToken}`,
              },
            })
            if (!resp.ok) {
              throw await NewFetchError(resp)
            }
            const blob = await resp.blob()
            const url = window.URL.createObjectURL(blob)
            const a = document.createElement('a')
            a.href = url
            a.download = 'scrapes.csv'
            document.body.appendChild(a)
            a.click()
            a.remove()
          } finally {
            setBusy(false)
          }
        }}
      >
        Export...
      </Button>
    </div>
  )
}

const UploadScrapes = ({ onSuccess }) => {
  const { getIdToken } = useContext(AuthContext)
  const [busy, setBusy] = useState(false)
  const [status, setStatus] = useState('')
  const input = useRef()
  return (
    <div>
      <h3>Import Scrapes</h3>
      <input type="file" ref={input} onChange={() => setStatus('')} />
      and then
      <Button
        disabled={busy}
        onClick={async () => {
          try {
            setBusy(true)
            // const reader = new FileReader();
            const idToken = await getIdToken()
            const resp = await fetch(`/api/feed/import`, {
              method: 'POST',
              headers: {
                authorization: `Bearer ${idToken}`,
              },
              body: input.current.files[0],
            })
            if (!resp.ok) {
              setStatus('Upload failed')
              const msg = await resp.text()
              setStatus(`Upload failed ${msg}`)
            } else {
              setStatus('Upload successful')
              onSuccess()
            }
          } finally {
            setBusy(false)
          }
        }}
      >
        Upload!
      </Button>
      {status}
    </div>
  )
}
