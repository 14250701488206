import React, { useContext, useState } from 'react'
import { CardElement, Elements, injectStripe, StripeProvider } from 'react-stripe-elements'

import styles from './CaptureCreditCard.module.css'
import { StripeContext } from '../stripe.js'
import Button from './Button'

const CheckoutForm = React.forwardRef(({ stripe, onSubmit, disabled = false, initialName = '' }, ref) => {
  const [name, setName] = useState(initialName)
  const [inflight, setInflight] = useState(false)
  const [error, setError] = useState(null)
  const [exception, setException] = useState(null)
  const [nameValid, setNameValid] = useState(true)

  if (exception) {
    throw exception
  }

  const createToken = async () => await stripe.createToken({ name })

  const handleSubmit = async (e) => {
    if (disabled || inflight) {
      return
    }
    if (name.trim().length === 0) {
      setNameValid(false)
      return
    }
    setNameValid(true)
    setInflight(true)
    try {
      let error = null
      if (onSubmit) {
        const resp = await onSubmit(createToken)
        error = resp && resp.error ? resp.error : null
        if (error) {
          setInflight(false)
        }
      }
      setError(error)
    } catch (e) {
      setException(e)
    }
  }

  return (
    <div className={styles.checkoutForm}>
      <input type="text" placeholder="Name" value={name} onChange={(e) => setName(e.target.value)} />
      {!nameValid && <div className={styles.error}>Name is required</div>}
      <CardElement />
      {error && <div className={styles.error}>{error.message}</div>}
      <Button
        className={`${styles.submit} ${disabled || inflight ? styles.disabled : ''}`}
        ref={ref}
        onClick={handleSubmit}
      >
        Submit Payment
      </Button>
    </div>
  )
})

const CheckoutFormWithStripe = injectStripe(({ forwardedRef, ...props }) => (
  <CheckoutForm ref={forwardedRef} {...props} />
))
const CheckoutFormWithStripeAndRef = React.forwardRef((props, ref) => (
  <CheckoutFormWithStripe forwardedRef={ref} {...props} />
))

export default React.forwardRef((props, ref) => {
  const stripe = useContext(StripeContext)
  return (
    <StripeProvider stripe={stripe}>
      <div className={styles.root}>
        <Elements>
          <CheckoutFormWithStripeAndRef ref={ref} {...props} />
        </Elements>
      </div>
    </StripeProvider>
  )
})
