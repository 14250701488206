import React, { useContext } from 'react'
import moment from 'moment-timezone'

import styles from './Receipt.module.css'
import { SiteContext } from './SiteContext'
import Price from './Price'
import Image from './Image'

export const receiptNumber = (id) => id.toLocaleString('en-US', { minimumIntegerDigits: 4 })

export default ({ id, created, featuredDailyEvent, totalCents }) => {
  const {
    site: { timezone },
  } = useContext(SiteContext)
  return (
    <table className={styles.root}>
      <thead>
        <tr>
          <td colSpan="2">
            <div>Receipt #{receiptNumber(id)}</div>
            <div>{moment(created).tz(timezone).format('ddd, MMM Do, h:mm: a')}</div>
          </td>
        </tr>
      </thead>
      <tbody>
        {featuredDailyEvent &&
          featuredDailyEvent.length > 0 &&
          featuredDailyEvent.map(({ summary, event, items }) => (
            <React.Fragment key={event?.id || summary}>
              <tr>
                <td colSpan="2">
                  {event && <Image className={styles.image} checksum={event.imageChecksum} size="100," />}
                  <div className={styles.meta}>
                    <div className={styles.title}>{event?.title || summary}</div>
                    {event && (
                      <>
                        <div>{event.venueName}</div>
                        <div>
                          {event.noStart
                            ? moment(event.start).tz(timezone).format('ddd, MMM Do')
                            : moment(event.start).tz(timezone).format('ddd, MMM Do, h:mm a')}
                        </div>
                      </>
                    )}
                  </div>
                </td>
              </tr>
              {items &&
                items.map(({ day, priceCents }) => (
                  <tr key={day}>
                    <td>{moment(day).format('ddd, MMM Do')}</td>
                    <td>
                      <Price className={styles.price} cents={priceCents} />
                    </td>
                  </tr>
                ))}
            </React.Fragment>
          ))}
      </tbody>
      <tfoot>
        <td></td>
        <td>
          Total: <Price className={styles.totalPrice} cents={totalCents} />
        </td>
      </tfoot>
    </table>
  )
}
