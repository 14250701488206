import React, { useContext, useState, useEffect } from 'react'
import { useLocation, useHistory } from 'react-router-dom'
import { makeStyles } from '@material-ui/core/styles'
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Input,
  InputLabel,
  MenuItem,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
} from '@material-ui/core'
import { Map, Marker } from 'react-leaflet'
import L from 'leaflet'

import CustomTileLayer from './CustomTileLayer'
import { AuthContext } from './AuthContext'
import { NewFetchError } from './Fetch'
import timezones from './timezones'
import { Link } from './AdminLink'
import qs from './qs'
import { AdminSiteContext } from './AdminSiteContext'

const useDialogStyles = makeStyles({
  map: {
    width: '100%',
    height: '15em',
  },
})

const icon = L.icon({ iconUrl: '/marker-icon.png' })

const CreateEditVenueDialog = ({ open, closeDialog, addVenue, venueId }) => {
  const classes = useDialogStyles()
  const { getIdToken } = useContext(AuthContext)
  const [label, setLabel] = useState('')
  const [name, setName] = useState('')
  const [address, setAddress] = useState('')
  const [timezone, setTimezone] = useState('')
  const [latitude, setLatitude] = useState()
  const [longitude, setLongitude] = useState()
  const [ready, setReady] = useState(false)
  const [submitting, setSubmitting] = useState()
  const [error, setError] = useState({})

  useEffect(() => {
    if (!venueId) {
      return
    }
    setReady(false)
    ;(async () => {
      const idToken = await getIdToken()
      const resp = await fetch(`/api/scrapevenue?id=${encodeURIComponent(venueId)}`, {
        headers: {
          authorization: `Bearer ${idToken}`,
        },
      })
      if (!resp.ok) {
        throw await NewFetchError(resp)
      }
      const data = await resp.json()
      setLabel(data.label)
      setName(data.name)
      setAddress(data.address)
      setTimezone(data.timezone)
      setLatitude(data.latitude)
      setLongitude(data.longitude)
      setReady(true)
    })()
  }, [getIdToken, venueId])

  if (venueId && !ready) {
    return null
  }

  const handleSubmit = () => {
    setSubmitting(true)
    ;(async () => {
      const error = await addVenue({
        id: venueId,
        label,
        name,
        address,
        timezone,
        latitude: Number(latitude),
        longitude: Number(longitude),
      })
      if (error) {
        setError(error)
        setSubmitting(false)
        return
      }
      closeDialog()
    })()
  }

  const position =
    Number.isFinite(Number.parseFloat(latitude)) && Number.isFinite(Number.parseFloat(longitude))
      ? [Number.parseFloat(latitude), Number.parseFloat(longitude)]
      : undefined

  return (
    <Dialog open={open} onClose={closeDialog} aria-labelledby="form-dialog-title">
      <DialogTitle id="form-dialog-title">{venueId ? 'Edit' : 'Add'} Venue</DialogTitle>
      <DialogContent>
        <Map className={classes.map} center={position} zoom={13}>
          <CustomTileLayer />
          {position && <Marker key={position} position={position} icon={icon} />}
        </Map>
        <TextField
          autoFocus={!venueId}
          name="label"
          value={label}
          onChange={(e) => {
            setLabel(e.target.value)
          }}
          error={error.label}
          helperText={error.label}
          margin="dense"
          label="Label"
          autoComplete="off"
          fullWidth
        />
        <TextField
          name="name"
          value={name}
          onChange={(e) => {
            setName(e.target.value)
          }}
          error={error.name}
          helperText={error.name}
          margin="dense"
          label="Name"
          autoComplete="off"
          fullWidth
        />
        <TextField
          name="address"
          value={address}
          onChange={(e) => {
            setAddress(e.target.value)
          }}
          margin="dense"
          label="Address"
          autoComplete="off"
          fullWidth
        />
        <TextField
          select
          name="timezone"
          value={timezone}
          onChange={(e) => {
            setTimezone(e.target.value)
          }}
          error={error.timezone}
          helperText={error.timezone}
          label="Timezone"
          variant="outlined"
          fullWidth
          margin="dense"
        >
          {timezones.map((v) => (
            <MenuItem key={v} value={v}>
              {v}
            </MenuItem>
          ))}
        </TextField>
        <TextField
          name="latitude"
          value={latitude}
          onChange={(e) => {
            setLatitude(e.target.value)
          }}
          error={error.latitude}
          helperText={error.latitude}
          margin="dense"
          label="Latitude"
          autoComplete="off"
          fullWidth
        />
        <TextField
          name="longitude"
          value={longitude}
          onChange={(e) => {
            setLongitude(e.target.value)
          }}
          error={error.longitude}
          helperText={error.longitude}
          margin="dense"
          label="Longitude"
          autoComplete="off"
          fullWidth
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={closeDialog} color="primary">
          Cancel
        </Button>
        <Button onClick={handleSubmit} color="primary" disabled={submitting}>
          {venueId ? 'Update' : 'Add'}
        </Button>
      </DialogActions>
    </Dialog>
  )
}

const useStyles = makeStyles({
  root: {
    padding: '1em',
    marginBottom: '1em',
  },
  venueMeta: {
    display: 'inline-block',
    verticalAlign: 'middle',
  },
  name: {
    fontWeight: 'bold',
  },
})

async function refresh(idToken, query) {
  const resp = await fetch(`/api/scrapevenues${qs.stringify(query)}`, {
    headers: {
      authorization: `Bearer ${idToken}`,
    },
  })
  if (!resp.ok) {
    throw await NewFetchError(resp)
  }
  return await resp.json()
}

export default () => {
  const history = useHistory()
  const location = useLocation()
  const params = qs.parse(location.search)
  const classes = useStyles()
  const { getIdToken, roles } = useContext(AuthContext)
  const { siteId } = useContext(AdminSiteContext)
  const [venues, setVenues] = useState(null)
  const [addingVenueKey, setAddingVenueKey] = useState(0)
  const [addingVenue, setAddingVenue] = useState(false)
  const [refreshKey, setRefreshKey] = useState(0)
  const [venueId, setVenueId] = useState()
  const [query, setQuery] = useState({
    q: params.q,
  })
  const siteSearch = window.location.origin.replace(/\/\/admin\./, `//${siteId}.`) + '/search'
  useEffect(() => {
    let cancel = false
    ;(async () => {
      history.replace(`${location.pathname}${qs.stringify({ ...query, site: params.site })}${location.hash}`)
      const idToken = await getIdToken()
      const data = await refresh(idToken, query)
      if (cancel) {
        return
      }
      setVenues(data.venues)
    })()
    return () => {
      cancel = true
    }
  }, [getIdToken, refreshKey, query, location.pathname, location.hash, history, params.site])

  const openAddVenueDialog = (id) => {
    setAddingVenueKey((k) => k + 1)
    setVenueId(id)
    setAddingVenue(true)
  }

  const closeAddVenueDialog = () => {
    setAddingVenue(false)
  }

  const updateVenue = async (data) => {
    const vErrs = {}
    if (!Number.isFinite(data.latitude)) {
      vErrs.latitude = 'latitude must not be blank'
    }
    if (!Number.isFinite(data.longitude)) {
      vErrs.longitude = 'longitude must not be blank'
    }
    if (Object.keys(vErrs).length > 0) {
      return vErrs
    }
    const idToken = await getIdToken()
    const resp = await fetch(`/api/scrapevenues/update`, {
      method: 'post',
      body: JSON.stringify(data),
      headers: {
        authorization: `Bearer ${idToken}`,
      },
    })
    if (!resp.ok) {
      if (resp.status === 400) {
        try {
          const data = await resp.json()
          if (data.type === 'validation_error') {
            return data.fields || { name: 'unknown error' }
          }
        } catch {} // Treat as generic error
      }
      throw await NewFetchError(resp)
    }
    setRefreshKey((k) => k + 1)
  }

  return (
    <Paper className={classes.root}>
      <CreateEditVenueDialog
        key={addingVenueKey}
        open={addingVenue}
        closeDialog={closeAddVenueDialog}
        addVenue={updateVenue}
        venueId={venueId}
      />
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>
              Venue{' '}
              <InputLabel>
                Filter:
                <Input value={query.q} onChange={(e) => setQuery({ ...query, q: e.target.value })} />
              </InputLabel>
              <Button onClick={(e) => setQuery({ q: '' })}>Clear</Button>
            </TableCell>
            <TableCell align="right">
              {roles.indexOf('superadmin') !== -1 && (
                <Button variant="contained" onClick={() => openAddVenueDialog()} disabled={addingVenue}>
                  Add
                </Button>
              )}
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {venues
            ? venues.length === 0 && (
                <TableRow>
                  <TableCell>There are no venues.</TableCell>
                  <TableCell></TableCell>
                </TableRow>
              )
            : null}
          {venues
            ? venues.map((v) => (
                <TableRow key={v.id}>
                  <TableCell>
                    <div className={classes.venueMeta}>
                      <div className={classes.name}>{v.label}</div>
                      <div className={classes.name}>{v.name}</div>
                      <div>{v.address}</div>
                      <div>
                        {v.inUse ? <Link to={`/scrapes?venue=${encodeURIComponent(v.id)}`}>See scrapes</Link> : null}
                      </div>
                      <div>
                        {v.upcomingEventsCount ? (
                          <a
                            href={siteSearch + `?noSiteBounds=true&venue=${encodeURIComponent(v.id)}`}
                            rel="noopener noreferrer"
                            target="_blank"
                          >
                            ({v.upcomingEventsCount} upcoming event{v.upcomingEventsCount !== 1 ? 's' : ''})
                          </a>
                        ) : null}
                      </div>
                    </div>
                  </TableCell>
                  <TableCell align="right">
                    {roles.indexOf('superadmin') !== -1 && (
                      <>
                        {!v.inUse && (
                          <Button
                            onClick={async () => {
                              if (window.confirm(`Delete ${v.name} and all of its events?`)) {
                                const idToken = await getIdToken()
                                const resp = await fetch(`/api/scrapevenue/delete?id=${encodeURIComponent(v.id)}`, {
                                  method: 'POST',
                                  headers: {
                                    authorization: `Bearer ${idToken}`,
                                  },
                                })
                                if (!resp.ok) {
                                  window.alert(`Unable to delete ${v.name}.`)
                                } else {
                                  setRefreshKey((k) => k + 1)
                                }
                              }
                            }}
                          >
                            Delete unreferenced venue...
                          </Button>
                        )}
                        <Button size="small" onClick={() => openAddVenueDialog(v.id)} disabled={addingVenue}>
                          Edit
                        </Button>
                      </>
                    )}
                  </TableCell>
                </TableRow>
              ))
            : null}
        </TableBody>
      </Table>
    </Paper>
  )
}
