import { MenuItem, TextField } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import React, { useContext, useEffect, useState } from 'react'
import { NewFetchError } from './Fetch'
import { AuthContext } from './AuthContext'
import { AdminSiteContext } from './AdminSiteContext'

const useStyles = makeStyles((theme) => ({
  root: {
    minWidth: '12em',
  },
}))

const AdminSitePicker = () => {
  const classes = useStyles()
  const { getIdToken } = useContext(AuthContext)
  const { siteId, setSiteId } = useContext(AdminSiteContext)
  const [sites, setSites] = useState([])
  const [idToken, setIdToken] = useState()
  useEffect(() => {
    ;(async () => {
      const tok = await getIdToken()
      setIdToken(tok)
      if (!tok) {
        return
      }
      const resp = await fetch('/api/siteslist', {
        headers: {
          authorization: `Bearer ${tok}`,
        },
      })
      if (!resp.ok) {
        throw await NewFetchError(resp)
      }
      const res = await resp.json()
      setSites(res.sites)
    })()
  }, [getIdToken, siteId])
  if (!idToken || !sites || sites.length === 0) {
    return null
  }
  const handleChange = (e) => {
    setSiteId(e.target.value)
  }

  return (
    <TextField className={classes.root} select value={siteId} onChange={handleChange} variant="outlined" margin="dense">
      {sites.map((s) => (
        <MenuItem key={s.id} value={s.id}>
          {s.name}
        </MenuItem>
      ))}
    </TextField>
  )
}

export default AdminSitePicker
