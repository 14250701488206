import React, { useContext } from 'react'
import API from '../API'
import { Helmet } from 'react-helmet-async'

import styles from './NeighborhoodsPage.module.css'
import { Link } from '../Link'
import { SiteContext } from '../SiteContext'
import { TrackPageView } from '../analytics'
import useAds from '../useAds'
import Ad from '../Ad'
import FeaturedDailyEvents from '../appcomponents/FeaturedDailyEvents'
import Breadcrumbs from '../Breadcrumbs'
import FeaturedVenues from '../appcomponents/FeaturedVenues'

const NeighborhoodsPage = ({ geo, breadcrumbs }) => {
  const { site } = useContext(SiteContext)
  useAds([{ id: 'div-gpt-ad-leaderboard-0', type: 'leaderboard' }])
  return (
    <div className={styles.root}>
      <Helmet>
        <title>Neighborhoods - {site.name}</title>
      </Helmet>
      <TrackPageView title="Neighborhoods" />
      <Breadcrumbs breadcrumbs={breadcrumbs} />
      <Ad id="div-gpt-ad-leaderboard-0" type="leaderboard" />
      <h2 className={styles.heading}>Places</h2>
      <div className={styles.places}>
        <div>
          {geo.places.map((n) => (
            <div key={n.id}>
              <div className={styles.place}>
                <Link to={`/place/${n.id}`}>{n.label}</Link>
              </div>
            </div>
          ))}
        </div>
      </div>
      <h2 className={styles.heading}>Neighborhoods</h2>
      <div className={styles.neighborhoods}>
        <div>
          {geo.neighborhoods.map((n) => (
            <div key={n.id}>
              <div className={styles.neighborhood}>
                <Link to={`/neighborhood/${n.id}`}>{n.label}</Link>
              </div>
            </div>
          ))}
        </div>
      </div>
      <h2 className={styles.heading}>Zip Codes</h2>
      <div className={styles.zips}>
        <div>
          {geo.zips.map((n) => (
            <div key={n.id}>
              <div className={styles.zip}>
                <Link to={`/zipcode/${n.id}`}>{n.label}</Link>
              </div>
            </div>
          ))}
        </div>
      </div>
      <FeaturedDailyEvents />
      <FeaturedVenues />
    </div>
  )
}

export default (props) => (
  <API input="/api/sitesearchopts">{({ data }) => data && <NeighborhoodsPage {...props} geo={data.geo} />}</API>
)
