import './polyfills'
import React from 'react'
import ReactDOM from 'react-dom'
import { BrowserRouter as Router } from 'react-router-dom'
import { HelmetProvider } from 'react-helmet-async'

import './index.css'
import ErrorBoundary from './ErrorBoundary'
import AppErrorPage from './pages/AppErrorPage'
import App from './App'
import ScrollToTop from './ScrollToTop'

let token
if (window.location.hash.indexOf('#token=') === 0) {
  token = window.location.hash.substring('#token='.length)
  window.history.replaceState(null, null, ' ')
}

ReactDOM.render(
  <React.StrictMode>
    <Router>
      <ScrollToTop>
        <HelmetProvider>
          <ErrorBoundary component={AppErrorPage}>
            <App token={token} />
          </ErrorBoundary>
        </HelmetProvider>
      </ScrollToTop>
    </Router>
  </React.StrictMode>,
  document.getElementById('root')
)
