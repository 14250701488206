import React, { useContext } from 'react'
import { Helmet } from 'react-helmet-async'
import { Typography } from '@material-ui/core'

import { TrackPageView } from '../analytics'
import { AdminSiteContext } from '../AdminSiteContext'
import AdminAnnouncements from '../AdminAnnouncements'

export default () => {
  const { siteName } = useContext(AdminSiteContext)
  return (
    <div>
      <Helmet>
        <title>Announcements - {siteName}</title>
      </Helmet>
      <TrackPageView title="Announcements" />
      <Typography component="h1" variant="h2">
        Announcements ~ {siteName}
      </Typography>
      <AdminAnnouncements />
    </div>
  )
}
