import React from 'react'

import styles from './InternalHeader.module.css'
import Logo from './Logo'

export default ({ name, logoChecksum, linkUrl, logoHeightHeader }) => {
  return (
    <header className={styles.root}>
      <Logo
        style={logoHeightHeader ? { height: logoHeightHeader } : {}}
        name={name}
        logoChecksum={logoChecksum}
        linkUrl={linkUrl}
        className={styles.logo}
      />
    </header>
  )
}
