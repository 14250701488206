import React from 'react'
import { Route, Switch } from 'react-router-dom'

import { Login } from './Login'
import { LoginWithGoogle } from './LoginWithGoogle'

export const LoginApp = ({ firebaseApp }) => {
  return (
    <Switch>
      <Route exact path="/login">
        <Login firebaseApp={firebaseApp} />
      </Route>
      <Route exact path="/login/google">
        <LoginWithGoogle firebaseApp={firebaseApp} />
      </Route>
    </Switch>
  )
}
