import React, { useState, useEffect } from 'react'

import { insertAsyncScriptTag } from './dom'

export const GoogleTagContext = React.createContext()
export const GoogleTagContextProvider = ({ children, networkCode, leaderboard, rightRail }) => {
  const [googleTag, setGoogleTag] = useState(null)
  useEffect(() => {
    if (!networkCode) {
      return
    }
    const googletag = (window.googletag = window.googletag || {})
    googletag.cmd = googletag.cmd || []
    googletag.cmd.push(() => {
      const googletag = window.googletag
      googletag.pubads().setCentering(true)
      googletag.pubads().enableSingleRequest()
      setGoogleTag(googletag)
    })
    insertAsyncScriptTag('https://www.googletagservices.com/tag/js/gpt.js')
  }, [networkCode])
  return (
    <GoogleTagContext.Provider
      value={{
        googletag: googleTag,
        networkCode,
        leaderboard,
        rightRail,
      }}
    >
      {children}
    </GoogleTagContext.Provider>
  )
}
