import React, { useContext } from 'react'

import styles from './AccountDropdown.module.css'
import { Link } from './Link'
import { AuthContext } from './AuthContext'
import { SiteContext } from './SiteContext'
import { Route } from 'react-router'
import Dropdown, { Opener, Overlay } from './Dropdown'
import { MdExpandLess, MdExpandMore } from 'react-icons/md'
import { AdminUrlContext, LoginUrlContext } from './contexts'
import { ReactComponent as EmailIcon } from './branding/email.svg'
import { ReactComponent as GoogleIcon } from './branding/google.svg'

export default () => {
  const { adminUrl } = useContext(AdminUrlContext)
  const { loginUrl } = useContext(LoginUrlContext)
  const { site } = useContext(SiteContext)
  const { isLoggedIn, logout, picture, email, roles, signInProviders, userName } = useContext(AuthContext)
  const isAdmin = roles.includes('superadmin') || roles.includes(`siteadmin:${site.id}`)
  return (
    <Dropdown className={styles.root}>
      <Opener>
        {({ isOpen, toggle }) => {
          return (
            <a
              className={isOpen ? styles.toggleOpen : styles.toggleClosed}
              onClick={(e) => {
                e.preventDefault()
                toggle()
              }}
              href="#toggleAccountDropdown"
            >
              <span className={styles.name}>
                {picture ? <img className={styles.picture} src={picture} alt={'User Profile'} /> : null}
                {isLoggedIn ? userName : 'My Account'}
              </span>{' '}
              {isOpen ? <MdExpandLess /> : <MdExpandMore />}
            </a>
          )
        }}
      </Opener>
      <Overlay className={styles.overlay} position="right">
        {({ close }) => (
          <>
            {!isLoggedIn && (
              <Route>
                <a href={`${loginUrl}/login?redirect=${encodeURIComponent(window.location.href)}`} onClick={close}>
                  Log In / Register
                </a>
              </Route>
            )}
            {isLoggedIn && (
              <>
                {email && (
                  <div>
                    <span title={email}>{email}</span>
                  </div>
                )}
                <div>
                  <span>
                    {signInProviders.includes('google.com') && <GoogleIcon className={styles.icon} />}
                    {signInProviders.includes('email') && <EmailIcon className={styles.icon} />}
                  </span>
                </div>
                <div>
                  <Link to="/account/events" onClick={close}>
                    My Events
                  </Link>
                </div>
                <div>
                  <Link to="/account/receipts" onClick={close}>
                    My Receipts
                  </Link>
                </div>
                {isAdmin && (
                  <div>
                    <a href={adminUrl}>Admin</a>
                  </div>
                )}
                <hr />
                <div>
                  <a
                    href="#logout"
                    onClick={(e) => {
                      e.preventDefault()
                      close()
                      logout()
                    }}
                  >
                    Log Out
                  </a>
                </div>
              </>
            )}
          </>
        )}
      </Overlay>
    </Dropdown>
  )
}
