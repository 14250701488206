import React, { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import {
  getAuth,
  signInWithEmailAndPassword,
  createUserWithEmailAndPassword,
  sendPasswordResetEmail,
  sendEmailVerification,
} from 'firebase/auth'

import styles from './LoginWithEmailPassword.module.css'
import qs from './qs'
import { completeLoginAndRedirect } from './login_util'

export const EmailPasswordSignIn = ({ firebaseApp }) => {
  const { search } = useLocation()
  const { email: emailHint, redirect: redirectUrl } = qs.parse(search)
  const [busy, setBusy] = useState(false)
  const [email, setEmail] = useState(emailHint || '')
  const [password, setPassword] = useState('')
  const [canSubmit, setCanSubmit] = useState(false)
  const [message, setMessage] = useState('')
  const [error, setError] = useState()
  useEffect(() => {
    const emailValid = /@/.test(email)
    setCanSubmit(emailValid)
    setError(emailValid ? '' : 'Please enter your email address')
  }, [email])

  const login = async () => {
    if (password === '') {
      setError('Please enter a password')
      return
    }
    setBusy(true)
    setError(null)
    setCanSubmit(false)
    try {
      const auth = getAuth(firebaseApp)
      const r = await signInWithEmailAndPassword(auth, email.trim(), password)
      if (r.user.emailVerified) {
        await completeLoginAndRedirect(r.user, redirectUrl)
      } else {
        setError(
          <>
            Please{' '}
            <a href="#verify" onClick={verifyEmailAddress}>
              verify your email address
            </a>
            , and then you'll be able to log in
          </>
        )
      }
    } catch (e) {
      let message = e.message ? e.message.replace(/^Firebase:\s?/, '') : 'We ran into a problem'
      switch (e.code) {
        case 'auth/wrong-password':
          message = 'Wrong password'
          break
        case 'auth/user-not-found':
          message = `There is no account for ${email}`
          break
        case 'auth/email-already-in-use':
          message = (
            <>
              Email in use. Would you like to{' '}
              <a href="#reset" onClick={resetPassword}>
                reset your password
              </a>
              ?
            </>
          )
          break
        default:
      }
      setError(message)
    } finally {
      setBusy(false)
      setCanSubmit(true)
    }
  }

  const createAccount = async () => {
    if (password === '') {
      setError('Please enter a password')
      return
    }
    setBusy(true)
    setError(null)
    setCanSubmit(false)
    try {
      const auth = getAuth(firebaseApp)
      const r = await createUserWithEmailAndPassword(auth, email.trim(), password)
      if (r.user.emailVerified) {
        await completeLoginAndRedirect(r.user, redirectUrl)
      } else {
        setError(
          <>
            Please{' '}
            <a href="#verify" onClick={verifyEmailAddress}>
              verify your email address
            </a>
            , and then you'll be able to log in
          </>
        )
      }
    } catch (e) {
      let message = e.message ? e.message.replace(/^Firebase:\s?/, '') : 'We ran into a problem'
      switch (e.code) {
        case 'auth/email-already-in-use':
          message = (
            <>
              Address in use. Would you like to{' '}
              <a href="#reset" onClick={resetPassword}>
                reset your password
              </a>
              ?
            </>
          )
          break
        default:
      }
      setError(message)
    } finally {
      setBusy(false)
      setCanSubmit(true)
    }
  }

  const resetPassword = async (e) => {
    e.preventDefault()
    setBusy(true)
    setError(null)
    setCanSubmit(false)
    try {
      const auth = getAuth(firebaseApp)
      await sendPasswordResetEmail(auth, email.trim(), {
        url: `${window.location.origin}${window.location.pathname}?email=${encodeURIComponent(
          email.trim()
        )}&redirect=${encodeURIComponent(redirectUrl)}`,
      })
      setMessage('Check your email for a link that will let you reset your password')
      setError('')
    } catch (e) {
      let message = e.message ? e.message.replace(/^Firebase:\s?/, '') : 'We ran into a problem'
      switch (e.code) {
        case 'auth/user-not-found':
          message = `There is no account for ${email}`
          break
        default:
      }
      setError(message)
    } finally {
      setBusy(false)
      setCanSubmit(true)
    }
  }

  const verifyEmailAddress = async (e) => {
    e.preventDefault()
    setBusy(true)
    setError(null)
    setCanSubmit(false)
    try {
      await sendEmailVerification(getAuth(firebaseApp).currentUser, {
        url: `${window.location.origin}${window.location.pathname}?email=${encodeURIComponent(
          email.trim()
        )}&redirect=${encodeURIComponent(redirectUrl)}`,
      })
      setMessage('Check your email for a link that will let you verify your account')
      setError('')
    } catch (e) {
      setError(e.message ? e.message.replace(/^Firebase:\s?/, '') : 'We ran into a problem')
    } finally {
      setBusy(false)
      setCanSubmit(true)
    }
  }

  return (
    <div className={styles.root}>
      <label>
        Email
        <input
          placeholder="email@example.com"
          disabled={busy}
          value={email}
          onChange={(e) => {
            setEmail(e.target.value)
            setError(null)
            setMessage('')
          }}
          onKeyDown={(e) => {
            if ((e.key === 'Enter' || e.keyCode === 13) && canSubmit) {
              login()
            }
          }}
        ></input>
      </label>
      <label>
        Password
        <input
          type="password"
          placeholder="password"
          disabled={busy}
          value={password}
          onChange={(e) => {
            setPassword(e.target.value)
            setError(null)
            setMessage('')
          }}
          onKeyDown={(e) => {
            if ((e.key === 'Enter' || e.keyCode === 13) && canSubmit) {
              login()
            }
          }}
        ></input>
      </label>
      <button disabled={busy || !canSubmit} onClick={login}>
        Login
      </button>
      <button disabled={busy || !canSubmit} onClick={createAccount}>
        create an account
      </button>
      <button disabled={busy || !canSubmit} onClick={resetPassword}>
        Reset Password
      </button>
      {message ? <div className={styles.message}>{message}</div> : null}
      {error ? <div className={styles.error}>{error}</div> : null}
    </div>
  )
}
