import React from 'react'
import { Map, Marker } from 'react-leaflet'
import L from 'leaflet'

import styles from './VenueDetail.module.css'
import CustomTileLayer from './CustomTileLayer'

const icon = L.icon({ iconUrl: '/marker-icon.png' })
export default ({ name, address, latitude, longitude }) => (
  <div className={styles.root}>
    <h1 className={styles.title}>{name}</h1>
    <div className={styles.address}>{address}</div>
    <Map className={styles.map} center={[latitude, longitude]} zoom={13}>
      <CustomTileLayer />
      <Marker position={[latitude, longitude]} icon={icon} />
    </Map>
  </div>
)
