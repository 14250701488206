import React, { useContext, useRef, useImperativeHandle } from 'react'

import styles from './Button.module.css'
import { SiteContext } from '../SiteContext'
import { Link } from '../Link'

export default React.forwardRef(({ className = '', to, ...props }, ref) => {
  const { site } = useContext(SiteContext)
  const clickRef = useRef(null)
  useImperativeHandle(ref, () => ({
    click: () => {
      clickRef.current.click()
    },
  }))
  props = {
    ...props,
    className: `${styles.root} ${className}`,
  }
  if (to) {
    return <Link {...props} innerRef={clickRef} to={to} />
  }
  if (site.preventSearchEngineIndex) {
    props.rel = 'nofollow'
  }
  //  eslint-disable-next-line jsx-a11y/anchor-has-content
  return <a {...props} ref={clickRef} />
})
