import React, { useContext } from 'react'
import { Helmet } from 'react-helmet-async'
import moment from 'moment-timezone'

import styles from './MyReceiptsPage.module.css'
import { SiteContext } from '../SiteContext'
import { TrackPageView } from '../analytics'
import Breadcrumbs from '../Breadcrumbs'
import API from '../API'
import Price from '../Price'
import { Link } from '../Link'
import { receiptNumber } from '../Receipt'

const ReceiptRow = ({ id, when, totalCents }) => {
  const {
    site: { timezone },
  } = useContext(SiteContext)
  return (
    <div className={styles.receipt}>
      <Link to={`/account/receipt/${encodeURIComponent(id)}`}>
        <div className={styles.receiptNum}>Receipt #{receiptNumber(id)}</div>
        <div>
          Date: <span className={styles.date}>{moment(when).tz(timezone).format('ddd, MMM Do, h:mm a')}</span>
        </div>
        <div>
          Total: <Price className={styles.price} cents={totalCents} />{' '}
        </div>
      </Link>
    </div>
  )
}

export default ({ breadcrumbs }) => {
  const { site } = useContext(SiteContext)
  return (
    <div className={styles.root}>
      <Helmet>
        <title>My Receipts - {site.name}</title>
      </Helmet>
      <TrackPageView title="MyReceipts" />
      <Breadcrumbs breadcrumbs={breadcrumbs} />
      <h1 className={styles.heading}>My Receipts</h1>
      <API input="/api/invoices">
        {({ data }) => {
          if (!data) {
            return null
          }
          const { receipts } = data
          return (
            <div>
              {receipts.length === 0 && (
                <div className={styles.noPurchases}>
                  <div>You have made no purchases.</div>
                </div>
              )}
              {receipts.map((receipt) => (
                <ReceiptRow key={receipt.id} {...receipt} />
              ))}
            </div>
          )
        }}
      </API>
    </div>
  )
}
