import React, { useContext, useEffect, useState } from 'react'
import moment from 'moment-timezone'
import { makeStyles } from '@material-ui/core/styles'
import { Paper, Typography, Table, TableBody, TableCell, TableHead, TableRow, Tooltip } from '@material-ui/core'

import { NewFetchError } from './Fetch'
import { AuthContext } from './AuthContext'
import TextPopover from './TextPopover'
import { TimezoneContext } from './contexts'
import { Link } from './AdminLink'

const useStyles = makeStyles((theme) => ({
  noRuns: {
    padding: '1em',
  },
  tableWrapper: {
    maxWidth: '100%',
    overflowX: 'auto',
    '& tbody tr:nth-of-type(odd)': {
      backgroundColor: theme.palette.background.default,
    },
  },
  headRow: {
    '& th': {
      borderBottom: 0,
      paddingBottom: 0,
    },
  },
  headTitle: {
    borderBottom: '1px solid currentColor',
  },
  noWrap: {
    whiteSpace: 'nowrap',
  },
  truncated: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    maxWidth: '6em',
  },
}))

const extractTypes = (runs, key) => {
  const types = runs
    .reduce((acc, cur) => {
      const data = cur[key] || {}
      Object.keys(data).forEach((type) => acc.add(type))
      return acc
    }, new Set())
    .values()
  return [...types].sort()
}

const Count = ({ count }) => {
  if (typeof count === 'undefined') {
    return null
  }
  return count.toLocaleString()
}

const summarizeUrl = (url) => (url ? new URL(url).hostname.split('.').reverse().slice(0, 2).reverse().join('.') : url)

export default ({ type }) => {
  const classes = useStyles()
  const { getIdToken } = useContext(AuthContext)
  const { timezone } = useContext(TimezoneContext)
  const [runs, setRuns] = useState()

  useEffect(() => {
    let cancel = false
    ;(async () => {
      const idToken = await getIdToken()
      const resp = await fetch(`/api/feeds/runs?type=${encodeURIComponent(type)}`, {
        headers: {
          authorization: `Bearer ${idToken}`,
        },
      })
      if (!resp.ok) {
        throw await NewFetchError(resp)
      }
      const data = await resp.json()
      if (cancel) {
        return
      }
      setRuns(data.runs)
    })()
    return () => {
      cancel = true
    }
  }, [getIdToken, type])

  if (!runs) {
    return null
  }
  const ingressTypes = extractTypes(runs, 'ingress')
  const processedTypes = extractTypes(runs, 'processed').filter((v) => type !== 'scrape' || v !== 'Venues')
  return (
    <Paper>
      {runs.length === 0 && <Typography className={classes.noRuns}>There are no runs for this feed.</Typography>}
      {runs.length > 0 && (
        <div className={classes.tableWrapper}>
          <Table aria-label="feed table" size="small">
            <TableHead>
              <TableRow className={classes.headRow}>
                <TableCell colSpan={type !== 'scrape' ? 5 : 6}></TableCell>
                {ingressTypes.length > 0 && (
                  <TableCell align="center" colSpan={5 * ingressTypes.length}>
                    <div className={classes.headTitle}>Ingress Counts</div>
                  </TableCell>
                )}
                {processedTypes.length > 0 && (
                  <TableCell align="center" colSpan={5 * processedTypes.length}>
                    <div className={classes.headTitle}>Processed Counts</div>
                  </TableCell>
                )}
              </TableRow>
              <TableRow className={classes.headRow}>
                <TableCell colSpan={type !== 'scrape' ? 5 : 6}></TableCell>
                {ingressTypes.map((type) => (
                  <TableCell key={type} align="center" colSpan={5}>
                    <div className={classes.headTitle}>{type}</div>
                  </TableCell>
                ))}
                {processedTypes.map((type) => (
                  <TableCell key={type} align="center" colSpan={5}>
                    <div className={classes.headTitle}>{type}</div>
                  </TableCell>
                ))}
              </TableRow>
              <TableRow>
                <TableCell>Start</TableCell>
                <TableCell>Duration</TableCell>
                <TableCell>Type</TableCell>
                <TableCell>Error</TableCell>
                {type !== 'scrape' ? (
                  <TableCell align="center">State</TableCell>
                ) : (
                  <>
                    <TableCell align="center">venue : category</TableCell>
                    <TableCell align="center">plugin</TableCell>
                    <TableCell align="center">url</TableCell>
                  </>
                )}
                {ingressTypes.map((type) => (
                  <React.Fragment key={type}>
                    <TableCell align="center">
                      <Tooltip title="Created" aria-label="created">
                        <span>C</span>
                      </Tooltip>
                    </TableCell>
                    <TableCell align="center">
                      <Tooltip title="Updated" aria-label="updated">
                        <span>U</span>
                      </Tooltip>
                    </TableCell>
                    <TableCell align="center">
                      <Tooltip title="Deleted" aria-label="deleted">
                        <span>D</span>
                      </Tooltip>
                    </TableCell>
                    <TableCell align="center">
                      <Tooltip title="Not Modified" aria-label="not modified">
                        <span>N</span>
                      </Tooltip>
                    </TableCell>
                    <TableCell align="center">
                      <Tooltip title="Invalid" aria-label="invalid">
                        <span>I</span>
                      </Tooltip>
                    </TableCell>
                  </React.Fragment>
                ))}
                {processedTypes.map((type) => (
                  <React.Fragment key={type}>
                    <TableCell align="center">
                      <Tooltip title="Created" aria-label="created">
                        <span>C</span>
                      </Tooltip>
                    </TableCell>
                    <TableCell align="center">
                      <Tooltip title="Updated" aria-label="updated">
                        <span>U</span>
                      </Tooltip>
                    </TableCell>
                    <TableCell align="center">
                      <Tooltip title="Deleted" aria-label="deleted">
                        <span>D</span>
                      </Tooltip>
                    </TableCell>
                    <TableCell align="center">
                      <Tooltip title="Not Modified" aria-label="not modified">
                        <span>N</span>
                      </Tooltip>
                    </TableCell>
                    <TableCell align="center">
                      <Tooltip title="Invalid" aria-label="invalid">
                        <span>I</span>
                      </Tooltip>
                    </TableCell>
                  </React.Fragment>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {runs.map((run) => (
                <TableRow key={run.id}>
                  <TableCell className={classes.noWrap}>
                    {moment(run.start).tz(timezone).format('MMM D, h:mm a')}
                  </TableCell>
                  <TableCell className={classes.noWrap}>
                    {run.end && moment.duration(moment(run.end).diff(moment(run.start))).humanize()}
                  </TableCell>
                  <TableCell>{run.type}</TableCell>
                  <TableCell>
                    <TextPopover title={<div className={classes.truncated}>{run.error}</div>}>
                      <div>{run.error}</div>
                      <pre>{run.trace}</pre>
                    </TextPopover>
                  </TableCell>
                  {type !== 'scrape' ? (
                    <TableCell align="center">
                      <TextPopover title={<div className={classes.truncated}>{run.state}</div>}>
                        {run.state}
                      </TextPopover>
                    </TableCell>
                  ) : (
                    <>
                      <TableCell>
                        <Link to={`/scrape/edit/${encodeURIComponent(run.feedId)}`}>{run.name}</Link>
                      </TableCell>
                      <TableCell>{run.scrapePlugin}</TableCell>
                      <TableCell>
                        <a href={run.scrapeUrl}>{summarizeUrl(run.scrapeUrl)}</a>
                      </TableCell>
                    </>
                  )}
                  {ingressTypes.map((type) => {
                    const counts = run.ingress[type] || {}
                    return (
                      <React.Fragment key={type}>
                        <TableCell align="right">
                          <Count count={counts.created} />
                        </TableCell>
                        <TableCell align="right">
                          <Count count={counts.updated} />
                        </TableCell>
                        <TableCell align="right">
                          <Count count={counts.deleted} />
                        </TableCell>
                        <TableCell align="right">
                          <Count count={counts.notModified} />
                        </TableCell>
                        <TableCell align="right">
                          <Count count={counts.invalid} />
                        </TableCell>
                      </React.Fragment>
                    )
                  })}
                  {processedTypes.map((type) => {
                    const counts = run.processed[type] || {}
                    return (
                      <React.Fragment key={type}>
                        <TableCell align="right">
                          <Count count={counts.created} />
                        </TableCell>
                        <TableCell align="right">
                          <Count count={counts.updated} />
                        </TableCell>
                        <TableCell align="right">
                          <Count count={counts.deleted} />
                        </TableCell>
                        <TableCell align="right">
                          <Count count={counts.notModified} />
                        </TableCell>
                        <TableCell align="right">
                          <Count count={counts.invalid} />
                        </TableCell>
                      </React.Fragment>
                    )
                  })}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </div>
      )}
    </Paper>
  )
}
