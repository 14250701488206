import React, { useContext, useState } from 'react'
import { useHistory } from 'react-router-dom'
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField,
} from '@material-ui/core'

import { NewFetchError } from './Fetch'
import { AuthContext } from './AuthContext'
import { useStickySearch } from './AdminLink'

export default ({ children }) => {
  const { getIdToken } = useContext(AuthContext)
  const history = useHistory()
  const stickySearch = useStickySearch()
  const [open, setOpen] = useState(false)
  const [host, setHost] = useState('')
  const [hostError, setHostError] = useState('')
  const [name, setName] = useState('')
  const [nameError, setNameError] = useState('')
  const [submitting, setSubmitting] = useState()

  const handleOpen = () => {
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
    setSubmitting(false)
  }

  const handleSubmit = () => {
    const normalized = {
      host: host.trim(),
      name: name.trim(),
    }
    setSubmitting(true)
    ;(async () => {
      const idToken = await getIdToken()
      const resp = await fetch('/api/site/create', {
        method: 'post',
        body: JSON.stringify(normalized),
        headers: {
          'Content-Type': 'application/json',
          authorization: `Bearer ${idToken}`,
        },
      })
      setSubmitting(false)
      if (!resp.ok) {
        if (resp.status === 400) {
          try {
            const data = await resp.json()
            if (data.type === 'validation_error') {
              setHostError(data.fields.host)
              setNameError(data.fields.name)
              return
            }
          } catch {} // Treat as generic error
        }
        throw await NewFetchError(resp)
      }
      const data = await resp.json()
      setOpen(false)
      history.push(stickySearch(`/site/edit?site=${encodeURIComponent(data.siteId)}`))
    })()
  }
  return (
    <div>
      {children && children({ open: handleOpen })}
      <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">Add Site</DialogTitle>
        <DialogContent>
          <DialogContentText>
            To create a new site, please enter a site host and name here. The site host can be changed later and is the
            first component of the site's URL.
          </DialogContentText>
          <TextField
            autoFocus
            name="host"
            value={host}
            onChange={(e) => {
              setHost(e.target.value.toLowerCase())
            }}
            error={!!hostError}
            helperText={hostError}
            margin="dense"
            label="Site Host"
            autoComplete="off"
            fullWidth
          />
          <TextField
            name="name"
            label="Name"
            onChange={(e) => {
              setName(e.target.value)
            }}
            error={!!nameError}
            helperText={nameError}
            margin="dense"
            autoComplete="off"
            fullWidth
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>
          <Button onClick={handleSubmit} color="primary" disabled={submitting}>
            Add
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  )
}
