import React from 'react'

import API from '../API'
import ServiceError from '../ServiceError'
import ErrorBoundary from '../ErrorBoundary'
import FeaturedVenues from '../components/FeaturedVenues'

export default () => (
  <ErrorBoundary component={() => <ServiceError message="Featured venues temporarily unavailable" />}>
    <API input="/api/featuredvenues/site">{({ data }) => data && <FeaturedVenues venues={data || []} />}</API>
  </ErrorBoundary>
)
