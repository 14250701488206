import React from 'react'
import Select, { components } from 'react-select'

import styles from './CategoryPicker.module.css'
import { catsToOptions, filterOption } from '../searchopts'

const MultiValue = ({ data, ...props }) => {
  return (
    <components.MultiValue {...props}>
      <div className={styles.label}>{data.label}</div>
      {data.path && data.path.length > 0 && <div className={styles.path}>{data.path.join(' » ')}</div>}
    </components.MultiValue>
  )
}

const SingleValue = ({ data, ...props }) => {
  return (
    <components.SingleValue {...props}>
      <div className={styles.label}>{data.label}</div>
      {data.path && data.path.length > 0 && <div className={styles.path}>{data.path.join(' » ')}</div>}
    </components.SingleValue>
  )
}

const Option = ({ data, ...props }) => {
  return (
    <components.Option {...props}>
      <div className={data.path.length === 0 ? styles.topLevel : ''}>{data.label}</div>
      {data.path && data.path.length > 0 && <div className={styles.path}>{data.path.join(' » ')}</div>}
    </components.Option>
  )
}

export default ({ value: selection, onChange, onBlur, multiple, categories = [], placeholder }) => {
  const options = catsToOptions(categories)
  let value
  if (multiple) {
    value = selection ? options.filter((v) => selection.includes(v.id)) : []
  } else {
    value = options.filter((v) => selection === v.id)
    value = value.length === 0 ? null : value[0]
  }
  const handleChange = (data) => {
    let result
    if (multiple) {
      result = data ? data.map((v) => v.id) : []
    } else {
      result = data ? data.id : ''
    }
    onChange && onChange(result)
  }
  return (
    <Select
      className={styles.root}
      value={value}
      isMulti={multiple}
      onChange={handleChange}
      onBlur={onBlur}
      options={options}
      getOptionValue={(opt) => opt.id}
      filterOption={filterOption}
      components={{ Option, MultiValue, SingleValue }}
      placeholder={placeholder}
    />
  )
}
