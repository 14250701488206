import React, { useContext } from 'react'

import styles from './HomeCategoryCard.module.css'
import { ImageServiceContext } from './ImageServiceContext'
import { Link } from './Link'

export default ({ id, name, blurb, thumbnailImageChecksum }) => {
  const { imageURL } = useContext(ImageServiceContext)
  return (
    <Link className={styles.root} to={`/category/${encodeURIComponent(id)}`}>
      <div
        className={styles.image}
        style={{ backgroundImage: `url(${imageURL({ checksum: thumbnailImageChecksum, size: '500,' })})` }}
      />
      <div className={styles.content}>
        <h2>{name}</h2>
        <p>{blurb}</p>
      </div>
    </Link>
  )
}
