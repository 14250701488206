import React, { useContext, useState, useEffect } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { Button, Paper } from '@material-ui/core'

import { AuthContext } from './AuthContext'
import { NewFetchError } from './Fetch'
import { AdminSiteContext } from './AdminSiteContext'

const useStyles = makeStyles({
  root: {
    padding: '1em',
    marginBottom: '1em',
  },
})

export default () => {
  const classes = useStyles()

  return (
    <Paper className={classes.root}>
      <EditUpsellRates />
    </Paper>
  )
}

const EditUpsellRates = () => {
  const { getIdToken } = useContext(AuthContext)
  const { siteId } = useContext(AdminSiteContext)
  const [upsellRates, setUpsellRates] = useState('')
  const [ready, setReady] = useState(false)
  const [submitting, setSubmitting] = useState()
  const [error, setError] = useState({})

  useEffect(() => {
    setReady(false)
    ;(async () => {
      const idToken = await getIdToken()
      const resp = await fetch(`/api/upsellrates?site=${encodeURIComponent(siteId)}`, {
        headers: {
          authorization: `Bearer ${idToken}`,
        },
      })
      if (!resp.ok) {
        throw await NewFetchError(resp)
      }
      const data = await resp.json()
      setUpsellRates({ 'featured-daily-event': 0, ...data })
      setReady(true)
    })()
  }, [getIdToken, siteId])

  const save = async (data) => {
    const vErrs = {}
    if (Object.keys(vErrs).length > 0) {
      return vErrs
    }
    const idToken = await getIdToken()
    const resp = await fetch(`/api/upsellrates/update?site=${encodeURIComponent(siteId)}`, {
      method: 'post',
      body: JSON.stringify(data),
      headers: {
        authorization: `Bearer ${idToken}`,
      },
    })
    if (!resp.ok) {
      if (resp.status === 400) {
        try {
          const data = await resp.json()
          if (data.type === 'validation_error') {
            return data.fields || { name: 'unknown error' }
          }
        } catch {} // Treat as generic error
      }
      throw await NewFetchError(resp)
    }
  }

  const handleSubmit = async (successCallback = () => null) => {
    setSubmitting(true)
    let data = { ...upsellRates }
    let failed = false
    Object.keys(upsellRates).forEach((k) => {
      try {
        const v = Number(upsellRates[k])
        if (!isNaN(v)) {
          data[k] = v
        } else {
          setError({ ...error, [k]: 'not a number' })
          failed = true
        }
      } catch (e) {
        setError({ [k]: e.message })
        failed = true
      }
    })
    if (failed) {
      setSubmitting(false)
      return
    }
    const error = await save(data)
    if (error) {
      setError(error)
      setSubmitting(false)
      return
    }
    setError(null)
    setSubmitting(false)
    successCallback()
  }

  if (!ready) {
    return null
  }
  return (
    <div>
      <div>
        <label>Featured Daily Events (rate in cents per day)</label>{' '}
        <input
          type="number"
          min="0.01"
          step="0.01"
          value={upsellRates?.['featured-daily-event']}
          onChange={(e) => setUpsellRates({ ...upsellRates, 'featured-daily-event': e.target.value })}
        />
      </div>
      {error?.['featured-daily-event'] ? error['featured-daily-event'] : null}
      <div>
        <Button onClick={() => handleSubmit()} color="primary" disabled={submitting}>
          Save
        </Button>
        {submitting ? ' ...' : ''}
      </div>
    </div>
  )
}
