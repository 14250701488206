import React, { useContext, useEffect, useState } from 'react'
import { Helmet } from 'react-helmet-async'
import { Table, TableBody, TableCell, TableHead, TableRow, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'

import { TrackPageView } from '../analytics'
import { AdminSiteContext } from '../AdminSiteContext'
import AdminApiKeys from '../AdminApiKeys'
import { AuthContext } from '../AuthContext'
import { NewFetchError } from '../Fetch'

const useStyles = makeStyles((theme) => ({
  root: {
    '& code': {
      fontFamily: 'monospace',
      backgroundColor: 'white',
      padding: '0.2em 0.4em',
    },
  },
  example: {
    display: 'block',
    whiteSpace: 'pre',
    fontFamily: 'monospace',
    backgroundColor: 'white',
    lineHeight: '1.4em',
    padding: '1em',
  },
  widgetPaper: {
    marginTop: '1em',
    maxWidth: '350px',
  },
}))
export default () => {
  const classes = useStyles()
  const { siteId, siteName } = useContext(AdminSiteContext)
  const { getIdToken } = useContext(AuthContext)
  const [categories, setCategories] = useState([])
  const apiUrl = window.location.origin.replace(/admin\./, 'api.') + '/v1/events'
  useEffect(() => {
    let cancel = false
    ;(async () => {
      const idToken = await getIdToken()
      const resp = await fetch(`/api/eventcategories?site=${encodeURIComponent(siteId)}`, {
        headers: {
          authorization: `Bearer ${idToken}`,
        },
      })
      if (!resp.ok) {
        throw await NewFetchError(resp)
      }
      const data = await resp.json()
      if (cancel) {
        return
      }
      setCategories(data)
    })()
    return () => {
      cancel = true
    }
  }, [siteId, getIdToken])
  return (
    <div className={classes.root}>
      <Helmet>
        <title>API - Vyb - {siteName}</title>
      </Helmet>
      <TrackPageView title="API" />
      <Typography component="h1" variant="h2">
        API ~ {siteName}
      </Typography>
      <ol>
        <li>
          <Typography>The API is meant to be used server-side. Do not leak your API key.</Typography>
        </li>
        <li>Endpoint URL {apiUrl}</li>
        <li>
          <p>
            API requests require an API key which is passed via the <code>apiKey</code> URL query parameter.
          </p>
        </li>
        <li>
          Pass search criteria via URL query parameters. All search criteria are optional.
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Key</TableCell>
                <TableCell>Format/Valid value(s)</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <TableCell>editorsPicksOnly</TableCell>
                <TableCell>true to limit to only editors picks</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>curatedVenueId</TableCell>
                <TableCell></TableCell>
              </TableRow>
              <TableRow>
                <TableCell>startDate</TableCell>
                <TableCell>YYYY-MM-DD</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>endDate</TableCell>
                <TableCell>YYYY-MM-DD</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>tags</TableCell>
                <TableCell>
                  pass multiple values via multiple query parameters. e.g. ?tags=foo&amp;tags=foo%3Dbar
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>zipcode</TableCell>
                <TableCell>
                  pass multiple values via multiple query parameters. e.g. ?zipcode=20012&amp;zipcode=20011
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>category</TableCell>
                <TableCell>pass multiple values via multiple query parameters. Parameters are ORed together</TableCell>
              </TableRow>
            </TableBody>
          </Table>
          <p>
            Note that zipcodes are converted to a geographic bounds so addresses might not all match the selected
            zipcodes due to geocoding or GIS limitations.
          </p>
          {categories.length > 0 && (
            <>
              <h2>Categories</h2>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>ID (use this as parameter)</TableCell>
                    <TableCell>Name / Path</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {categories.map((c, idx) => (
                    <TableRow key={idx}>
                      <TableCell>{c.id}</TableCell>
                      <TableCell>{c.namePath}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </>
          )}
        </li>
        <li>
          The API returns a JSON array of up to 30 events. <br />
          Results are sorted by startDate ascending. <br />
          All results are geofenced to the site's bounds. <br />
          All dates and times are in the site's timezone.
          <pre>
            <code>
              {JSON.stringify(
                [
                  {
                    eventDetailPageUrl: 'https://...',
                    title: 'event title',
                    description: 'event description html',
                    imageThumbUrl: 'https://...',
                    imageFullUrl: 'https://...',
                    startDate: '2022-01-01',
                    endDate: '2022-01-01',
                    startTime: '13:00 (omit empty)',
                    endTime: '14:00 (omit empty)',
                    venue: {
                      name: 'cool venue',
                      address: '123 Main ST',
                    },
                  },
                ],
                null,
                2
              )}
            </code>
          </pre>
        </li>
      </ol>
      <AdminApiKeys apiUrl={apiUrl} />
    </div>
  )
}
