import React, { useContext, useEffect, useRef, useState } from 'react'
import { Formik, Field, Form, FieldArray, ErrorMessage } from 'formik'
import moment from 'moment-timezone'
import { array, object, string, boolean } from 'yup'
import ReactQuill from 'react-quill'
import 'react-quill/dist/quill.snow.css'

import { AuthContext } from '../AuthContext'
import { SiteContext } from '../SiteContext'
import { RecaptchaContext } from '../recaptcha'
import styles from './CreateEventForm.module.css'
import ImageUpload from './ImageUpload'
import SingleDatepicker from './SingleDatepicker'
import Timepicker from './Timepicker'
import VenuePicker from './VenuePicker'
import CategoryPicker from './CategoryPicker'
import Button from './Button'

const makeInitial = (
  {
    id,
    published,
    title,
    description,
    categories,
    imageChecksum,
    start,
    end,
    venue,
    noEnd,
    noStart,
    upstreamUrl,
    ticketUrl,
    contact,
  },
  timezone
) => {
  if (!id) {
    return {
      published: true,
      title: '',
      description: '',
      categories: [],
      imageChecksum: '',
      startDate: '',
      startTime: '',
      endTime: '',
      additionalSeriesEvents: [],
      venue: {},
      venueAddress: '',
      venueLatitude: null,
      venueLongitude: null,
      upstreamUrl: '',
      ticketUrl: '',
      contact: {
        name: '',
        phone: '',
        email: '',
        orgName: '',
      },
    }
  }
  const startTime = moment(start).tz(timezone)
  const endTime = moment(end).tz(timezone)
  return {
    published,
    title,
    description,
    categories,
    imageChecksum,
    startDate: startTime.format('YYYY-MM-DD'),
    startTime: noStart ? '' : startTime.format('HH:mm'),
    endTime: noEnd ? '' : endTime.format('HH:mm'),
    additionalSeriesEvents: [],
    venue: { id: venue.id, name: venue.name, address: venue.address },
    venueName: '',
    venueLatitude: null,
    venueLongitude: null,
    upstreamUrl,
    ticketUrl,
    contact: {
      name: contact.name,
      phone: contact.phone,
      email: contact.email,
      orgName: contact.orgName,
    },
  }
}

const normalizeDate = ({ startDate, startTime, endTime }, timezone) => {
  const noStart = !startTime
  const noEnd = !endTime
  const start = moment.tz(`${startDate}T${noStart ? '00:00' : startTime}`, timezone)
  let end = noEnd ? start : moment.tz(`${startDate}T${endTime}`, timezone)
  if (!noStart && !noEnd && end.isBefore(start)) {
    end = end.add(1, 'd')
  }
  return {
    start,
    noStart,
    end,
    noEnd,
  }
}

export default ({ timezone, initialMonth, getVenues, categories, event = {}, uploadImage, onSubmit }) => {
  const { roles, userId } = useContext(AuthContext)
  const { site } = useContext(SiteContext)
  const { recaptchaSiteKey } = useContext(RecaptchaContext)
  const [recaptchaResponse, setRecaptchaResponse] = useState(null)
  const [promote, setPromote] = useState(false)
  const [lastDate, setLastDate] = useState(moment().format('YYYY-MM-DD'))
  const isOwner = userId === event.createdBy
  const isSuperAdmin = roles.includes('superadmin')
  const isAdmin = isSuperAdmin || roles.includes(`siteadmin:${site.id}`)
  const recaptchaElement = useRef(null)
  const useRecaptcha = !event.id && recaptchaSiteKey
  useEffect(() => {
    if (!useRecaptcha || !recaptchaElement.current) {
      return
    }
    // https://developers.google.com/recaptcha/docs/display#js_api
    window.grecaptcha.ready(function () {
      window.grecaptcha.render(recaptchaElement.current, {
        sitekey: recaptchaSiteKey,
        callback: (token) => {
          setRecaptchaResponse(token)
        },
        'expired-callback': () => {
          setRecaptchaResponse(null)
        },
      })
    })
  }, [useRecaptcha, recaptchaSiteKey, recaptchaElement])

  if (event.id && !(isOwner || isAdmin)) {
    // TODO better error message for when attempting unauthorized edit
    return null
  }
  if (event.id && event.feedData) {
    // TODO better error message for when attempting unauthorized edit of feed content
    return null
  }
  return (
    <Formik
      initialValues={makeInitial(event, timezone)}
      validationSchema={object().shape({
        published: boolean(),
        title: string().required('Title is required').min(3, 'Title is too short'),
        description: string().required('Description is required'),
        imageChecksum: string().required('You must select an image'),
        startDate: string()
          .required('Start date is required')
          .matches(/^\d{4}-\d{2}-\d{2}$/),
        startTime: string().matches(/^(|\d{2}:\d{2})$/),
        endTime: string().matches(/^(|\d{2}:\d{2})$/),
        additionalSeriesEvents: array().of(
          object().shape({
            startDate: string()
              .required('Start date is required')
              .matches(/^(|\d{4}-\d{2}-\d{2})$/),
            startTime: string().matches(/^(|\d{2}:\d{2})$/),
            endTime: string().matches(/^(|\d{2}:\d{2})$/),
          })
        ),
        venue: object()
          .nullable()
          .required('Venue is required')
          .shape({ id: string().required('Venue must have an id') }),
        venueName: string().nullable(),
        venueAddress: string().nullable(),
        venueLatitude: string()
          .matches(/(^-?\d{1,3}\.\d{1,5}$)/)
          .nullable(),
        venueLongitude: string()
          .matches(/(^-?\d{1,2}\.\d{1,5}$)/)
          .nullable(),
        categories: array().required('Category is required'),
        upstreamUrl: string().matches(/^(http:\/\/|https:\/\/)/i, 'Invalid URL (must start with http or https)'),
        ticketUrl: string().matches(/^(http:\/\/|https:\/\/)/i, 'Invalid URL (must start with http or https)'),
        contact: object().required().shape({
          name: string(),
          phone: string(),
          email: string(),
          orgName: string(),
        }),
      })}
      onSubmit={async (values, actions) => {
        const when = normalizeDate(values, timezone)
        if (when.end.isBefore(when.start)) {
          actions.setStatus({ when: 'End date must not be before start date' })
          actions.setSubmitting(false)
          return
        }
        const additionalSeries = values.additionalSeriesEvents.map((v) => normalizeDate(v, timezone))
        let addDateValidationError = false
        additionalSeries.forEach((when, i) => {
          if (when.end.isBefore(when.start)) {
            addDateValidationError = true
            actions.setStatus({ additionalWhen: 'End date must not be before start date' })
          }
        })
        if (addDateValidationError) {
          actions.setSubmitting(false)
          return
        }
        if (useRecaptcha && !recaptchaResponse) {
          actions.setSubmitting(false)
          return
        }
        const normalized = {
          id: event.id,
          published: values.published,
          title: values.title,
          description: values.description,
          imageChecksum: values.imageChecksum,
          start: when.start.format(),
          noStart: when.noStart,
          end: when.end.format(),
          noEnd: when.noEnd,
          additionalSeriesEvents: additionalSeries.map((when) => ({
            start: when.start.format(),
            noStart: when.noStart,
            end: when.end.format(),
            noEnd: when.noEnd,
          })),
          venueId: values.venue.id,
          venueName: values.venueName,
          venueAddress: values.venueAddress,
          venueLatitude: values.venueLatitude ? parseFloat(values.venueLatitude) : null,
          venueLongitude: values.venueLongitude ? parseFloat(values.venueLongitude) : null,
          categories: values.categories,
          upstreamUrl: values.upstreamUrl,
          ticketUrl: values.ticketUrl,
          contact: {
            name: values.contact.name,
            phone: values.contact.phone,
            email: values.contact.email,
            orgName: values.contact.orgName,
          },
        }
        try {
          onSubmit && (await onSubmit(normalized, promote, useRecaptcha ? recaptchaResponse : null))
          actions.setStatus()
        } catch (error) {
          actions.setStatus({ msg: 'Your form has some problems' })
          if (useRecaptcha) {
            setRecaptchaResponse(null)
            // https://developers.google.com/recaptcha/docs/display#js_api
            window.grecaptcha.ready(function () {
              window.grecaptcha.reset()
            })
          }
        }
        actions.setSubmitting(false)
      }}
    >
      {({ errors, touched, values, status, setFieldValue, setFieldTouched, isSubmitting, submitForm }) => {
        const enableSubmit = !isSubmitting && (!useRecaptcha || (useRecaptcha && recaptchaResponse))
        return (
          <Form className={styles.root}>
            <div className={styles.preamble}>
              {event.id && isOwner ? (
                <p>You are editing your event.</p>
              ) : event.id && isAdmin ? (
                <p>You are making a platform wide edit of this event.</p>
              ) : null}
            </div>
            <fieldset>
              <h3 className={styles.heading}>Tell us about your event</h3>
              <Field name="title" type="text" autoComplete="off" placeholder="Event title" />
              <ErrorMessage component="div" name="title" className={styles.error} />
            </fieldset>
            <fieldset>
              <ReactQuill
                theme="snow"
                defaultValue={values.description}
                modules={{
                  toolbar: [
                    [{ header: [1, 2, 3, 4, false] }],
                    ['bold', 'italic', 'underline', 'strike', 'blockquote'],
                    [{ list: 'ordered' }, { list: 'bullet' }, { indent: '-1' }, { indent: '+1' }],
                    ['link'],
                    ['clean'],
                  ],
                }}
                formats={[
                  'header',
                  'bold',
                  'italic',
                  'underline',
                  'strike',
                  'blockquote',
                  'list',
                  'bullet',
                  'indent',
                  'link',
                ]}
                onChange={(content, _delta, _source, _editor) => setFieldValue('description', content)}
                onBlur={() => setFieldTouched('description', true)}
              />
              <ErrorMessage component="div" name="description" className={styles.error} />
            </fieldset>
            <fieldset>
              <h3 className={styles.heading}>Where is your event happening?</h3>
              <p className={styles.subHeading}>Start typing the name or address to find your venue</p>
              <VenuePicker
                value={values.venue}
                onChange={(opt) => {
                  setFieldValue('venue', opt)
                  if (opt?.name === '') {
                    setFieldValue('venueAddress', opt.address)
                    setFieldValue('venueLatitude', opt.latitude.toFixed(5))
                    setFieldValue('venueLongitude', opt.longitude.toFixed(5))
                    setFieldTouched('venueAddress', true)
                    setFieldTouched('venueLatitude', true)
                    setFieldTouched('venueLongitude', true)
                  } else {
                    setFieldValue('venueName', null)
                    setFieldValue('venueAddress', null)
                    setFieldValue('venueLatitude', null)
                    setFieldValue('venueLongitude', null)
                    setFieldTouched('venueAddress', true)
                    setFieldTouched('venueLatitude', true)
                    setFieldTouched('venueLongitude', true)
                  }
                }}
                onBlur={() => setFieldTouched('venue', true)}
                getVenues={getVenues}
                placeholder="Venue"
              />
              {errors.venue && touched.venue && (
                <div className={styles.error}>
                  {typeof errors.venue !== 'object' ? errors.venue : <pre>{JSON.stringify(errors.venue)}</pre>}
                </div>
              )}
              {values.venue && values.venue.name === '' && (
                <div>
                  <h3 className={styles.heading}>Would you like to create a named venue with this address?</h3>
                  <Field
                    name="venueName"
                    type="text"
                    autoComplete="off"
                    placeholder="Venue Name"
                    onChange={(e) => setFieldValue('venueName', e.target.value)}
                    onBlur={() => setFieldTouched('venueName', true)}
                  />
                  <ErrorMessage component="div" name="venueName" className={styles.error} />
                  <label>Address</label>
                  <Field
                    name="venueAddress"
                    type="text"
                    autoComplete="off"
                    value={values.venueAddress}
                    onChange={(e) => setFieldValue('venueAddress', e.target.value)}
                    onBlur={() => setFieldTouched('venueAddress', true)}
                  />
                  <ErrorMessage component="div" name="venueAddress" className={styles.error} />
                  <label>Latitude</label>
                  <Field
                    name="venueLatitude"
                    type="text"
                    autoComplete="off"
                    value={values.venueLatitude}
                    onChange={(e) => setFieldValue('venueLatitude', e.target.value)}
                    onBlur={() => setFieldTouched('venueLatitude', true)}
                  />
                  <ErrorMessage component="div" name="venueLatitude" className={styles.error} />
                  <label>Longitude</label>
                  <Field
                    name="venueLongitude"
                    type="text"
                    autoComplete="off"
                    value={values.venueLongitude}
                    onChange={(e) => setFieldValue('venueLongitude', e.target.value)}
                    onBlur={() => setFieldTouched('venueLongitude', true)}
                  />
                  <ErrorMessage component="div" name="venueLongitude" className={styles.error} />
                </div>
              )}
            </fieldset>
            <fieldset>
              <h3 className={styles.heading}>What type of event is it?</h3>
              <CategoryPicker
                value={values.categories}
                onChange={(opt) => setFieldValue('categories', opt)}
                onBlur={() => setFieldTouched('categories', true)}
                multiple
                categories={categories}
                placeholder="Category"
              />
              <ErrorMessage component="div" name="categories" className={styles.error} />
            </fieldset>
            <fieldset>
              <h3 className={styles.heading}>Upload an image</h3>
              <ImageUpload
                value={values.imageChecksum}
                onChange={(opt) => setFieldValue('imageChecksum', opt)}
                onUpload={uploadImage}
              />
              <ErrorMessage component="div" name="imageChecksum" className={styles.error} />
            </fieldset>
            <fieldset>
              <h3 className={styles.heading}>When is your event happening?</h3>
              <div className={styles.when}>
                <div>
                  <SingleDatepicker
                    selectedDay={values.startDate}
                    onChange={(v) => {
                      setFieldValue('startDate', v)
                      setLastDate(v)
                    }}
                    onBlur={() => setFieldTouched('startDate', true)}
                    start={initialMonth}
                  />
                  <ErrorMessage component="div" name="startDate" className={styles.error} />
                </div>
                <div>
                  <Timepicker
                    placeholder="Start Time"
                    value={values.startTime}
                    onChange={(v) => setFieldValue('startTime', v)}
                    onBlur={() => setFieldTouched('startTime', true)}
                  />
                  <ErrorMessage component="div" name="startTime" className={styles.error} />
                  <Timepicker
                    placeholder="End Time"
                    value={values.endTime}
                    onChange={(v) => setFieldValue('endTime', v)}
                    onBlur={() => setFieldTouched('endTime', true)}
                  />
                  <ErrorMessage component="div" name="endTime" className={styles.error} />
                </div>
                {status && status.when && <div>{status.when}</div>}
              </div>
            </fieldset>
            <fieldset>
              <FieldArray name="additionalSeriesEvents">
                {(arrayHelpers) => (
                  <fieldset>
                    {values.additionalSeriesEvents.length > 0 && <h3 className={styles.heading}>Additional Dates</h3>}
                    {values.additionalSeriesEvents.map((e, index) => (
                      <div className={styles.when} key={index}>
                        <div className={styles.additionalDatePicker}>
                          <SingleDatepicker
                            selectedDay={e.startDate}
                            defaultMonth={lastDate}
                            placeholder="Date"
                            onChange={(v) => {
                              setFieldValue(`additionalSeriesEvents.${index}.startDate`, v)
                              setLastDate(v)
                            }}
                            onBlur={() => setFieldTouched(`additionalSeriesEvents.${index}.startDate`, true)}
                            start={initialMonth}
                            isInput
                          />
                          <ErrorMessage
                            component="div"
                            name={`additionalSeriesEvents.${index}.startDate`}
                            className={styles.error}
                          />
                        </div>
                        <div>
                          <Timepicker
                            placeholder="Start Time"
                            value={e.startTime}
                            onChange={(v) => setFieldValue(`additionalSeriesEvents.${index}.startTime`, v)}
                            onBlur={() => setFieldTouched(`additionalSeriesEvents.${index}.startTime`, true)}
                          />
                          <ErrorMessage
                            component="div"
                            name={`additionalSeriesEvents.${index}.startTime`}
                            className={styles.error}
                          />
                          <Timepicker
                            placeholder="End Time"
                            value={e.endTime}
                            onChange={(v) => setFieldValue(`additionalSeriesEvents.${index}.endTime`, v)}
                            onBlur={() => setFieldTouched(`additionalSeriesEvents.${index}.endTime`, true)}
                          />
                          <ErrorMessage
                            component="div"
                            name={`additionalSeriesEvents.${index}.endTime`}
                            className={styles.error}
                          />

                          <a
                            className={styles.deleteDate}
                            onClick={(e) => {
                              e.preventDefault()
                              arrayHelpers.remove(index)
                            }}
                            href="#deleteThisDate"
                          >
                            Remove this date
                          </a>
                        </div>
                      </div>
                    ))}
                    {status && status.additionalWhen && <div>{status.additionalWhen}</div>}
                    <Button onClick={() => arrayHelpers.push({ startDate: '', startTime: '', endTime: '' })}>
                      Add a date
                    </Button>
                  </fieldset>
                )}
              </FieldArray>
            </fieldset>
            <fieldset>
              <h3 className={styles.heading}>External Links</h3>
              <p className={styles.fieldLabel}>Upstream URL</p>
              <Field name="upstreamUrl" type="text" autoComplete="off" placeholder="Upstream URL" />
              <ErrorMessage component="div" name="upstreamUrl" className={styles.error} />
              <p className={styles.fieldLabel}>Ticket URL</p>
              <Field name="ticketUrl" type="text" autoComplete="off" placeholder="Ticket URL" />
              <ErrorMessage component="div" name="ticketUrl" className={styles.error} />
            </fieldset>
            <fieldset>
              <h3 className={styles.heading}>Contact Information</h3>
              <p className={styles.fieldLabel}>Name</p>
              <Field name="contact.name" type="text" autoComplete="off" placeholder="Name" />
              <ErrorMessage component="div" name="contact.name" className={styles.error} />
              <p className={styles.fieldLabel}>Phone</p>
              <Field name="contact.phone" type="text" autoComplete="off" placeholder="Phone" />
              <ErrorMessage component="div" name="contact.phone" className={styles.error} />
              <p className={styles.fieldLabel}>Email</p>
              <Field name="contact.email" type="text" autoComplete="off" placeholder="Email" />
              <ErrorMessage component="div" name="contact.email" className={styles.error} />
              <p className={styles.fieldLabel}>Organization Name</p>
              <Field name="contact.orgName" type="text" autoComplete="off" placeholder="Organization Name" />
              <ErrorMessage component="div" name="contact.orgName" className={styles.error} />
            </fieldset>
            <fieldset>
              <label className={styles.published}>
                <Field type="checkbox" name="published" checked={values.published} />
                Published
              </label>
              <ErrorMessage component="div" name="published" className={styles.error} />
            </fieldset>
            {useRecaptcha && (
              <fieldset className={styles.recaptcha}>
                <div ref={recaptchaElement} />
              </fieldset>
            )}
            {status && status.msg && <div className={styles.error}>{status.msg}</div>}
            <fieldset className={styles.submit}>
              <Button
                onClick={() => {
                  setPromote(false)
                  submitForm()
                }}
                disabled={!enableSubmit}
              >
                {event.id ? 'Save' : 'Add'} Event
              </Button>
              <Button
                onClick={() => {
                  setPromote(true)
                  submitForm()
                }}
                disabled={!enableSubmit}
              >
                {event.id ? 'Save' : 'Add'} and Promote Event
              </Button>
            </fieldset>
          </Form>
        )
      }}
    </Formik>
  )
}
