import React from 'react'
import Dropdown, { Opener, Overlay } from '../Dropdown'

import { Link } from '../Link'
import EventCard from './EventCard'
import styles from './FeaturedDailyEvents.module.css'

export default ({ events }) => {
  const hasEvents = !!events?.length
  return (
    <div className={styles.root}>
      <div className={styles.header}>
        <div className={styles.title}>{hasEvents ? 'Featured Events' : 'Want your event featured here?'}</div>
        {hasEvents ? (
          <div>
            <Dropdown>
              <Opener>
                {({ isOpen, toggle }) => {
                  return (
                    <a
                      href="#feature"
                      onClick={(e) => {
                        e.preventDefault()
                        toggle()
                      }}
                    >
                      Want your event here?
                    </a>
                  )
                }}
              </Opener>
              <Overlay position="right">
                {({ close }) => {
                  return (
                    <div className={styles.dropdownmessage}>
                      <p>
                        If your event is in the system, search for the event and promote the event from the event
                        details page.
                      </p>
                      <p>
                        If your event is not in the system, <Link to="/create/event">click here to add your event</Link>
                        . Upon adding your event, you can promote the event from the event details page.
                      </p>
                    </div>
                  )
                }}
              </Overlay>
            </Dropdown>
          </div>
        ) : null}
      </div>
      {hasEvents ? (
        <div className={styles.container}>
          <div>{events?.length ? events.map((e, idx) => <EventCard key={idx} {...e} />) : null}</div>
        </div>
      ) : (
        <div className={styles.message}>
          <p>If your event is in the system, search for the event and promote the event from the event details page.</p>
          <p>
            If your event is not in the system, <Link to="/create/event">click here to add your event</Link>. Upon
            adding your event, you can promote the event from the event details page.
          </p>
        </div>
      )}
    </div>
  )
}
