import React, { useContext } from 'react'
import { Helmet } from 'react-helmet-async'
import { Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'

import { TrackPageView } from '../analytics'
import { AdminSiteContext } from '../AdminSiteContext'
import AdminSiteSummaryCard from '../AdminSiteSummaryCard'
import DashboardUGC from '../DashboardUGC'
import DashboardUpsells from '../DashboardUpsells'
import DashboardTraffic from '../DashboardTraffic'

const useStyles = makeStyles({
  widgets: {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr 1fr',
    gridColumnGap: '2em',
    gridRowGap: '2em',
  },
})

export default () => {
  const classes = useStyles()
  const { name, canonicalDomain, siteUrl, otherDomains } = useContext(AdminSiteContext)
  return (
    <div>
      <Helmet>
        <title>Dashboard - Vyb</title>
      </Helmet>
      <TrackPageView title="Dashboard" />
      <Typography component="h1" variant="h2">
        Dashboard
      </Typography>
      <div className={classes.widgets}>
        <AdminSiteSummaryCard {...{ name, canonicalDomain, siteUrl, otherDomains }} />
        <DashboardUGC />
        <DashboardUpsells />
        <DashboardTraffic />
      </div>
    </div>
  )
}
