import React, { useContext } from 'react'
import { Helmet } from 'react-helmet-async'

import styles from './StaticPage.module.css'
import { SiteContext } from '../SiteContext'
import { TrackPageView } from '../analytics'
import Breadcrumbs from '../Breadcrumbs'

export default ({ title, children, breadcrumbs }) => {
  const { site } = useContext(SiteContext)
  return (
    <div className={styles.root}>
      <Helmet>
        <title>
          {title} - {site.name}
        </title>
      </Helmet>
      <TrackPageView title={title} />
      <Breadcrumbs breadcrumbs={breadcrumbs} />
      <section>{children}</section>
    </div>
  )
}
