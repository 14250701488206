import React, { useContext, useEffect, useRef, useState } from 'react'
import { Map, Marker } from 'react-leaflet'
import L from 'leaflet'
import moment from 'moment-timezone'

import styles from './EventDetail.module.css'
import { AdminUrlContext } from './contexts'
import { AuthContext } from './AuthContext'
import { SiteContext } from './SiteContext'
import { ImageServiceContext } from './ImageServiceContext'
import CustomTileLayer from './CustomTileLayer'
import { Link } from './Link'
import Image from './Image'
import Button from './components/Button'
import DateTimeRange from './components/DateTimeRange'
import { NewFetchError } from './Fetch'
import A from './A'
import noImage from './components/no_image_avail.png'
import CategoryPicker from './components/CategoryPicker'

const icon = L.icon({ iconUrl: '/marker-icon.png' })
const summarizeUrl = (url) => new URL(url).hostname.split('.').reverse().slice(0, 2).reverse().join('.')

export default ({
  id,
  createdBy,
  createTime,
  updateTime,
  feedData,
  published,
  tags: origTags,
  title,
  upstreamUrl,
  affiliateUrl,
  ticketUrl,
  start,
  end,
  noStart,
  noEnd,
  description,
  imageChecksum,
  venue,
  categories,
  categoryRegistry,
  contact,
  series,
  status,
  quarantine,
  picked: origPicked,
  excludedSites,
  featuredVenues,
  allCategories,
  categoriesLocked: origCategoriesLocked,
}) => {
  const { getIdToken, roles, userId } = useContext(AuthContext)
  const { site } = useContext(SiteContext)
  const { adminUrl } = useContext(AdminUrlContext)
  const { imageURL } = useContext(ImageServiceContext)
  const siteIdPrefix = `${site.id}:`
  const siteIdPrefixLen = siteIdPrefix.length
  const [tags, setTags] = useState(
    origTags.filter((t) => t.indexOf(siteIdPrefix) === 0).map((t) => t.slice(siteIdPrefixLen))
  )
  const [picked, setPicked] = useState(origPicked)
  const [picking, setPicking] = useState(false)
  const handlePicked = async (e) => {
    if (picking) return
    setPicking(true)
    try {
      const idToken = await getIdToken()
      const resp = await fetch(`/api/event/picked?id=${encodeURIComponent(id)}&v=${!picked}`, {
        method: 'post',
        headers: {
          authorization: `Bearer ${idToken}`,
        },
      })
      if (!resp.ok) {
        throw await NewFetchError(resp)
      }
      setPicked(!picked)
    } finally {
      setPicking(false)
    }
  }
  const [hiddenOnSite, setHiddenOnSite] = useState((excludedSites || []).includes(site.id))
  const [hiding, setHiding] = useState(false)
  const handleHide = async (e) => {
    if (hiding) return
    setHiding(true)
    try {
      const idToken = await getIdToken()
      const resp = await fetch(`/api/event/hide?id=${encodeURIComponent(id)}&v=${!hiddenOnSite}`, {
        method: 'post',
        headers: {
          authorization: `Bearer ${idToken}`,
        },
      })
      if (!resp.ok) {
        throw await NewFetchError(resp)
      }
      setHiddenOnSite(!hiddenOnSite)
    } finally {
      setHiding(false)
    }
  }
  const [categoriesOverride, setCategoriesOverride] = useState(categories)
  const [categoriesLocked, setCategoriesLocked] = useState(origCategoriesLocked)
  const [savingCatories, setSavingCatories] = useState(false)
  const handleCategoriesChanged = async () => {
    if (savingCatories) return
    setSavingCatories(true)
    try {
      const idToken = await getIdToken()
      const resp = await fetch(`/api/event/categories?id=${encodeURIComponent(id)}`, {
        method: 'post',
        headers: {
          authorization: `Bearer ${idToken}`,
        },
        body: JSON.stringify({
          categories: categoriesOverride,
        }),
      })
      if (!resp.ok) {
        throw await NewFetchError(resp)
      }
      setCategoriesLocked(true)
    } finally {
      setSavingCatories(false)
    }
  }

  const [savingTags, setSavingTags] = useState(false)
  const tagInputRef = useRef(null)
  const saveTags = async (tags) => {
    if (savingTags) return
    setSavingTags(true)
    try {
      const idToken = await getIdToken()
      const resp = await fetch(`/api/event/tags?id=${encodeURIComponent(id)}`, {
        method: 'post',
        headers: {
          authorization: `Bearer ${idToken}`,
        },
        body: JSON.stringify({
          tags,
        }),
      })
      if (!resp.ok) {
        throw await NewFetchError(resp)
      }
    } finally {
      setSavingTags(false)
    }
  }

  useEffect(() => {
    setHiddenOnSite((excludedSites || []).includes(site.id))
    setPicked(origPicked)
  }, [id, excludedSites, origPicked, site.id])

  const isSuperAdmin = roles.includes('superadmin')
  const isAdmin = isSuperAdmin || roles.includes(`siteadmin:${site.id}`)
  const isOwner = userId === createdBy
  const isUgc = !feedData
  const feedType = isUgc ? 'UGC' : feedData?.feed?.type
  const feedId = feedData?.feed?.id
  const featuredVenue = featuredVenues && featuredVenues.find((fv) => fv.siteId === site.id)
  const defaultImageUrl = site.defaultEventImageChecksum
    ? imageURL({ size: '800,', checksum: site.defaultEventImageChecksum })
    : noImage

  return (
    <div className={styles.root}>
      <h1 className={styles.title}>
        {quarantine && (
          <div className={styles.quarantine}>
            This event has been placed in quarantine by the platform administrators.
          </div>
        )}
        {hiddenOnSite && <div className={styles.hidden}>This event is hidden by the site administrators.</div>}
        {!published && (isAdmin || isOwner) && <div className={styles.unpublished}>This event is not published.</div>}
        {status === 'cancelled' && <div className={styles.cancelled}>This event has been cancelled.</div>}
        {status === 'postponed' && <div className={styles.postponed}>This event has been postponed.</div>}
        <div>{title}</div>
      </h1>
      {imageChecksum ? (
        <Image className={styles.image} size="800," checksum={imageChecksum} alt={title} />
      ) : (
        <img className={styles.image} alt={title} src={defaultImageUrl} />
      )}
      <div className={styles.col}>
        {moment(start).isAfter(moment()) && (
          <Button className={styles.promote} to={`/promote/featured-daily-event/${encodeURIComponent(id)}`}>
            Promote {isOwner ? 'My' : ''} Event
          </Button>
        )}
        {(isAdmin || isOwner) && isUgc && (
          <Button className={styles.editMyEvent} to={`/edit/event/${encodeURIComponent(id)}`}>
            {isOwner ? 'Edit My Event' : 'Edit Event'}
          </Button>
        )}
        {isAdmin && !isUgc && (
          <div>
            <CategoryPicker
              value={categoriesOverride}
              onChange={setCategoriesOverride}
              onBlur={handleCategoriesChanged}
              multiple
              categories={allCategories}
              placeholder="Category"
            />
            {categoriesLocked ? '(manually set)' : ''}
            {savingCatories ? ' saving ...' : ''}
          </div>
        )}
        {isAdmin && typeof picked !== 'undefined' && (
          <div className={styles.togglePicked}>
            <label>
              <input type="checkbox" disabled={picking} checked={picked} onChange={handlePicked} /> Picked Event (admin)
              {picking ? ' ...' : ''}
            </label>
          </div>
        )}
        {isAdmin && (
          <div className={styles.toggleHidden}>
            <label>
              <input type="checkbox" disabled={hiding} checked={hiddenOnSite} onChange={handleHide} /> Hidden Event
              (admin)
              {hiding ? ' ...' : ''}
            </label>
          </div>
        )}
        {isAdmin && (
          <div>
            Tags:{' '}
            <ul>
              {tags.map((t, idx) => (
                <li key={idx}>
                  {t} (
                  <a
                    href="#remove_tag"
                    onClick={(e) => {
                      e.preventDefault()
                      const updated = tags.filter((_, i) => i !== idx)
                      setTags(updated)
                      saveTags(updated)
                    }}
                  >
                    remove
                  </a>
                  )
                </li>
              ))}
              <li>
                <input ref={tagInputRef} placeholder="new tag" />
                <button
                  onClick={(e) => {
                    const t = tagInputRef.current.value
                    if (t) {
                      if (!tags.includes(t)) {
                        const updated = [...tags, t]
                        setTags(updated)
                        saveTags(updated)
                      }
                      tagInputRef.current.value = ''
                    }
                  }}
                >
                  Add
                </button>
              </li>
            </ul>
            {savingTags ? '...' : ''}
          </div>
        )}
        {isAdmin && (
          <div className={styles.meta}>
            {isUgc ? (
              <>
                Feed Type: UGC{' '}
                <a
                  href={`${adminUrl}/ugc?site=${encodeURIComponent(site.id)}&eventId=${encodeURIComponent(id)}`}
                  target="_blank"
                  rel="noreferrer"
                >
                  (details...)
                </a>
              </>
            ) : (
              <>Feed type: {feedType}</>
            )}

            {createTime && (
              <>
                <br />
                {moment(createTime).tz(site.timezone).format('[Created] dddd MMMM Do, YYYY [at] h:mm a z')}
              </>
            )}
            {updateTime && (
              <>
                <br />
                {moment(updateTime).tz(site.timezone).format('[Updated] dddd MMMM Do, YYYY [at] h:mm a z')}
              </>
            )}
          </div>
        )}
        {isSuperAdmin && feedId && feedType === 'scrape' && (
          <div className={styles.meta}>
            <a
              href={`${adminUrl}/scrape/edit/${encodeURI(feedId)}?site=${encodeURIComponent(site.id)}`}
              target="_blank"
              rel="noreferrer"
            >
              Scrape...
            </a>{' '}
            (admin)
          </div>
        )}
        <div className={styles.meta}>
          <table>
            <tbody>
              {featuredVenue ? (
                <tr>
                  <th>Where</th>
                  <td>
                    <Link to={`/featuredvenue/${featuredVenue.uid}`}>{featuredVenue.name}</Link>
                  </td>
                </tr>
              ) : venue ? (
                <tr>
                  <th>Where</th>
                  <td>
                    <Link to={`/venue/${venue.id}`}>{venue.name || venue.address}</Link>
                  </td>
                </tr>
              ) : null}
              <tr>
                <th>When</th>
                <td>
                  <DateTimeRange start={start} end={end} noStart={noStart} noEnd={noEnd} />
                </td>
              </tr>
              {categories && categories.length ? (
                <tr>
                  <th>What</th>
                  <td>
                    {categories.map((v) => (
                      <Link key={v} className={styles.category} to={`/category/${encodeURIComponent(v)}`}>
                        {categoryRegistry[v] ? categoryRegistry[v].label : v}
                      </Link>
                    ))}
                  </td>
                </tr>
              ) : null}
              {upstreamUrl ? (
                <tr>
                  <th>Link</th>
                  <td>
                    <A href={upstreamUrl} rel="nofollow">
                      {summarizeUrl(upstreamUrl)}
                    </A>
                  </td>
                </tr>
              ) : null}
              {contact && (contact.name || contact.phone || contact.email || contact.orgName) ? (
                <tr>
                  <th>Contact</th>
                  <td>
                    {contact.name && <div>{contact.name}</div>}
                    {contact.phone && (
                      <div>
                        <A href={`tel:${contact.phone}`}>{contact.phone}</A>
                      </div>
                    )}
                    {contact.email && (
                      <div>
                        <A href={`mailto:${contact.email}`}>{contact.email}</A>
                      </div>
                    )}
                    {contact.orgName && <div>{contact.orgName}</div>}
                  </td>
                </tr>
              ) : null}
            </tbody>
          </table>
        </div>
        {affiliateUrl && (
          <Button className={styles.buy} href={affiliateUrl}>
            Buy Tickets
          </Button>
        )}
        {ticketUrl ? (
          <Button className={styles.buy} href={ticketUrl}>
            Buy Tickets
          </Button>
        ) : null}
      </div>
      {description && /(<[^<>]+>|&[#\w]+;)/.test(description) ? (
        <div className={styles.description} dangerouslySetInnerHTML={{ __html: description }} />
      ) : (
        <div className={styles.description}>
          {description &&
            description
              .split('\n')
              .map((v) => v.trim())
              .filter((v) => v !== '')
              .map((v, i) => <p key={i}>{v}</p>)}
        </div>
      )}
      <div className={styles.moreInSeries}>
        {series && series.length > 0 && (
          <>
            <h1>More in this Series</h1>
            {series.map(
              ({ id, start, noStart, excludedOnSite }) =>
                (isAdmin || !excludedOnSite) && (
                  <div key={id} className={styles.series}>
                    <Link to={`/event/${id}`}>
                      {noStart
                        ? moment(start).tz(site.timezone).format('ddd, MMM Do')
                        : moment(start).tz(site.timezone).format('ddd, MMM Do, h:mm a')}
                      {isAdmin && excludedOnSite && ' (hidden)'}
                    </Link>
                  </div>
                )
            )}
          </>
        )}
        {series && series.length === 0 && <h2>No future events in this series</h2>}
      </div>
      <Map className={styles.map} center={[venue.latitude, venue.longitude]} zoom={13}>
        <CustomTileLayer />
        <Marker position={[venue.latitude, venue.longitude]} icon={icon} />
      </Map>
    </div>
  )
}
