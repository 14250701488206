import React, { useContext } from 'react'

import styles from './FeaturedVenueCard.module.css'
import { ImageServiceContext } from '../ImageServiceContext'
import { Link } from '../Link'

export default ({ idx, featuredVenue, curatedVenue }) => {
  const { imageURL } = useContext(ImageServiceContext)
  const { uid } = featuredVenue
  const { name, address, secondaryImageChecksum } = curatedVenue
  return (
    <div className={styles.root}>
      <Link to={`/featuredvenue/${uid}`}>
        <div
          className={styles.image}
          style={{ backgroundImage: `url(${imageURL({ size: '510,', checksum: secondaryImageChecksum })})` }}
        />
      </Link>
      <div className={styles.meta}>
        <Link to={`/featuredvenue/${uid}`}>
          <div className={styles.name}>{name}</div>
          <div className={styles.address}>{(address || '').replace(/,.*$/, '')}</div>
        </Link>
      </div>
    </div>
  )
}
