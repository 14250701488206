import React, { useContext, useEffect, useState } from 'react'
import moment from 'moment'

import styles from './PromoteFeaturedDailyEvents.module.css'
import InventoryDatepicker from './InventoryDatepicker'
import ShoppingCart from './ShoppingCart'
import CaptureCreditCard from './CaptureCreditCard'
import { AuthContext } from '../AuthContext'
import { SiteContext } from '../SiteContext'
import Button from './Button'

const formatDay = (day) => moment(day).format('MMMM Do, YYYY')

const LineItem = ({ day }) => <div className={styles.lineItem}>{formatDay(day)}</div>

const RejectedDays = ({ days }) => (
  <div className={styles.rejectedDays}>
    Your cart has changed. The following days are no longer available for promotion:
    <ul>{days && days.map((day) => <li key={day}>{formatDay(day)}</li>)}</ul>
  </div>
)

export default React.forwardRef(
  (
    {
      event,
      fetchInventory,
      adminFeatureEvent,
      createInvoice,
      payInvoice,
      settleInvoice,
      cancelInvoice,
      createStripeTokenDecorator,
      initialMonth,
      initialDays = [],
      initialName,
    },
    ref
  ) => {
    const { roles } = useContext(AuthContext)
    const { site } = useContext(SiteContext)
    const isSuperAdmin = roles.includes('superadmin')
    const isAdmin = isSuperAdmin || roles.includes(`siteadmin:${site.id}`)
    const [days, setDays] = useState(initialDays)
    const [inventory, setInventory] = useState(null)
    const [rejectedDays, setRejectedDays] = useState([])
    const [message, setMessage] = useState(null)
    const [busy, setBusy] = useState(false)

    useEffect(
      () => {
        ;(async () => {
          if (!fetchInventory) {
            return
          }
          updateInventory(event.id)
        })()
      },
      // TODO There is a real cyclic dependency bug here, for now just hide the warning so we can lint the rest
      // of the project.
      // eslint-disable-next-line react-hooks/exhaustive-deps
      [event.id, fetchInventory]
    )

    const updateInventory = async (eventId) => {
      const inv = await fetchInventory(eventId)
      if (inv.pastEvent) {
        setInventory(null)
        setDays([])
        return
      }
      const getOverride = (day) => {
        return inv.overrides && inv.overrides[day] ? inv.overrides[day] : 'available'
      }
      setDays(days.filter((v) => getOverride(v) === 'available'))
      setRejectedDays(days.filter((v) => getOverride(v) !== 'available'))
      setInventory(inv)
    }

    const handleSubmit = async (next) => {
      if (days.length < 1) {
        throw new Error('No days selected')
      }
      const { invoiceId, totalCents } = await createInvoice(event.id, days)
      if (!invoiceId) {
        await updateInventory(event.id)
        return
      }
      let shouldSettle = false
      try {
        if (days.length * inventory.rate !== totalCents) {
          return { error: { message: 'Something went wrong.' } }
        }
        const createStripeToken = createStripeTokenDecorator ? createStripeTokenDecorator(next) : next
        const { token, error } = await createStripeToken()
        if (error) {
          return { error }
        }
        const resp = await payInvoice(invoiceId, token.id)
        if (resp && resp.error) {
          return { error: resp.error }
        }
        shouldSettle = true
      } finally {
        if (!shouldSettle) {
          try {
            await cancelInvoice(invoiceId)
          } catch {
            // don't mask previous error/exception
          }
        }
      }
      await settleInvoice(invoiceId)
      return
    }
    return (
      <div className={styles.root}>
        {inventory && (
          <>
            <h2 className={styles.heading}>Select Days</h2>
            <InventoryDatepicker
              start={initialMonth}
              min={inventory.min}
              max={inventory.max}
              selectedDays={days}
              overrides={inventory.overrides}
              onChange={setDays}
            />
            <h2 className={styles.heading}>Shopping Cart</h2>
            <ShoppingCart
              rows={days.map((d) => ({ props: { day: d }, price: inventory.rate }))}
              propsKey="day"
              children={LineItem}
            />
            {rejectedDays && rejectedDays.length > 0 && <RejectedDays days={rejectedDays} />}
            {isAdmin ? (
              <Button
                disabled={busy}
                onClick={async () => {
                  setBusy(true)
                  setMessage('')
                  try {
                    if (days.length < 1) {
                      setMessage('No days selected')
                      return
                    }
                    await adminFeatureEvent(event.id, days)
                    const inv = await fetchInventory(event.id)
                    if (inv.pastEvent) {
                      setInventory(null)
                      setDays([])
                      return
                    }
                    const getOverride = (day) => {
                      return inv.overrides && inv.overrides[day] ? inv.overrides[day] : 'available'
                    }
                    setDays(days.filter((v) => getOverride(v) === 'available'))
                    setInventory(inv)
                  } finally {
                    setBusy(false)
                  }
                }}
              >
                Administratively Promote This Event (no charge)
              </Button>
            ) : null}
            {message ? <div>{message}</div> : null}
            <h2 className={styles.heading}>Payment Details</h2>
            <CaptureCreditCard ref={ref} disabled={days.length < 1} onSubmit={handleSubmit} initialName={initialName} />
          </>
        )}
      </div>
    )
  }
)
