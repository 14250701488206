import { NewFetchError } from './Fetch'

export const createUploadImageHandler = (getIdToken) => async (f, checksum) => {
  const idToken = await getIdToken()
  let resp = await fetch(`/api/images/create?checksum=${encodeURIComponent(checksum)}`, {
    method: 'post',
    headers: {
      authorization: `Bearer ${idToken}`,
    },
  })
  if (!resp.ok) {
    throw await NewFetchError(resp)
  }
  const { needsUpload } = await resp.json()
  if (!needsUpload) {
    return
  }
  resp = await fetch(`/api/images/upload?checksum=${encodeURIComponent(checksum)}`, {
    method: 'post',
    body: f,
    headers: {
      'Content-Type': 'application/octet-stream',
      authorization: `Bearer ${idToken}`,
    },
  })
  if (!resp.ok) {
    throw await NewFetchError(resp)
  }
}
