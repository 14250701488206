import React, { useContext, useState, useEffect } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import {
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '@material-ui/core'
import { RemoveCircle, ArrowUpward, ArrowDownward } from '@material-ui/icons'
import { Map, Marker } from 'react-leaflet'
import L from 'leaflet'
import ReactQuill from 'react-quill'
import 'react-quill/dist/quill.snow.css'

import CustomTileLayer from './CustomTileLayer'
import { AdminSiteContext } from './AdminSiteContext'
import { AuthContext } from './AuthContext'
import { ImageServiceContext } from './ImageServiceContext'
import { NewFetchError } from './Fetch'
import ImageUpload from './components/ImageUpload'
import { createUploadImageHandler } from './api_utilities'

const icon = L.icon({ iconUrl: '/marker-icon.png' })

const useDialogStyles = makeStyles({
  map: {
    width: '100%',
    height: '15em',
  },
  autocompleteContainer: {
    display: 'flex',
  },
  autocomplete: {
    flexGrow: 1,
  },
  iconContainer: {
    display: 'flex',
    flexDirection: 'column',
    margin: 'auto 0',
  },
})

const CreateEditVenueDialog = ({ open, closeDialog, addFeaturedVenue, curatedVenueUid, featuredVenueUid }) => {
  const classes = useDialogStyles()
  const { getIdToken } = useContext(AuthContext)
  const { siteId } = useContext(AdminSiteContext)
  const { imageURL } = useContext(ImageServiceContext)
  const [curatedVenue, setCuratedVenue] = useState()
  const [featured, setFeatured] = useState(false)
  const [additionalMedia, setAdditionalMedia] = useState([])
  const [marketingMessage, setMarketingMessage] = useState('')
  const [ready, setReady] = useState(false)
  const [submitting, setSubmitting] = useState()

  useEffect(() => {
    if (!curatedVenueUid) {
      return
    }
    setReady(false)
    ;(async () => {
      const idToken = await getIdToken()
      if (featuredVenueUid) {
        const resp = await fetch(`/api/featuredvenue?uid=${encodeURIComponent(featuredVenueUid)}`, {
          headers: {
            authorization: `Bearer ${idToken}`,
          },
        })
        if (!resp.ok) {
          throw await NewFetchError(resp)
        }
        const data = await resp.json()
        setCuratedVenue(data.curatedVenue)
        setFeatured(data.featuredVenue.featured)
        setAdditionalMedia(data.featuredVenue.additionalMedia)
        setMarketingMessage(data.featuredVenue.marketingMessage)
      } else {
        const resp = await fetch(`/api/curatedvenue?uid=${encodeURIComponent(curatedVenueUid)}`, {
          headers: {
            authorization: `Bearer ${idToken}`,
          },
        })
        if (!resp.ok) {
          throw await NewFetchError(resp)
        }
        const data = await resp.json()
        setCuratedVenue(data)
      }
      setReady(true)
    })()
  }, [getIdToken, curatedVenueUid, featuredVenueUid])

  if (featuredVenueUid && !ready) {
    return null
  }

  const handleSubmit = () => {
    setSubmitting(true)
    ;(async () => {
      const error = await addFeaturedVenue({
        uid: featuredVenueUid,
        curatedVenueUid,
        featured,
        siteId,
        marketingMessage,
        additionalMedia: additionalMedia.filter((v) => !!v),
      })
      if (error) {
        setSubmitting(false)
        return
      }
      closeDialog()
    })()
  }

  const position =
    Number.isFinite(Number.parseFloat(curatedVenue?.latitude)) &&
    Number.isFinite(Number.parseFloat(curatedVenue.longitude))
      ? [Number.parseFloat(curatedVenue?.latitude), Number.parseFloat(curatedVenue?.longitude)]
      : undefined

  return (
    <Dialog open={open} aria-labelledby="form-dialog-title">
      <DialogTitle id="form-dialog-title">{featuredVenueUid ? 'Edit' : 'Add'} Featured Venue</DialogTitle>
      <DialogContent>
        <Map className={classes.map} center={position} zoom={13}>
          <CustomTileLayer />
          {position && <Marker key={position} position={position} icon={icon} />}
        </Map>
        <div>{curatedVenue?.name}</div>
        <div>
          <label>
            Featured
            <Checkbox checked={featured} onChange={(e) => setFeatured(e.target.checked)} />
          </label>
        </div>
        <div>{curatedVenue?.address}</div>
        {curatedVenue?.primaryImageChecksum && (
          <>
            <label>Primany Image (wide)</label>
            <div>
              <img
                src={imageURL({ size: '370,', checksum: curatedVenue?.primaryImageChecksum })}
                alt="16:9 Main Promo"
              />
            </div>
          </>
        )}
        {curatedVenue?.secondaryImageChecksum && (
          <>
            <label>Secondary Image (teaser)</label>
            <div>
              <img
                src={imageURL({ size: '370,', checksum: curatedVenue?.secondaryImageChecksum })}
                alt="Square Tile Promo"
              />
            </div>
          </>
        )}
        <label>Additional Images (16:9)</label>
        {additionalMedia.map((sv, idx) => (
          <div key={idx} className={classes.autocompleteContainer}>
            <div className={classes.autocomplete}>
              <ImageUpload
                dropzoneContainerStyle={{ aspectRatio: '16/9', width: '520px' }}
                value={additionalMedia[idx]}
                onChange={(v) => {
                  const c = [...additionalMedia]
                  c[idx] = v
                  additionalMedia(c)
                }}
                onUpload={createUploadImageHandler(getIdToken)}
              />
            </div>
            <div className={classes.iconContainer}>
              {idx !== 0 && (
                <div>
                  <ArrowUpward
                    onClick={() => {
                      const c = [...additionalMedia]
                      c[idx - 1] = additionalMedia[idx]
                      c[idx] = additionalMedia[idx - 1]
                      setAdditionalMedia(c)
                    }}
                  />
                </div>
              )}
              <div>
                <RemoveCircle
                  onClick={() => {
                    const c = [...additionalMedia]
                    c[idx] = null
                    setAdditionalMedia(c.filter((v) => !!v))
                  }}
                />
              </div>
              {idx !== additionalMedia.length - 1 && (
                <div>
                  <ArrowDownward
                    onClick={() => {
                      const c = [...additionalMedia]
                      c[idx + 1] = additionalMedia[idx]
                      c[idx] = additionalMedia[idx + 1]
                      setAdditionalMedia(c)
                    }}
                  />
                </div>
              )}
            </div>
          </div>
        ))}
        <ImageUpload
          value={null}
          dropzoneContainerStyle={{ aspectRatio: '16/9', width: '520px' }}
          onUpload={createUploadImageHandler(getIdToken)}
          onChange={(v) => v && setAdditionalMedia([...additionalMedia, v])}
        />
        <label>
          Marketing Message
          <ReactQuill
            theme="snow"
            defaultValue={marketingMessage}
            onChange={(content, _delta, _source, _editor) => setMarketingMessage(content)}
          />
        </label>
      </DialogContent>
      <DialogActions>
        <Button onClick={closeDialog} color="primary">
          Cancel
        </Button>
        <Button onClick={handleSubmit} color="primary" disabled={submitting}>
          {featuredVenueUid ? 'Update' : 'Add'}
        </Button>
      </DialogActions>
    </Dialog>
  )
}

const useStyles = makeStyles({
  root: {
    padding: '1em',
    marginBottom: '1em',
  },
  venueMeta: {
    display: 'inline-block',
    verticalAlign: 'middle',
  },
  name: {
    fontWeight: 'bold',
  },
})

export default () => {
  const classes = useStyles()
  const { getIdToken, roles } = useContext(AuthContext)
  const { siteId, siteName } = useContext(AdminSiteContext)
  const [featuredAndCuratedVenues, setFeaturedAndCuratedVenues] = useState(null)
  const [addingVenueKey, setAddingVenueKey] = useState(0)
  const [addingVenue, setAddingVenue] = useState(false)
  const [refreshKey, setRefreshKey] = useState(0)
  const [selectedFeaturedAndCuratedVenue, setSelectedFeaturedAndCuratedVenue] = useState()
  useEffect(() => {
    let cancel = false
    ;(async () => {
      const idToken = await getIdToken()
      const resp = await fetch(`/api/featuredandcuratedvenues?siteId=${encodeURIComponent(siteId)}`, {
        headers: {
          authorization: `Bearer ${idToken}`,
        },
      })
      if (!resp.ok) {
        throw await NewFetchError(resp)
      }
      const data = await resp.json()
      if (cancel) {
        return
      }
      setFeaturedAndCuratedVenues(data)
    })()
    return () => {
      cancel = true
    }
  }, [getIdToken, refreshKey, siteId])

  const openAddVenueDialog = ({ curatedVenue, featuredVenue }) => {
    setAddingVenueKey((k) => k + 1)
    setSelectedFeaturedAndCuratedVenue({ curatedVenue, featuredVenue })
    setAddingVenue(true)
  }

  const closeAddVenueDialog = () => {
    setAddingVenue(false)
  }

  const updateVenue = async (data) => {
    const vErrs = {}
    // TODO: validation
    if (Object.keys(vErrs).length > 0) {
      return vErrs
    }
    const idToken = await getIdToken()
    const resp = await fetch(`/api/featuredvenues/update`, {
      method: 'post',
      body: JSON.stringify(data),
      headers: {
        authorization: `Bearer ${idToken}`,
      },
    })
    if (!resp.ok) {
      if (resp.status === 400) {
        try {
          const data = await resp.json()
          if (data.type === 'validation_error') {
            return data.fields || { name: 'unknown error' }
          }
        } catch {} // Treat as generic error
      }
      throw await NewFetchError(resp)
    }
    setRefreshKey((k) => k + 1)
  }

  return (
    <Paper className={classes.root}>
      <CreateEditVenueDialog
        key={addingVenueKey}
        open={addingVenue}
        closeDialog={closeAddVenueDialog}
        addFeaturedVenue={updateVenue}
        featuredVenueUid={selectedFeaturedAndCuratedVenue?.featuredVenue?.uid}
        curatedVenueUid={selectedFeaturedAndCuratedVenue?.curatedVenue?.uid}
      />
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Featured Venue</TableCell>
            <TableCell />
          </TableRow>
        </TableHead>
        <TableBody>
          {featuredAndCuratedVenues
            ? featuredAndCuratedVenues.length === 0 && (
                <TableRow>
                  <TableCell>There are no featured venues for {siteName}.</TableCell>
                  <TableCell></TableCell>
                </TableRow>
              )
            : null}
          {featuredAndCuratedVenues
            ? featuredAndCuratedVenues.map(({ featuredVenue, curatedVenue }) => (
                <TableRow key={curatedVenue.uid}>
                  <TableCell>
                    <div className={classes.venueMeta}>
                      <div className={classes.name}>{curatedVenue.name}</div>
                      <div>{curatedVenue.address}</div>
                      <div>{featuredVenue?.featured ? 'featured' : 'not featured'}</div>
                    </div>
                  </TableCell>
                  <TableCell align="right">
                    {roles.includes('superadmin') || roles.includes(`siteadmin:${siteId}`) ? (
                      <Button
                        size="small"
                        onClick={() => openAddVenueDialog({ featuredVenue, curatedVenue })}
                        disabled={addingVenue}
                      >
                        Edit
                      </Button>
                    ) : null}
                  </TableCell>
                </TableRow>
              ))
            : null}
        </TableBody>
      </Table>
    </Paper>
  )
}
