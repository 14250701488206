import React, { useContext } from 'react'
import { Helmet } from 'react-helmet-async'
import { Typography } from '@material-ui/core'

import { TrackPageView } from '../analytics'
import { AdminSiteContext } from '../AdminSiteContext'
import AdminUGCDetails from '../AdminUGCDetails'

export default () => {
  const { siteId, siteName, siteTimezone } = useContext(AdminSiteContext)
  return (
    <div>
      <Helmet>
        <title>User Generated Content - {siteName}</title>
      </Helmet>
      <TrackPageView title="User Generated Content" />
      <Typography component="h1" variant="h2">
        User Generated Content ~ {siteName}
      </Typography>
      <AdminUGCDetails timezone={siteTimezone} siteId={siteId} />
    </div>
  )
}
