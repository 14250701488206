import React, { useContext } from 'react'
import { Helmet } from 'react-helmet-async'
import { Typography } from '@material-ui/core'

import { TrackPageView } from '../analytics'
import { AdminSiteContext } from '../AdminSiteContext'
import AdminUpsellRates from '../AdminUpsellRates'

export default () => {
  const { siteName } = useContext(AdminSiteContext)
  return (
    <div>
      <Helmet>
        <title>Upsell Rates - {siteName}</title>
      </Helmet>
      <TrackPageView title="Upsell Rates" />
      <Typography component="h1" variant="h2">
        Upsell Rates ~ {siteName}
      </Typography>
      <AdminUpsellRates />
    </div>
  )
}
