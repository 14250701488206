import React, { useContext } from 'react'
import { TileLayer } from 'react-leaflet'

import { AppContext } from './AppContext'

export default () => {
  const { mapboxAPIKey } = useContext(AppContext)
  return (
    <TileLayer
      url="https://api.mapbox.com/styles/v1/{mapStyle}/tiles/{z}/{x}/{y}?access_token={accessToken}"
      mapStyle="pointslocal/cjx3l4ak96cir1cpfx8emw3nh"
      accessToken={mapboxAPIKey}
    />
  )
}
