import React, { useState, useContext, useRef } from 'react'

import styles from './Dropdown.module.css'
import useOutsideAlerter from './useOutsideAlerter'

const DropdownContext = React.createContext()

export default ({ initialIsOpen = false, children, className = '', ...props }) => {
  const [isOpen, setIsOpen] = useState(initialIsOpen)
  return (
    <div className={`${styles.root} ${className}`} {...props}>
      <DropdownContext.Provider
        value={{
          isOpen,
          open: () => {
            setIsOpen(true)
          },
          close: () => {
            setIsOpen(false)
          },
          toggle: () => {
            setIsOpen(!isOpen)
          },
        }}
      >
        {children}
      </DropdownContext.Provider>
    </div>
  )
}

export const Opener = ({ children }) => {
  const context = useContext(DropdownContext)
  return children ? children(context) : null
}

const positions = {
  inline: styles.positionInline,
  left: styles.positionLeft,
  right: styles.positionRight,
}
export const Overlay = ({ children, className = '', position = 'inline', ...props }) => {
  const context = useContext(DropdownContext)
  const { isOpen, close } = context
  const ref = useRef(null)
  useOutsideAlerter(ref, position !== 'inline' ? close : null)
  if (!isOpen) {
    return null
  }
  const positionClass = positions[position] || ''
  return (
    <div ref={ref} className={`${className} ${positionClass}`} {...props}>
      {children ? children(context) : null}
    </div>
  )
}
