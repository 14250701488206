import React, { useContext } from 'react'
import API from '../API'
import { Helmet } from 'react-helmet-async'

import styles from './CategoriesPage.module.css'
import { SiteContext } from '../SiteContext'
import { ImageServiceContext } from '../ImageServiceContext'
import { TrackPageView } from '../analytics'
import useAds from '../useAds'
import Ad from '../Ad'
import { Link } from '../Link'
import FeaturedDailyEvents from '../appcomponents/FeaturedDailyEvents'
import Breadcrumbs from '../Breadcrumbs'
import FeaturedVenues from '../appcomponents/FeaturedVenues'

const CategoriesPage = ({ categories, breadcrumbs }) => {
  const { site } = useContext(SiteContext)
  const { imageURL } = useContext(ImageServiceContext)
  useAds([{ id: 'div-gpt-ad-leaderboard-0', type: 'leaderboard' }])
  return (
    <div className={styles.root}>
      <Helmet>
        <title>Categories - {site.name}</title>
      </Helmet>
      <TrackPageView title="Categories" />
      <Breadcrumbs breadcrumbs={breadcrumbs} />
      <Ad id="div-gpt-ad-leaderboard-0" type="leaderboard" />
      <h2 className={styles.heading}>Categories</h2>
      <div className={styles.categories}>
        <div>
          {categories.map((c) => (
            <div key={c.id} className={styles.card}>
              <Link to={`/category/${encodeURIComponent(c.id)}`}>
                <div
                  className={styles.thumbnail}
                  style={{ backgroundImage: `url(${imageURL({ checksum: c.thumbnailImageChecksum, size: '500,' })})` }}
                />
                <div className={styles.meta}>
                  <div className={styles.name}>{c.name}</div>
                  <div className={styles.blurb}>{c.blurb}</div>
                </div>
              </Link>
            </div>
          ))}
        </div>
      </div>
      <FeaturedDailyEvents />
      <FeaturedVenues />
    </div>
  )
}

export default (props) => (
  <API input="/api/categories">
    {({ data }) => data && <CategoriesPage {...props} categories={data.topLevelCategories} />}
  </API>
)
