import React, { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import { getAuth, GoogleAuthProvider, signInWithRedirect, getRedirectResult } from 'firebase/auth'

import qs from './qs'
import { completeLoginAndRedirect } from './login_util'
import googleLogo from './branding/btn_google_light_normal_ios@3x.png'

import styles from './LoginWithGoogle.module.css'

export const GoogleSignIn = ({ firebaseApp, link, loginHint }) => {
  const { search } = useLocation()
  return (
    <div className={styles.root}>
      <a href={`/login/google${search}`}>
        <img src={googleLogo} alt="Google Logo" />
        <div>Sign in with Google</div>
      </a>
    </div>
  )
}

export const LoginWithGoogle = ({ firebaseApp }) => {
  const { search } = useLocation()
  const [ready, setReady] = useState(false)
  const [error, setError] = useState()

  useEffect(() => {
    const auth = getAuth(firebaseApp)
    const { redirect: redirectUrl } = qs.parse(search)
    ;(async () => {
      try {
        const r = await getRedirectResult(auth)
        if (!r) {
          // https://developers.google.com/identity/protocols/oauth2/openid-connect#authenticationuriparameters
          const provider = new GoogleAuthProvider()
          provider.setCustomParameters({ prompt: 'select_account' })
          await signInWithRedirect(auth, provider)
        } else {
          if (r.user.emailVerified) {
            await completeLoginAndRedirect(r.user, redirectUrl)
          } else {
            setError(`Please log in with an email or google. We don't trust your login yet.`)
            setReady(true)
          }
        }
      } catch (err) {
        setError(err.message ? err.message.replace(/^Firebase:\s?/, '') : 'We ran into a problem signing you in')
        setReady(true)
        console.error(err)
      }
    })()
  }, [firebaseApp, search])

  if (!ready) {
    return null
  }

  return (
    <div>
      <div>Login With Google</div>
      {error ? <div>{error}</div> : null}
    </div>
  )
}
