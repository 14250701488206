import React, { useContext, useEffect, useState } from 'react'
import { useLocation, useHistory } from 'react-router-dom'

import qs from './qs'
import { AuthContext } from './AuthContext'
import { LoginUrlContext } from './contexts'
import AdminNoAccessPage from './pages/AdminNoAccessPage'

export const AdminSiteContext = React.createContext()

export const AdminSiteProvider = ({ children }) => {
  const [ready, setReady] = useState()
  const [data, setData] = useState({})
  const location = useLocation()
  const history = useHistory()
  const { getIdToken } = useContext(AuthContext)
  const { loginUrl } = useContext(LoginUrlContext)

  const updateSiteId = (s) => {
    const params = qs.parse(location.search)
    params.site = s
    history.replace(`${location.pathname}${qs.stringify(params)}${location.hash}`)
  }

  useEffect(() => {
    let cancel = false
    ;(async () => {
      const idToken = await getIdToken()
      if (!idToken) {
        window.location = `${loginUrl}/login?redirect=${encodeURIComponent(window.location.href)}`
        return
      }
      const params = qs.parse(location.search)
      if (params.site && params.site === data?.siteId) {
        return
      }
      if (data?.siteId && !params.site) {
        params.site = data.siteId
        history.replace(`${location.pathname}${qs.stringify(params)}${location.hash}`)
        return
      }
      const resp = await fetch(`/api/siteaccess?site=${encodeURIComponent(params.site ? params.site : '')}`, {
        method: 'post',
        headers: {
          authorization: `Bearer ${idToken}`,
        },
      })
      if (cancel) {
        return
      }
      if (!resp.ok) {
        setData({})
        setReady(true)
        return
      }
      const d = await resp.json()
      setData(d)
      setReady(true)
      if (d.siteId) {
        params.site = d.siteId
        history.replace(`${location.pathname}${qs.stringify(params)}${location.hash}`)
      }
    })()
    return () => {
      cancel = true
    }
  }, [getIdToken, history, location.hash, location.pathname, location.search, loginUrl, data?.siteId])

  const refresh = async () => {
    const idToken = await getIdToken()
    if (!idToken) {
      window.location = `${loginUrl}/login?redirect=${encodeURIComponent(window.location.href)}`
      return
    }
    const params = qs.parse(location.search)
    const resp = await fetch(`/api/siteaccess?site=${encodeURIComponent(params.site ? params.site : '')}`, {
      method: 'post',
      headers: {
        authorization: `Bearer ${idToken}`,
      },
    })
    if (!resp.ok) {
      setData({})
      setReady(true)
      return
    }
    const d = await resp.json()
    setData(d)
    setReady(true)
  }

  if (!ready) {
    return null
  }
  if (!data?.siteId) {
    return <AdminNoAccessPage />
  }
  return (
    <AdminSiteContext.Provider value={{ ...data, setSiteId: updateSiteId, refresh }}>
      {children}
    </AdminSiteContext.Provider>
  )
}
