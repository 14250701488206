import qs from 'qs'

export default {
  stringify: (params) =>
    qs.stringify(params, {
      arrayFormat: 'repeat',
      addQueryPrefix: true,
      strictNullHandling: true,
    }),
  parse: (search) =>
    qs.parse(search, {
      ignoreQueryPrefix: true,
      parseArrays: false,
      strictNullHandling: true,
    }),
}
