import React from 'react'
import { Helmet } from 'react-helmet-async'
import { Typography } from '@material-ui/core'

import { TrackPageView } from '../analytics'
import AdminScrapes from '../AdminScrapes'

export default () => {
  return (
    <div>
      <Helmet>
        <title>Scrapes - Vyb</title>
      </Helmet>
      <TrackPageView title="Scrapes" />
      <Typography component="h1" variant="h2">
        Scrapes
      </Typography>
      <AdminScrapes />
    </div>
  )
}
