import React, { useContext, useState, useEffect } from 'react'
import {
  Avatar,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  Toolbar,
  Typography,
} from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'

import { AdminSiteContext } from './AdminSiteContext'
import { AuthContext } from './AuthContext'
import { NewFetchError } from './Fetch'

const useStyles = makeStyles({
  root: {
    padding: '1em',
    marginBottom: '1em',
  },
  title: {
    flex: '1 1 100%',
  },
  avatar: {
    display: 'inline-block',
    marginRight: '1em',
    verticalAlign: 'middle',
  },
  userMeta: {
    display: 'inline-block',
    verticalAlign: 'middle',
  },
  name: {
    fontWeight: 'bold',
  },
})

const AddUserDialog = ({ open, closeDialog, addUser }) => {
  const [email, setEmail] = useState('')
  const [submitting, setSubmitting] = useState()
  const [error, setError] = useState()
  const handleSubmit = () => {
    ;(async () => {
      setSubmitting(true)
      const error = await addUser(email)
      if (error) {
        setError(error)
        setSubmitting(false)
        return
      }
      closeDialog()
    })()
  }
  return (
    <Dialog open={open} onClose={closeDialog} aria-labelledby="form-dialog-title">
      <DialogTitle id="form-dialog-title">Add Administrator</DialogTitle>
      <DialogContent>
        <DialogContentText>
          To add another administrator for this site, please enter their email address here.
        </DialogContentText>
        <TextField
          autoFocus
          name="email"
          value={email}
          onChange={(e) => {
            setEmail(e.target.value)
          }}
          error={!!error}
          helperText={error}
          margin="dense"
          label="Email"
          autoComplete="off"
          fullWidth
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={closeDialog} color="primary">
          Cancel
        </Button>
        <Button onClick={handleSubmit} color="primary" disabled={submitting}>
          Add
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default () => {
  const classes = useStyles()
  const { siteId, siteName } = useContext(AdminSiteContext)
  const { getIdToken, userId } = useContext(AuthContext)
  const [users, setUsers] = useState(null)
  const [addingUser, setAddingUser] = useState()
  const [addingUserKey, setAddingUserKey] = useState(0)
  const [removingUsers, setRemovingUsers] = useState({})
  const [refreshKey, setRefreshKey] = useState(0)
  useEffect(() => {
    let cancel = false
    ;(async () => {
      const idToken = await getIdToken()
      const resp = await fetch(`/api/siteadmins?site=${encodeURIComponent(siteId)}`, {
        headers: {
          authorization: `Bearer ${idToken}`,
        },
      })
      if (!resp.ok) {
        throw await NewFetchError(resp)
      }
      const data = await resp.json()
      if (cancel) {
        return
      }
      setUsers(data.users)
    })()
    return () => {
      cancel = true
    }
  }, [siteId, getIdToken, refreshKey])
  const openAddUserDialog = () => {
    setAddingUserKey((k) => k + 1)
    setAddingUser(true)
  }
  const closeAddUserDialog = () => {
    setAddingUser(false)
  }
  const addUser = async (email) => {
    const idToken = await getIdToken()
    const resp = await fetch(
      `/api/siteadmins/add?site=${encodeURIComponent(siteId)}&email=${encodeURIComponent(email)}`,
      {
        method: 'post',
        headers: {
          authorization: `Bearer ${idToken}`,
        },
      }
    )
    if (!resp.ok) {
      if (resp.status === 400) {
        try {
          const data = await resp.json()
          if (data.type === 'validation_error') {
            return data.fields.email || 'unknown error'
          }
        } catch {} // Treat as generic error
      }
      throw await NewFetchError(resp)
    }
    setRefreshKey((k) => k + 1)
  }
  const removeUser = (u) => () => {
    ;(async () => {
      setRemovingUsers((users) => ({ ...users, [u]: true }))
      try {
        const idToken = await getIdToken()
        const resp = await fetch(
          `/api/siteadmins/remove?site=${encodeURIComponent(siteId)}&user=${encodeURIComponent(u)}`,
          {
            method: 'post',
            headers: {
              authorization: `Bearer ${idToken}`,
            },
          }
        )
        if (!resp.ok) {
          if (resp.status === 400) {
            try {
              const data = await resp.json()
              if (data.type === 'validation_error') {
                return data.fields.id || 'unknown error'
              }
            } catch {} // Treat as generic error
          }
          throw await NewFetchError(resp)
        }
        setRefreshKey((k) => k + 1)
      } finally {
        setRemovingUsers((users) => ({ ...users, [u]: undefined }))
      }
    })()
  }
  return (
    <Paper className={classes.root}>
      <Toolbar>
        <Typography component="h2" variant="h4" className={classes.title}>
          Administrators for {siteName}
        </Typography>
        <Button variant="contained" onClick={openAddUserDialog} disabled={addingUser}>
          Add
        </Button>
        <AddUserDialog key={addingUserKey} open={addingUser} closeDialog={closeAddUserDialog} addUser={addUser} />
      </Toolbar>
      {users && users.length === 0 && <Typography>The {siteName} site has no admin users.</Typography>}
      {users && users.length > 0 && (
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>User</TableCell>
              <TableCell></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {users.map((u) => (
              <TableRow key={u.id}>
                <TableCell>
                  <div className={classes.avatar}>
                    <Avatar src={u.picture} alt="avatar picture" />
                  </div>
                  <div className={classes.userMeta}>
                    <div className={classes.name}>{u.name}</div>
                    <div>{u.email}</div>
                    {u.superadmin ? <div>super admin</div> : null}
                  </div>
                </TableCell>
                <TableCell align="right">
                  {userId !== u.id && (
                    <Button size="small" onClick={removeUser(u.id)} disabled={removingUsers[u.id]}>
                      Remove
                    </Button>
                  )}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      )}
    </Paper>
  )
}
