import React, { useContext } from 'react'
import { Helmet } from 'react-helmet-async'
import { Typography } from '@material-ui/core'

import { TrackPageView } from '../analytics'
import AdminScrapeUpdate from '../AdminScrapeUpdate'
import API from '../API'
import { AdminSiteContext } from '../AdminSiteContext'

export default ({ id }) => {
  const { siteId } = useContext(AdminSiteContext)
  return (
    <div>
      <Helmet>
        <title>{id ? 'Edit' : 'Create'} Scrape - Vyb</title>
      </Helmet>
      <TrackPageView title={`${id ? 'Edit' : 'Create'} Scrape`} />
      <Typography component="h1" variant="h2">
        {`${id ? 'Edit' : 'Create'} Scrape`}
      </Typography>
      <API input={`/api/site?id=${encodeURIComponent(siteId)}`}>
        {({ data: site }) =>
          site && (
            <API input={`/api/sitesearchopts?id=${encodeURIComponent(siteId)}`}>
              {({ data: sitesearchopts }) =>
                sitesearchopts && <AdminScrapeUpdate id={id} categories={sitesearchopts.categories.children} />
              }
            </API>
          )
        }
      </API>
    </div>
  )
}
