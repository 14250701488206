import React from 'react'
import AsyncSelect from 'react-select/async'
import { components } from 'react-select'

import styles from './VenuePicker.module.css'

const SingleValue = (props) => {
  return (
    <components.SingleValue {...props}>
      {props.data.name && <div className={styles.name}>{props.data.name}</div>}
      <div className={styles.address}>{props.data.address}</div>
    </components.SingleValue>
  )
}

const Option = (props) => {
  return (
    <components.Option {...props}>
      {props.data.name && <div className={styles.name}>{props.data.name}</div>}
      <div className={styles.address}>{props.data.address}</div>
    </components.Option>
  )
}

export default ({ value, onChange, onBlur, getVenues, placeholder }) => (
  <AsyncSelect
    className={styles.root}
    value={value}
    onChange={onChange}
    onBlur={onBlur}
    loadOptions={getVenues}
    getOptionValue={(opt) => opt.id}
    components={{ Option, SingleValue }}
    isClearable={true}
    placeholder={placeholder}
  />
)
