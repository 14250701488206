import React from 'react'
import { useLocation } from 'react-router-dom'
import { MdNavigateBefore, MdNavigateNext } from 'react-icons/md'

import styles from './SearchResults.module.css'
import EventCard from './components/EventCard'
import API from './API'
import qs from './qs'
import { Link } from './Link'
import ServiceError from './ServiceError'
import ErrorBoundary from './ErrorBoundary'

const Page = ({ page, maxPage, params, children }) => {
  const disabled = page < 1 || page > maxPage
  const to = { ...params }
  if (!disabled) {
    to.page = page
  }
  return (
    <Link to={`/search${qs.stringify(to)}`} className={`${styles.page} ${disabled ? styles.disabled : ''}`}>
      {children}
    </Link>
  )
}

const Pagination = ({ currentPage, maxPage, params }) => {
  return (
    <div className={styles.pagination}>
      <Page page={currentPage - 1} maxPage={maxPage} params={params}>
        <MdNavigateBefore /> Previous
      </Page>
      <Page page={currentPage + 1} maxPage={maxPage} params={params}>
        Next <MdNavigateNext />
      </Page>
    </div>
  )
}

const getPage = (page, max) => {
  let p = parseInt(page)
  if (isNaN(p)) {
    p = 0
  }
  if (p < 1) {
    p = 1
  } else if (p > max) {
    p = max
  }
  return p
}

export default () => {
  const { search } = useLocation()
  const params = qs.parse(search)
  const page = getPage(params.page, 100)
  let eventsQuery = {
    q: params.q,
    cats: params.cats,
    nhoods: params.nhoods,
    places: params.places,
    zips: params.zips,
    days: params.days,
    feed: params.feed,
    noSiteBounds: params.noSiteBounds,
    ugc: params.ugc,
    venue: params.venue,
    tags: params.tags,
    page: page,
  }
  return (
    <ErrorBoundary component={() => <ServiceError message="Events temporarily unavailable" />}>
      <API input={`/api/search/events${qs.stringify(eventsQuery)}`}>
        {({ data, isFetching }) => {
          if (isFetching) {
            return <div>Loading...</div>
          }
          if (data.events.length === 0) {
            return <div className={styles.noEvents}>No events match your search criteria.</div>
          }
          return (
            <div className={styles.root}>
              <div className={styles.events}>
                <div>
                  {data.events
                    .filter((e, i) => i < data.size)
                    .map((e) => (
                      <EventCard key={e.id} {...e} />
                    ))}
                </div>
              </div>
              {data.fuzzySize > 0 && (
                <React.Fragment>
                  <div className={styles.closeMatches}>There are {data.fuzzySize} close matches</div>
                  <div className={styles.events}>
                    <div>
                      {data.events
                        .filter((e, i) => i >= data.size)
                        .map((e) => (
                          <EventCard key={e.id} {...e} />
                        ))}
                    </div>
                  </div>
                </React.Fragment>
              )}
              <Pagination currentPage={data.currentPage} maxPage={data.maxPage} params={params} />
            </div>
          )
        }}
      </API>
    </ErrorBoundary>
  )
}
