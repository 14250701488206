import React from 'react'
import { Helmet } from 'react-helmet-async'
import { Typography } from '@material-ui/core'

import { TrackPageView } from '../analytics'
import AdminCuratedVenues from '../AdminCuratedVenues'

export default () => {
  return (
    <div>
      <Helmet>
        <title>Curated Venues - Vyb</title>
      </Helmet>
      <TrackPageView title="Curated Venues" />
      <Typography component="h1" variant="h2">
        Curated Venues
      </Typography>
      <AdminCuratedVenues />
    </div>
  )
}
