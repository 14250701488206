import React, { useContext } from 'react'
import { useHistory } from 'react-router-dom'
import { Card, CardContent, Typography } from '@material-ui/core'
import { ResponsiveContainer, BarChart, Bar, XAxis, YAxis } from 'recharts'
import moment from 'moment-timezone'

import API from './API'
import { AdminSiteContext } from './AdminSiteContext'
import CustomizedAxisTick from './CustomizedAxisTick'
import { expandDateToLabelAndDay } from './adminutils'

export default () => {
  const { siteId, siteTimezone } = useContext(AdminSiteContext)
  const history = useHistory()
  return (
    <Card>
      <CardContent>
        <Typography gutterBottom variant="h5" component="h2">
          User Generated Content
        </Typography>
        <API input={`/api/dashboard/ugc?site=${encodeURIComponent(siteId)}`}>
          {({ data }) =>
            data && (
              <ResponsiveContainer width="100%" height={300}>
                <BarChart
                  data={expandDateToLabelAndDay(data.counts)}
                  style={{ cursor: 'pointer' }}
                  onClick={(p) => {
                    history.push(
                      `/ugc?site=${encodeURIComponent(siteId)}${
                        p?.activeLabel
                          ? `&start=${moment(p.activeLabel, 'MM/DD').tz(siteTimezone).toISOString()}&end=${moment(
                              p.activeLabel,
                              'MM/DD'
                            )
                              .tz(siteTimezone)
                              .toISOString()}`
                          : ''
                      }`
                    )
                  }}
                >
                  <XAxis dataKey="label" tick={<CustomizedAxisTick />} />
                  <YAxis allowDecimals={false} />
                  <Bar dataKey="count" fill="#795548" fillOpacity={0.6} />
                </BarChart>
              </ResponsiveContainer>
            )
          }
        </API>
      </CardContent>
    </Card>
  )
}
