import React, { useContext } from 'react'
import moment from 'moment-timezone'

import styles from './EventCard.module.css'
import { ImageServiceContext } from '../ImageServiceContext'
import { SiteContext } from '../SiteContext'
import { Link } from '../Link'

import noImage from './no_image_avail.png'

export default ({
  id,
  published = true,
  title,
  seriesId,
  seriesFutureCount,
  status,
  start,
  picked,
  venue,
  imageChecksum,
  onEventClick,
}) => {
  const { imageURL } = useContext(ImageServiceContext)
  const { site } = useContext(SiteContext)
  const handleEventClick = !onEventClick ? undefined : (e) => onEventClick(e, id)
  const when = moment(start).tz(site.timezone)
  const defaultImageUrl = site.defaultEventImageChecksum
    ? imageURL({ size: '510,', checksum: site.defaultEventImageChecksum })
    : noImage
  return (
    <div className={`${styles.root} ${published ? '' : styles.unpublished}`}>
      {imageChecksum ? (
        <Link to={`/event/${id}`} onClick={handleEventClick}>
          <div
            className={styles.image}
            style={{ backgroundImage: `url(${imageURL({ size: '510,', checksum: imageChecksum })})` }}
          />
        </Link>
      ) : (
        <Link to={`/event/${id}`} onClick={handleEventClick}>
          <div
            className={styles.image}
            style={{
              backgroundPosition: 'center',
              backgroundSize: 'contain',
              backgroundRepeat: 'no-repeat',
              backgroundImage: `url(${defaultImageUrl})`,
            }}
          />
        </Link>
      )}
      <div className={styles.meta}>
        <div>
          <Link to={`/event/${id}`} onClick={handleEventClick}>
            <h2>{title}</h2>
          </Link>
          <div className={styles.where}>{venue.name}</div>
          {picked && <div className={styles.pick}>Our Pick</div>}
          {!picked && status !== 'scheduled' && <div className={styles.status}>{status}</div>}
        </div>
        <div className={styles.when}>
          <div>{when.format('MMM')}</div>
          <div className={styles.day}>{when.format('D')}</div>
          {seriesId && seriesFutureCount ? <div className={styles.series}>+ {seriesFutureCount} more</div> : null}
        </div>
      </div>
    </div>
  )
}
