import React, { useContext, useEffect, useState } from 'react'
import { useLocation, useHistory } from 'react-router-dom'
import DayPickerInput from 'react-day-picker/DayPickerInput'
import moment from 'moment-timezone'
import { Table, TableHead, TableBody, TableRow, TableCell, Typography, Paper } from '@material-ui/core'

import { AdminSiteContext } from './AdminSiteContext'
import { AuthContext } from './AuthContext'
import { NewFetchError } from './Fetch'
import qs from './qs'

const search = async (siteId, getIdToken, start, end) => {
  const idToken = await getIdToken()
  const resp = await fetch(
    `/api/upselldetails?site=${encodeURIComponent(siteId)}${
      start.isValid() ? `&start=${encodeURIComponent(start.startOf('day').toISOString())}` : ''
    }${end.isValid() ? `&end=${encodeURIComponent(end.endOf('day').toISOString())}` : ''}`,
    {
      headers: {
        authorization: `Bearer ${idToken}`,
      },
    }
  )
  if (!resp.ok) {
    throw await NewFetchError(resp)
  }
  const data = await resp.json()
  return data
}
let onSubmit = () => null

const rollupLineItems = (siteUrl, lineItems) => {
  const daysByEvent = {}
  lineItems.forEach((i, idx) => {
    daysByEvent[i.eventId] = {
      ...(daysByEvent[i.eventId || i.summary || idx]
        ? daysByEvent[i.eventId || i.summary || idx]
        : {
            link: i.eventId ? (
              <a href={`${siteUrl}/event/${encodeURIComponent(i.eventId)}`} target="_blank" rel="noreferrer">
                {i.event ? i.event.title : i.summary}
              </a>
            ) : (
              <>{i.summary} (event deleted)</>
            ),
            days: [],
          }),
    }
    daysByEvent[i.eventId].days.push(i.day)
  })
  let result = []
  Object.values(daysByEvent).forEach((i) => {
    const days = (i.days || []).sort()
    result.push(
      <>
        {i.link} featured on {days.join(', ')}
      </>
    )
  })
  return result
}

export default () => {
  const history = useHistory()
  const location = useLocation()
  const params = qs.parse(location.search)
  const { getIdToken } = useContext(AuthContext)
  const { siteId, siteTimezone, siteUrl } = useContext(AdminSiteContext)
  const [start, setStart] = useState(
    params.start
      ? moment.tz(params.start, siteTimezone).startOf('day')
      : moment().tz(siteTimezone).add(-14, 'days').startOf('day')
  )
  const [end, setEnd] = useState(moment.tz(params.end, siteTimezone).endOf('day'))
  const [results, setResults] = useState({
    events: [],
    start: start.clone(),
    end: end.clone(),
    searching: false,
  })
  onSubmit = async () => {
    let s = start.clone()
    let e = end.clone()
    if (e.isBefore(s)) {
      let t = s
      s = e
      e = t
      setStart(s)
      setEnd(e)
    }
    history.replace(
      `${location.pathname}${qs.stringify({
        ...params,
        start: s.startOf('day').format('YYYY-MM-DD'),
        end: e.endOf('day').format('YYYY-MM-DD'),
      })}${location.hash}`
    )
    setResults({ ...results, searching: true })
    const data = await search(siteId, getIdToken, s, e)
    setResults({ ...results, events: data, start: s, end: e })
  }
  useEffect(() => {
    onSubmit()
  }, [siteId])

  return (
    <Paper>
      {!results.searching ? (
        <Typography gutterBottom variant="h5" component="h2">
          {results.events.length === 0 ? 'No' : results.events.length} Upsell{results.events.length !== 1 ? 's' : ''}{' '}
          Created {results.start.format('LL')}
          {results.end.isValid() ? ` - ${results.end.format('LL')}` : null}
        </Typography>
      ) : (
        <Typography gutterBottom variant="h5" component="h2">
          Searching for upsells created {start.format('LL')} - {end.format('LL')}...
        </Typography>
      )}
      <label>
        Start:{' '}
        <DayPickerInput
          value={start.isValid ? start.format('YYYY-MM-DD') : null}
          onDayChange={(day) => {
            day = moment.tz(day.toISOString(), siteTimezone)
            setStart(day)
          }}
        />
      </label>{' '}
      <label>
        End:{' '}
        <DayPickerInput
          value={end.isValid ? end.format('YYYY-MM-DD') : null}
          onDayChange={(day) => {
            day = moment.tz(day.toISOString(), siteTimezone)
            setEnd(day)
          }}
        />
      </label>{' '}
      <button onClick={() => onSubmit()}>Search</button>
      {results.searching && (
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Please Wait ...</TableCell>
            </TableRow>
          </TableHead>
        </Table>
      )}
      {!results.searching ? (
        !results.events.length ? (
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>
                  No upsells created {results.start.format('LL')}-{results.end.format('LL')}
                </TableCell>
              </TableRow>
            </TableHead>
          </Table>
        ) : (
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Customer</TableCell>
                <TableCell>Sales Date</TableCell>
                <TableCell>Status</TableCell>
                <TableCell>Charged</TableCell>
                <TableCell>Stripe Fee</TableCell>
                <TableCell>Net</TableCell>
                <TableCell>Promoted Events/Dates</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {results.events.map((i, idx) => (
                <TableRow key={idx}>
                  <TableCell>
                    <a href={`mailto:${i.user.email}`}>
                      {i.user.displayName === i.user.email || !i.user.displayName
                        ? i.user.email
                        : `${i.user.displayName} (${i.user.email})`}
                    </a>
                  </TableCell>
                  <TableCell>
                    {moment(i.created, moment.ISO_8601).tz(siteTimezone).format('YYYY-MM-DD h:mm a')}
                  </TableCell>
                  <TableCell>{i.status}</TableCell>
                  <TableCell>
                    {i.amount && (i.amount / 100).toLocaleString('en-US', { style: 'currency', currency: 'USD' })}
                  </TableCell>
                  <TableCell>
                    {i.fee && (i.fee / 100).toLocaleString('en-US', { style: 'currency', currency: 'USD' })}
                  </TableCell>
                  <TableCell>
                    {i.net && (i.net / 100).toLocaleString('en-US', { style: 'currency', currency: 'USD' })}
                  </TableCell>
                  <TableCell>{rollupLineItems(siteUrl, i.lineItems)}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        )
      ) : null}
    </Paper>
  )
}
