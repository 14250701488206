import React from 'react'
import { Helmet } from 'react-helmet-async'
import { Typography } from '@material-ui/core'

import { TrackPageView } from '../analytics'
import AdminAddEditCuratedVenue from '../AdminAddEditCuratedVenue'

export default ({ uid }) => {
  return (
    <div>
      <Helmet>
        <title>{uid ? 'Add ' : 'Edit '}Curated Venue - Vyb</title>
      </Helmet>
      <TrackPageView title={`${uid ? 'Add' : 'Edit'} Curated Venue`} />
      <Typography component="h1" variant="h2">
        Curated Venue
      </Typography>
      <AdminAddEditCuratedVenue uid={uid} />
    </div>
  )
}
