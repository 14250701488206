import React, { useState, useEffect } from 'react'
import DayPicker from 'react-day-picker'
import moment from 'moment'
import 'react-day-picker/lib/style.css'
import { useLocation, useHistory } from 'react-router-dom'
import { MdSearch, MdFilterList } from 'react-icons/md'

import styles from './SearchBar.module.css'
import API from './API'
import SearchOptions from './SearchOptions'
import { catsToOptions } from './searchopts'
import qs from './qs'

const neighborhoodPrefix = 'nhood:'
const placePrefix = 'place:'
const zipcodePrefix = 'zip:'

export default () => {
  const location = useLocation()
  const history = useHistory()
  const [text, setText] = useState('')
  const [what, setWhat] = useState([])
  const [when, setWhen] = useState([])
  const [where, setWhere] = useState([])
  const [showFilters, setShowFilters] = useState(false)
  useEffect(() => {
    let newText = ''
    let newWhat = []
    let newWhere = []
    let newWhen = []
    if (location.pathname === '/search') {
      const params = qs.parse(location.search)
      if (params.q) {
        const values = Array.isArray(params.q) ? params.q : [params.q]
        if (values.length > 0) {
          newText = values[0]
        }
      }
      if (params.cats) {
        const values = Array.isArray(params.cats) ? params.cats : [params.cats]
        if (values.length > 0) {
          newWhat = values
        }
      }
      if (params.nhoods) {
        const values = Array.isArray(params.nhoods) ? params.nhoods : [params.nhoods]
        newWhere.push(...values.map((v) => neighborhoodPrefix + v))
      }
      if (params.places) {
        const values = Array.isArray(params.places) ? params.places : [params.places]
        newWhere.push(...values.map((v) => placePrefix + v))
      }
      if (params.zips) {
        const values = Array.isArray(params.zips) ? params.zips : [params.zips]
        newWhere.push(...values.map((v) => zipcodePrefix + v))
      }
      if (params.days) {
        const values = Array.isArray(params.days) ? params.days : [params.days]
        if (values.length > 0) {
          newWhen = values
        }
      }
    }
    setText(newText)
    setWhat(newWhat)
    setWhere(newWhere)
    setWhen(newWhen)
  }, [location.pathname, location.search])
  const searchHandler = (e) => {
    e.preventDefault()
    const params = {}
    if (text) {
      params.q = text
    }
    if (what.length > 0) {
      params.cats = what
    }
    if (where.length > 0) {
      const nhoods = where
        .filter((v) => v.startsWith(neighborhoodPrefix))
        .map((v) => v.substr(neighborhoodPrefix.length))
      if (nhoods.length > 0) {
        params.nhoods = nhoods
      }
      const places = where.filter((v) => v.startsWith(placePrefix)).map((v) => v.substr(placePrefix.length))
      if (places.length > 0) {
        params.places = places
      }
      const zips = where.filter((v) => v.startsWith(zipcodePrefix)).map((v) => v.substr(zipcodePrefix.length))
      if (zips.length > 0) {
        params.zips = zips
      }
    }
    if (when.length > 0) {
      params.days = when
    }
    history.push(`/search${qs.stringify(params)}`)
  }

  const handleTextChange = (e) => {
    setText(e.target.value)
  }
  const selectedDays = when.map((d) => moment(d, 'YYYY-MM-DD', true).toDate())
  const whenOpts = [...when.map((w) => ({ id: w, display: 'none' })), { display: 'children' }]
  const handleDayClick = (day, { selected }) => {
    const current = moment(day).format('YYYY-MM-DD')
    const days = [...when]
    if (selected) {
      days.splice(days.indexOf(current), 1)
    } else {
      days.push(current)
    }
    setWhen(days)
  }
  return (
    <API input="/api/sitesearchopts">
      {({ data: searchopts }) => {
        if (!searchopts) {
          return null
        }
        return (
          <div className={`${styles.root} ${showFilters ? styles.showFilters : styles.hideFilters}`}>
            <div className={styles.text}>
              <form onSubmit={searchHandler}>
                <input type="search" value={text} onChange={handleTextChange} placeholder="Search for events" />
              </form>
              <div className={styles.search2}>
                <button onClick={searchHandler}>
                  <MdSearch className={styles.searchIcon} />
                </button>
              </div>
            </div>
            <div className={styles.filterToggle}>
              <button onClick={(e) => setShowFilters(!showFilters)}>
                {showFilters ? (
                  <MdFilterList className={styles.toggleIconOpen} />
                ) : (
                  <MdFilterList className={styles.toggleIconClosed} />
                )}
              </button>
            </div>
            <div className={styles.search}>
              <button onClick={searchHandler}>
                <MdSearch className={styles.searchIcon} />
              </button>
            </div>
            <div className={styles.what}>
              <SearchOptions
                className={styles.whatOpts}
                placeholder="What"
                options={catsToOptions(searchopts.categories.children)}
                value={what}
                onChange={setWhat}
              />
            </div>
            <div className={styles.where}>
              <SearchOptions
                placeholder="Where"
                options={[
                  {
                    label: 'Neighborhoods',
                    options: catsToOptions(searchopts.geo.neighborhoods, 'nhood:'),
                  },
                  { label: 'Places', options: catsToOptions(searchopts.geo.places, 'place:') },
                  { label: 'Zip codes', options: catsToOptions(searchopts.geo.zips, 'zip:') },
                ]}
                value={where}
                onChange={setWhere}
              />
            </div>
            <div className={styles.when}>
              <SearchOptions
                className={styles.whenOpts}
                placeholder="When"
                options={whenOpts}
                value={when}
                onChange={setWhen}
              >
                <DayPicker className={styles.dayPicker} selectedDays={selectedDays} onDayClick={handleDayClick} />
              </SearchOptions>
            </div>
          </div>
        )
      }}
    </API>
  )
}
