import React from 'react'
import DayPicker, { DateUtils } from 'react-day-picker'
import DayPickerInput from 'react-day-picker/DayPickerInput'
import moment from 'moment'
import 'react-day-picker/lib/style.css'

import styles from './SingleDatepicker.module.css'

export default ({ selectedDay, onChange, onBlur, start, min, max, placeholder, isInput = false, defaultMonth }) => {
  const selectedDate = selectedDay ? moment(selectedDay, 'YYYY-MM-DD', true).toDate() : null
  const month = start ? moment(start, 'YYYY-MM-DD', true).toDate() : undefined
  const fromMonth = min ? moment(min, 'YYYY-MM-DD', true).toDate() : undefined
  const toMonth = max ? moment(max, 'YYYY-MM-DD', true).toDate() : undefined
  const initialMonth = defaultMonth ? moment(defaultMonth, 'YYYY-MM-DD', true).toDate() : undefined
  const modifiers = {
    disabled: [
      {
        before: fromMonth,
        after: toMonth,
      },
    ],
  }
  const dayPickerProps = {
    modifiers,
    month,
    fromMonth,
    toMonth,
    initialMonth,
  }
  if (isInput) {
    const classNames = { ...DayPickerInput.defaultProps.classNames }
    classNames.container = `${classNames.container} ${styles.inputRoot}`
    return (
      <DayPickerInput
        classNames={classNames}
        value={selectedDate ? moment(selectedDate).format('YYYY-MM-DD') : ''}
        onDayChange={(day) => {
          const result = day ? moment(day).format('YYYY-MM-DD') : ''
          onChange && onChange(result)
        }}
        inputProps={{ className: styles.input, type: 'search', placeholder, onBlur }}
        dayPickerProps={dayPickerProps}
        clickUnselectsDay
      />
    )
  }
  return (
    <DayPicker
      className={styles.root}
      selectedDays={selectedDate ? [selectedDate] : undefined}
      onDayClick={(day, { selected, disabled }) => {
        if (disabled) {
          return
        }
        const result = selectedDate && DateUtils.isSameDay(selectedDate, day) ? '' : moment(day).format('YYYY-MM-DD')
        onChange && onChange(result)
      }}
      onBlur={onBlur}
      {...dayPickerProps}
    />
  )
}
