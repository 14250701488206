import React, { useContext } from 'react'
import { Helmet } from 'react-helmet-async'

import styles from './VenuePage.module.css'
import API from '../API'
import VenueDetail from '../VenueDetail'
import NearbyEvents from '../NearbyEvents'
import { SiteContext } from '../SiteContext'
import { TrackPageView } from '../analytics'
import useAds from '../useAds'
import Ad from '../Ad'
import ServiceError from '../ServiceError'
import ErrorBoundary from '../ErrorBoundary'
import DetailPageNotFound from '../DetailPageNotFound'
import FeaturedDailyEvents from '../appcomponents/FeaturedDailyEvents'
import Breadcrumbs from '../Breadcrumbs'
import FeaturedVenues from '../appcomponents/FeaturedVenues'

export const venueToLDJSON = ({ name, address, latitude, longitude }) => ({
  '@context': 'http://schema.org',
  '@type': 'Place',
  name: name,
  address: address,
  geo: {
    '@type': 'GeoCoordinates',
    latitude: latitude,
    longitude: longitude,
  },
})

const VenuePage = ({ id, venue, breadcrumbs }) => {
  const { site } = useContext(SiteContext)
  useAds([
    { id: 'div-gpt-ad-right-rail-0', type: 'rightRail' },
    { id: 'div-gpt-ad-leaderboard-0', type: 'leaderboard' },
  ])
  return (
    <div className={styles.root}>
      <Helmet>
        <title>
          {venue.name} - {site.name}
        </title>
        <script type="application/ld+json">{JSON.stringify(venueToLDJSON(venue))}</script>
      </Helmet>
      {venue.id === id && <TrackPageView title={venue.name} />}
      <Breadcrumbs breadcrumbs={breadcrumbs} />
      <div className={styles.layout}>
        <div>
          <VenueDetail {...venue} />
          <NearbyEvents latitude={venue.latitude} longitude={venue.longitude} />
        </div>
        <div>
          <Ad id="div-gpt-ad-right-rail-0" type="rightRail" />
        </div>
      </div>
      <FeaturedDailyEvents />
      <FeaturedVenues />
      <Ad id="div-gpt-ad-leaderboard-0" type="leaderboard" />
    </div>
  )
}

export default ({ id, breadcrumbs }) => (
  <ErrorBoundary component={() => <ServiceError message="Venue temporarily unavailable" />}>
    <API input={`/api/venue?id=${id}`} throwError={false}>
      {({ data, error }) => {
        if (error !== null) {
          if (error.resp && error.resp.status !== 404) {
            throw error
          }
          return <DetailPageNotFound message="Venue not found" breadcrumbs={breadcrumbs} />
        }
        if (data === null) {
          return null
        }
        return <VenuePage id={id} venue={data} breadcrumbs={breadcrumbs} />
      }}
    </API>
  </ErrorBoundary>
)
