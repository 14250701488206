import React from 'react'

import StaticPage from './StaticPage'

export default ({ breadcrumbs }) => {
  return (
    <StaticPage title="Terms of Service" breadcrumbs={breadcrumbs}>
      <h1>Terms of Service</h1>
      <h2>
        <span>1. Terms</span>
      </h2>
      <p>
        By accessing this website, you agree to be bound by these terms of service, all applicable laws and regulations,
        and any applicable local laws. If you do not agree with any of these terms, you are prohibited from using or
        accessing this site. The materials contained in this website are protected by applicable copyright and trademark
        law.
      </p>

      <h2>
        <span>2. Use License</span>
      </h2>
      <p>
        a. Permission is granted to temporarily download one copy of the materials (information or software) on Vyb
        Events' website for personal, non-commercial transitory viewing only. This is the grant of a license, not a
        transfer of title, and under this license you may not:
      </p>

      <p>i. modify or copy the materials;</p>
      <p>ii. use the materials for any commercial purpose, or for any public display (commercial or non-commercial);</p>
      <p>iii. attempt to decompile or reverse engineer any software contained on Vyb Events' website;</p>
      <p>iv. remove any copyright or other proprietary notations from the materials; or</p>
      <p>v. transfer the materials to another person or "mirror" the materials on any other server.</p>

      <p>
        b. This license shall automatically terminate if you violate any of these restrictions and may be terminated by
        Vyb Events at any time. Upon terminating your viewing of these materials or upon the termination of this
        license, you must destroy any downloaded materials in your possession whether in electronic or printed format.
      </p>

      <h2>
        <span>3. Disclaimer</span>
      </h2>
      <p>
        a. The materials on Vyb Events' website are provided on an 'as is' basis. Vyb Events makes no warranties,
        expressed or implied, and hereby disclaims and negates all other warranties including, without limitation,
        implied warranties or conditions of merchantability, fitness for a particular purpose, or non-infringement of
        intellectual property or other rights.
      </p>

      <p>
        b. Vyb Events does not warrant or make any representations concerning the accuracy, likely results, or
        reliability of the use of the materials on its website, or otherwise relating to such materials on any sites
        linked to it.
      </p>

      <h2>
        <span>4. Limitations</span>
      </h2>
      <p>
        In no event shall Vyb Events or its suppliers be liable for any damages (including, without limitation, damages
        for loss of data or profit, or due to business interruption) arising out of the use or inability to use the
        materials on Vyb Events' website, even if Vyb Events or a Vyb Events authorized representative has been notified
        orally or in writing of the possibility of such damage. Because some jurisdictions do not allow limitations on
        implied warranties, or limitations of liability for consequential or incidental damages, these limitations may
        not apply to you.
      </p>

      <h2>
        <span>5. Accuracy of materials</span>
      </h2>
      <p>
        The materials appearing on Vyb Events' website could include technical, typographical, or photographic errors.
        Vyb Events does not warrant that any of the materials on its website are accurate, complete or current. Vyb
        Events may make changes to the materials contained on its website at any time and without notice. However, Vyb
        Events does not make any commitment to update the materials.
      </p>

      <h2>
        <span>6. Links</span>
      </h2>
      <p>
        Vyb Events has not reviewed all of the sites linked to its website and is not responsible for the contents of
        any linked site. The inclusion of any link does not imply endorsement by Vyb Events of the site. Use of any
        linked website is at the user's own risk.
      </p>

      <h2>
        <span>7. Modifications</span>
      </h2>
      <p>
        Vyb Events may revise these terms of service at any time without notice. By using this website you are agreeing
        to be bound by the then current version of these terms of service.
      </p>

      <h2>
        <span>8. Governing Law</span>
      </h2>
      <p>
        These terms and conditions are governed by and construed in accordance with the laws of New York and you
        irrevocably submit to the exclusive jurisdiction of the courts in that State or location.
      </p>

      <h2>
        <span>9. Copyright Complaints</span>
      </h2>
      <p>
        If you believe that content on Vyb Events violates your copyright, please send us a notice of the violation
        using the following contact information:
      </p>

      <p>By mail:</p>
      <p>DMCA Designated Agent</p>
      <p>c/o Legal Department</p>
      <p>Vyb Events</p>
      <p>547 Broadway, 5th Floor</p>
      <p>New York, NY 10012</p>

      <p>By Email:</p>
      <p>dmca@pointslocal.com*</p>

      <p>
        *In order to allow for spam filtering, only email with the phrase “Copyright Matter” in the subject line will be
        read. All other email will be discarded.
      </p>

      <p>Your notice must include:</p>
      <ul>
        <li>
          <p>
            a physical or electronic signature of a person authorized to act on behalf of the owner of an exclusive
            right that is allegedly infringed;
          </p>
        </li>
        <li>
          <p>
            identification of the copyrighted work claimed to have been infringed, or, if multiple copyrighted works at
            a single online site are covered by a single notification, a representative list of such works at that site;
          </p>
        </li>
        <li>
          <p>
            identification of the material that is claimed to be infringing or to be the subject of infringing activity
            and information reasonably sufficient to permit us to locate the material;
          </p>
        </li>
        <li>
          <p>information sufficient to permit us to contact the complaining party;</p>
        </li>
        <li>
          <p>
            a statement that the complaining party has a good-faith belief that use of the material in the manner
            complained of is not authorized by the copyright owner, its agent, or the law; and
          </p>
        </li>
        <li>
          <p>
            a statement that the information in the notification is accurate, and under penalty of perjury, that the
            complaining party is authorized to act on behalf of the owner of an exclusive right that is allegedly
            infringed.
          </p>
        </li>
      </ul>

      <p>
        Upon receipt of a notice of claimed infringement that satisfies these requirements (or any statement in
        conformance with 17 U.S.C. § 512(c)(3)), we will act expeditiously to remove or disable access to any Content
        that is claimed to be infringing upon the copyright of any person under the laws of the United States, and will
        terminate the access privileges of those who repeatedly infringe on the copyrights of others. United States law
        imposes substantial penalties for falsely submitting a notice of copyright infringement.
      </p>
    </StaticPage>
  )
}
