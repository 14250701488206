import React from 'react'
import Select, { components } from 'react-select'

import styles from './SearchOptions.module.css'
import { filterOption } from './searchopts'

const MultiValue = (props) => {
  // Only display our "You have selected N options" pill once, we do this by only returning
  // something for the first selected value.
  const {
    selectProps: { value, placeholder },
    data,
  } = props
  if (value[0].id === data.id) {
    return (
      <components.MultiValue {...props}>
        {placeholder} <span className={styles.pillCount}>{value.length}</span>
      </components.MultiValue>
    )
  }
  return null
}

const Option = ({ data, ...props }) => {
  if (data.display === 'none') {
    return null
  }
  if (data.display === 'children') {
    return props.selectProps.children
  }
  return (
    <components.Option {...props}>
      <div className={data.path && data.path.length === 0 ? 'SearchOptions--TopLevel' : ''}>{data.label}</div>
      {data.path && data.path.length > 0 && <div className="SearchOptions--Path">{data.path.join(' » ')}</div>}
    </components.Option>
  )
}

const flatten = (options) => options.flatMap((opts) => (opts.options ? flatten(opts.options) : opts))

export default ({ value: selection, onChange, onBlur, options = [], placeholder, children, menuIsOpen }) => {
  const value = selection ? flatten(options).filter((v) => selection.includes(v.id)) : []
  const handleChange = (data, action) => {
    const result =
      data && action.action !== 'pop-value' && action.action !== 'remove-value'
        ? data.map((v) => v.id).filter((v) => v)
        : []
    onChange && onChange(result)
  }
  return (
    <Select
      className={styles.root}
      classNamePrefix="SearchOptions"
      value={value}
      isMulti
      onChange={handleChange}
      onBlur={onBlur}
      options={options}
      getOptionValue={(opt) => opt.id}
      filterOption={filterOption}
      components={{ Option, MultiValue }}
      placeholder={placeholder}
      menuIsOpen={menuIsOpen}
      children={children}
      closeMenuOnSelect={false}
      hideSelectedOptions={false}
      tabSelectsValue={false}
      styles={{
        control: (provided, state) => {
          const r = { ...provided }
          delete r['outline']
          return r
        },
      }}
    />
  )
}
