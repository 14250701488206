import React, { useContext } from 'react'
import { Link as RouterLink, NavLink as RouterNavLink } from 'react-router-dom'

import { SiteContext } from './SiteContext'

const updateProps = (props, site) => {
  if (!site.preventSearchEngineIndex) {
    return props
  }
  return { ...props, rel: 'nofollow' }
}

export const Link = (props) => {
  const { site } = useContext(SiteContext)
  return <RouterLink {...updateProps(props, site)} />
}
export const NavLink = (props) => {
  const { site } = useContext(SiteContext)
  return <RouterNavLink {...updateProps(props, site)} />
}
