import React, { useContext } from 'react'
import { Helmet } from 'react-helmet-async'

import styles from './EventPage.module.css'
import API from '../API'
import EventDetail from '../EventDetail'
import NearbyEvents from '../NearbyEvents'
import { SiteContext } from '../SiteContext'
import { venueToLDJSON } from './VenuePage'
import { TrackPageView } from '../analytics'
import useAds from '../useAds'
import Ad from '../Ad'
import ErrorBoundary from '../ErrorBoundary'
import ServiceError from '../ServiceError'
import DetailPageNotFound from '../DetailPageNotFound'
import FeaturedDailyEvents from '../appcomponents/FeaturedDailyEvents'
import Breadcrumbs from '../Breadcrumbs'
import FeaturedVenues from '../appcomponents/FeaturedVenues'

const normalizeCategories = (cats) => {
  const registry = {}
  cats &&
    cats.forEach((cat) => {
      normalizeCategory(cat, null, registry)
    })
  return registry
}

const normalizeCategory = (cat, parent = null, registry = {}) => {
  const result = {
    id: cat.id,
    label: cat.label,
  }
  if (parent) {
    result.parent = parent
  }
  if (cat.children && cat.children.length > 0) {
    result.children = cat.children.map((c) => normalizeCategory(c, cat.id, registry).id)
  }
  registry[result.id] = result
  return result
}

const eventToLDJSON = ({ title, description, start, image, venue }) => ({
  '@context': 'http://schema.org',
  '@type': 'Event',
  name: title,
  description,
  startDate: start,
  image,
  location: venueToLDJSON(venue),
})

const EventPage = ({ id, event, series, quarantine, picked, breadcrumbs }) => {
  const { site } = useContext(SiteContext)
  useAds([
    { id: 'div-gpt-ad-right-rail-0', type: 'rightRail' },
    { id: 'div-gpt-ad-leaderboard-0', type: 'leaderboard' },
  ])
  return (
    <div className={styles.root}>
      <Helmet>
        <title>
          {event.title} - {site.name}
        </title>
        <script type="application/ld+json">{JSON.stringify(eventToLDJSON(event))}</script>
      </Helmet>
      {event.id === id && <TrackPageView title={event.title} />}
      <Breadcrumbs breadcrumbs={breadcrumbs} />
      <div className={styles.layout}>
        <div>
          <API input={`/api/sitesearchopts`}>
            {({ data }) => {
              if (data === null) {
                return null
              }
              const categoryRegistry = normalizeCategories(data.categories.children)
              return (
                <EventDetail
                  {...event}
                  series={series}
                  quarantine={quarantine}
                  picked={picked}
                  categoryRegistry={categoryRegistry}
                  allCategories={data.categories.children}
                />
              )
            }}
          </API>
          <NearbyEvents
            latitude={event.venue.latitude}
            longitude={event.venue.longitude}
            excludes={[event.id]}
            excludeSeries={event.seriesId ? [event.seriesId] : undefined}
          />
        </div>
        <div>
          <Ad id="div-gpt-ad-right-rail-0" type="rightRail" />
        </div>
      </div>
      <FeaturedDailyEvents />
      <FeaturedVenues />
      <Ad id="div-gpt-ad-leaderboard-0" type="leaderboard" />
    </div>
  )
}

export default ({ id, breadcrumbs }) => (
  <ErrorBoundary component={() => <ServiceError message="Event temporarily unavailable" />}>
    <API input={`/api/event?id=${id}`} throwError={false}>
      {({ data, error }) => {
        if (error !== null) {
          if (error.resp && error.resp.status !== 404) {
            throw error
          }
          return <DetailPageNotFound message="Event not found" breadcrumbs={breadcrumbs} />
        }
        if (data === null) {
          return null
        }
        return (
          <EventPage
            id={id}
            event={data.event}
            series={data.series}
            quarantine={data.quarantine}
            picked={data.picked}
            breadcrumbs={breadcrumbs}
          />
        )
      }}
    </API>
  </ErrorBoundary>
)
