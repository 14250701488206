import React, { useContext } from 'react'
import { Helmet } from 'react-helmet-async'
import { object, string } from 'yup'
import { ErrorMessage, Field, Form, Formik } from 'formik'
import { useHistory } from 'react-router-dom'

import './CategoryEditPage.css'
import ErrorBoundary from '../ErrorBoundary'
import ServiceError from '../ServiceError'
import API from '../API'
import { TrackPageView } from '../analytics'
import DetailPageNotFound from '../DetailPageNotFound'
import ImageUpload from '../components/ImageUpload'
import { SiteContext } from '../SiteContext'
import { createUploadImageHandler } from '../api_utilities'
import { AuthContext } from '../AuthContext'
import { NewFetchError } from '../Fetch'
import Breadcrumbs from '../Breadcrumbs'

const EditCategory = ({ category, uploadImage, onSubmit }) => (
  <Formik
    className="EditCategory"
    initialValues={{
      name: category.name,
      blurb: category.blurb,
      description: category.description,
      thumbnailImageChecksum: category.thumbnailImageChecksum,
      backgroundImageChecksum: category.backgroundImageChecksum,
    }}
    validationSchema={object().shape({
      name: string().required('Name is required').min(3, 'Name is too short'),
      blurb: string(),
      description: string(),
      thumbnailImageChecksum: string(),
      backgroundImageChecksum: string(),
    })}
    onSubmit={async (values, actions) => {
      try {
        const normalized = { id: category.id, ...values }
        onSubmit && (await onSubmit(normalized))
        actions.setStatus()
      } catch (error) {
        actions.setStatus({ msg: 'Your form has some problems' })
        actions.setSubmitting(false)
      }
    }}
  >
    {({ isSubmitting, status, values, setFieldValue }) => (
      <Form className="EditCategoryForm">
        <fieldset>
          <Field name="name" type="text" autoComplete="off" placeholder="name" />
          <ErrorMessage component="div" name="name" className="Error" />
        </fieldset>
        <fieldset>
          <Field component="textarea" name="blurb" placeholder="blurb" />
          <ErrorMessage component="div" name="blurb" className="Error" />
        </fieldset>
        <fieldset>
          <Field component="textarea" name="description" placeholder="description" />
          <ErrorMessage component="div" name="description" className="Error" />
        </fieldset>
        <fieldset>
          <ImageUpload
            value={values.thumbnailImageChecksum}
            onChange={(opt) => setFieldValue('thumbnailImageChecksum', opt)}
            onUpload={uploadImage}
          />
          <ErrorMessage component="div" name="thumbnailImageChecksum" className="Error" />
        </fieldset>
        <fieldset>
          <ImageUpload
            value={values.backgroundImageChecksum}
            onChange={(opt) => setFieldValue('backgroundImageChecksum', opt)}
            onUpload={uploadImage}
          />
          <ErrorMessage component="div" name="backgroundImageChecksum" className="Error" />
        </fieldset>
        {status && status.msg && <div className="Error">{status.msg}</div>}
        <fieldset>
          <button type="submit" disabled={isSubmitting}>
            Submit
          </button>
        </fieldset>
      </Form>
    )}
  </Formik>
)

const submitSite = (getIdToken, history) => async (category) => {
  const idToken = await getIdToken()
  const resp = await fetch('/api/category/update', {
    method: 'post',
    body: JSON.stringify(category),
    headers: {
      'Content-Type': 'application/json',
      authorization: `Bearer ${idToken}`,
    },
  })
  if (!resp.ok) {
    throw await NewFetchError(resp)
  }
  history.replace(`/category/${encodeURIComponent(category.id)}`)
}

const CategoryEditPage = ({ id, category, breadcrumbs }) => {
  const { site } = useContext(SiteContext)
  const { getIdToken } = useContext(AuthContext)
  const history = useHistory()
  return (
    <div className="CategoryEditPage">
      <Helmet>
        <title>
          Edit {category.name} - {site.name}
        </title>
      </Helmet>
      {category.id === id && <TrackPageView title="Edit Category" />}
      <Breadcrumbs breadcrumbs={breadcrumbs} />
      <h1>Edit {category.name}</h1>
      <EditCategory
        category={category}
        onSubmit={submitSite(getIdToken, history)}
        uploadImage={createUploadImageHandler(getIdToken, history)}
      />
    </div>
  )
}

export default ({ id, breadcrumbs }) => (
  <ErrorBoundary component={() => <ServiceError message="Category temporarily unavailable" />}>
    <API input={`/api/category?id=${encodeURIComponent(id)}`} throwError={false}>
      {({ data, error }) => {
        if (error !== null) {
          if (error.resp && error.resp.status !== 404) {
            throw error
          }
          return <DetailPageNotFound message="Category not found" breadcrumbs={breadcrumbs} />
        }
        if (data === null) {
          return null
        }
        return <CategoryEditPage id={id} category={data} breadcrumbs={breadcrumbs} />
      }}
    </API>
  </ErrorBoundary>
)
