import React from 'react'

// Works around a bug in rechart xaxis labels, they disappear when resizing the browser window.
// To use: <XAxis dataKey="example" tick={<CustomizedAxisTick />} />
// https://github.com/recharts/recharts/issues/1664
export default (props) => {
  const { x, y, payload } = props
  return (
    <g transform={`translate(${x},${y})`}>
      <text dy={16} textAnchor="middle" fill="#666">
        {payload.value}
      </text>
    </g>
  )
}
