import React, { useContext, useEffect, useState } from 'react'

import Fetch from './Fetch'
import { AuthContext } from './AuthContext'

export default ({ input, init, children, throwError = true, ...props }) => {
  const { getIdToken, logout } = useContext(AuthContext)
  const [initWithBearer, setInitWithBearer] = useState(null)
  useEffect(() => {
    ;(async () => {
      const idToken = await getIdToken()
      let iwb = { ...init }
      if (idToken) {
        if ('headers' in iwb) {
          iwb['headers'] = { ...iwb['headers'] }
        } else {
          iwb.headers = {}
        }
        iwb.headers.authorization = `Bearer ${idToken}`
      }
      setInitWithBearer(iwb)
    })()
  }, [getIdToken, init])
  if (!initWithBearer) {
    return null
  }
  return (
    <Fetch {...props} input={input} init={initWithBearer} throwError={false}>
      {(state) => {
        if (state.error !== null) {
          if (state.error.resp && state.error.resp.status === 401) {
            logout()
            return null
          }
          if (throwError) {
            throw state.error
          }
        }
        return children(state)
      }}
    </Fetch>
  )
}
