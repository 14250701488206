export const completeLoginAndRedirect = async (user, redirectUrl) => {
  const token = await user.getIdToken()
  let resp = await fetch(`/api/auth/mint?redirect=${encodeURIComponent(redirectUrl)}`, {
    method: 'post',
    headers: {
      authorization: `Bearer ${token}`,
    },
  })
  if (!resp.ok) {
    throw new Error('Unable to mint token')
  }
  const mtoken = await resp.text()
  let nextUrl = ''
  if (/#/.test(redirectUrl)) {
    nextUrl = redirectUrl.replace(/#.*$/, `#token=${encodeURIComponent(mtoken)}`)
  } else {
    nextUrl = `${redirectUrl}#token=${encodeURIComponent(mtoken)}`
  }
  window.location.replace(nextUrl)
}
