import React from 'react'
import MuiReactSelect from './MuiReactSelect'
import { makeStyles } from '@material-ui/core'

const useStyles = makeStyles((theme) => ({
  menuValue: {
    display: 'inline-block',
  },
  name: {
    fontSize: 'x-small',
  },
  address: {
    fontSize: 'x-small',
  },
}))

export default ({ value, onChange, onBlur, getVenues, label, error, helperText, placeholder }) => {
  const classes = useStyles()
  return (
    <MuiReactSelect
      async
      value={value}
      onChange={onChange}
      onBlur={onBlur}
      loadOptions={getVenues}
      getOptionValue={(opt) => opt.id}
      isClearable={true}
      label={label}
      error={error}
      helperText={helperText}
      placeholder={placeholder}
      formatOptionLabel={(data, { context }) =>
        context === 'value' ? (
          <>
            {data.name && <div className={classes.name}>{data.name}</div>}
            <div className={classes.address}>{data.address}</div>
          </>
        ) : (
          <div className={classes.menuValue}>
            {data.name && <div className={classes.name}>{data.name}</div>}
            <div className={classes.address}>{data.address}</div>
          </div>
        )
      }
    />
  )
}
