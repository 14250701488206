import React, { useState } from 'react'
import { makeStyles, Popover } from '@material-ui/core'

const useStyles = makeStyles({
  content: {
    padding: '1em',
    fontSize: '1em',
  },
})

export default ({ title, children }) => {
  const classes = useStyles()
  const [anchorEl, setAnchorEl] = useState(null)
  const open = Boolean(anchorEl)

  return (
    <>
      <span
        variant="contained"
        onClick={(e) => {
          setAnchorEl(e.currentTarget)
        }}
      >
        {title}
      </span>
      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={() => {
          setAnchorEl(null)
        }}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        <div className={classes.content}>{children}</div>
      </Popover>
    </>
  )
}
