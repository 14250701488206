import React, { useContext } from 'react'

import { SiteContext } from '../SiteContext'
import Dropdown, { Opener, Overlay } from '../Dropdown'
import FeaturedVenueCard from './FeaturedVenueCard'
import styles from './FeaturedVenues.module.css'

export default ({ venues }) => {
  const {
    site: { upsellEmail },
  } = useContext(SiteContext)
  const hasVenues = !!venues?.length
  if (!hasVenues && !upsellEmail) {
    return null
  }
  return (
    <div className={styles.root}>
      <div className={styles.header}>
        <div className={styles.title}>{hasVenues ? 'Featured Venues' : 'Want your venue featured here?'}</div>
        {hasVenues && upsellEmail ? (
          <div>
            <Dropdown>
              <Opener>
                {({ isOpen, toggle }) => {
                  return (
                    <a
                      href="#featurevenue"
                      onClick={(e) => {
                        e.preventDefault()
                        toggle()
                      }}
                    >
                      Want your venue here?
                    </a>
                  )
                }}
              </Opener>
              <Overlay position="right">
                {({ close }) => {
                  return (
                    <div className={styles.dropdownmessage}>
                      <p>
                        Email our sales team at{' '}
                        <a
                          href={`mailto:${upsellEmail}?subject=Feature My Venue&body=I am interested in promoting my venue`}
                          onClick={() => {
                            // TODO: React.GA.event ...
                          }}
                        >
                          {upsellEmail}
                        </a>
                        .
                      </p>
                    </div>
                  )
                }}
              </Overlay>
            </Dropdown>
          </div>
        ) : null}
      </div>
      {hasVenues ? (
        <div className={styles.container}>
          <div>
            {venues?.length
              ? venues.map((e, idx) => (
                  <FeaturedVenueCard
                    key={idx}
                    idx={idx}
                    featuredVenue={e.featuredVenue}
                    curatedVenue={e.curatedVenue}
                  />
                ))
              : null}
          </div>
        </div>
      ) : (
        <div className={styles.message}>
          <p>
            Email our sales team at{' '}
            <a
              href={`mailto:${upsellEmail}?subject=Feature My Venue&body=I am interested in promoting my venue`}
              onClick={() => {
                // TODO: React.GA.event ...
              }}
            >
              {upsellEmail}
            </a>
            .
          </p>
        </div>
      )}
    </div>
  )
}
